import { devtools, subscribeWithSelector } from "zustand/middleware";

import generateRandomId from "utils/generateRandomId";

import type { ModalElement, ModalState } from "../components/ModalKit/types";

import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "ModalStore";

export type ModalStore = {
  backdropId: ModalElement["id"] | undefined;
  closeAllModals: () => void;
  closeModal: (modalId?: ModalElement["id"]) => void;
  closeModals: (modalIds?: ModalElement["id"][]) => void;
  enqueuedToClose: ModalElement["id"][];
  immediate?: boolean;
  modalIsOpen: (modalId?: ModalElement["id"]) => boolean;
  modals: ModalElement[];
  openModal: (
    nextModal: ModalElement["modal"],
    options?: {
      closeModals?: ModalElement["id"][];
      id?: ModalElement["id"];
      immediate?: boolean;
    }
  ) => string;
  removeModal: (id?: ModalElement["id"]) => void;
  setBackdropId: (id?: ModalElement["id"]) => void;
  stable: Omit<ModalElement, "modal">[];
  topModalId?: ModalElement["id"] | undefined;
  topModalState: ModalState;
  updateModalState: ({
    id,
    nextState,
  }: { id?: ModalElement["id"]; nextState: ModalState }) => void;
};

const useModalStore = glueCreateZustand<ModalStore>({
  name: STORE_NAME,
})(
  subscribeWithSelector(
    devtools<ModalStore>(
      (set, get) => ({
        backdropId: undefined,

        closeAllModals: () =>
          set(() => ({
            enqueuedToClose: get().modals.map(modal => modal.id),
          })),

        closeModal: modalId =>
          set(state => {
            const openModals = state.modals.filter(
              modal => modal.state === "open"
            );
            const idToClose = modalId || openModals[openModals.length - 1]?.id; // id of the top-most _open_ modal
            return {
              enqueuedToClose: idToClose ? [idToClose] : [],
            };
          }),

        closeModals: modalIds =>
          set(() => ({
            enqueuedToClose: modalIds || [],
          })),

        enqueuedToClose: [],

        modalIsOpen: modalId =>
          modalId
            ? !!get().modals.find(modal => modalId === modal.id)
            : !!get().topModalId,

        modals: [],

        openModal: (nextModal, options) => {
          const modalId = `${options?.id || generateRandomId()}`;
          set(state => {
            return {
              backdropId: modalId,
              enqueuedToClose: options?.closeModals ?? [],
              immediate: options?.immediate,
              modals: nextModal
                ? [
                    ...state.modals,
                    {
                      id: modalId,
                      modal: nextModal,
                      state: "opening",
                    },
                  ]
                : [...state.modals],
              stable: nextModal
                ? [...state.stable, { id: modalId, state: "opening" }]
                : [...state.stable],
              topModalId: modalId,
              topModalState: "opening",
            };
          });
          return modalId;
        },

        // removes the modal immediately, without the "closeModal" lifecycle;
        // for internal use only; components should typically use `closeModal` instead.
        removeModal: id =>
          set(state => {
            const modals = [...state.modals];
            if (id) {
              const index = modals.findIndex(modal => modal.id === `${id}`);
              if (index > -1) {
                modals.splice(index, 1);
              }
            } else {
              modals.splice(-1);
            }

            const nextTopModal = modals[modals.length - 1];

            return {
              modals,
              topModalId: nextTopModal?.id,
              topModalState: modals.length ? "open" : "closed",
            };
          }),

        setBackdropId: id =>
          set(state => {
            // modals are considered as "unstable" when still rendered, but in the process of animating out;
            // we need to fade in the backdrop for the top-most "stable" modal.
            const stableModals = state.stable.filter(
              stable => stable.id !== id && stable.state === "open"
            );
            return {
              backdropId: stableModals[stableModals.length - 1]?.id,
              stable: stableModals,
            };
          }),

        stable: [],
        topModalId: undefined,
        topModalState: "closed",

        updateModalState: ({ id, nextState }) =>
          set(state => {
            if (state.modals.length === 0) return { modals: state.modals };

            const modalIndex = state.modals.findIndex(modal => modal.id === id);
            const modal = modalIndex > -1 ? state.modals[modalIndex] : null;

            const stableIndex = state.stable.findIndex(
              stable => stable.id === id
            );
            const stable = stableIndex > -1 ? state.stable[stableIndex] : null;

            if (modal) {
              modal.state = nextState;
              state.modals.splice(modalIndex, 1, modal);
            }

            if (stable) {
              stable.state = nextState;
              state.stable.splice(stableIndex, 1, stable);
            }

            return {
              modals: state.modals,
              stable: state.stable,
            };
          }),
      }),
      {
        name: STORE_NAME,
        serialize: true,
      }
    )
  )
);

export default useModalStore;
