import { ComponentProps, ReactNode } from "react";
import { Link } from "react-router-dom";

import Icon from "components/design-system/icons/Icon";
import tw from "utils/tw";

import BadgedAvatar from "../../design-system/Avatar/BadgedAvatar";

const ProfileItemPrimitive = ({
  avatarURL,
  badgeProps,
  badgeVisible,
  emojiProps,
  iconProps,
  closed,
  archived,
  route,
  subtitles = [],
  title,
}: ComponentProps<typeof BadgedAvatar> & {
  archived?: boolean;
  closed?: boolean;
  route?: string;
  subtitles?: ReactNode[];
  title: string;
}) => {
  const avatarBackground = avatarURL
    ? "subtle"
    : emojiProps?.emoji || iconProps?.icon
      ? "transparent"
      : "subtle";

  return (
    <Link
      className="flex grow items-center max-w-full"
      onClick={e => {
        e.stopPropagation();
      }}
      to={route ?? ""}
    >
      <BadgedAvatar
        avatarURL={avatarURL}
        background={avatarBackground}
        badgeProps={badgeProps}
        badgeVisible={badgeVisible}
        emojiProps={{
          emoji: emojiProps?.emoji,
        }}
        iconProps={iconProps}
        margin="mr-8"
        name={title}
        priority={["avatar", "emoji", "icon", "name"]}
        rounded="rounded-md"
        size="large"
      />

      <div className="flex flex-col overflow-hidden w-full">
        <div className="flex items-center gap-4 justify-start text-subhead-bold text-text-primary">
          {title}
          {closed && (
            <Icon className="text-icon-secondary" icon="LockFilled" size={16} />
          )}
          {archived && (
            <Icon
              className="text-icon-secondary"
              icon="ArchiveFilled"
              size={16}
            />
          )}
        </div>
        {subtitles.length > 0 && (
          <div
            className={tw(
              "flex min-w-0 text-footnote text-text-subtle",
              "[&>span:after]:content-['·'] [&>span:after]:mx-4",
              "[&>span:last-child:after]:content-[''] [&>span:last-child:after]:mx-0"
            )}
          >
            {subtitles.filter(Boolean).map((s, i, arr) => {
              if (typeof s === "string" || typeof s === "number") {
                return (
                  <span
                    key={s}
                    className={i === arr.length - 1 ? "truncate" : "shrink-0"}
                  >
                    {s}
                  </span>
                );
              }

              return s;
            })}
          </div>
        )}
      </div>
    </Link>
  );
};

export default ProfileItemPrimitive;
