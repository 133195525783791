import { FieldPath, FieldValues } from "react-hook-form";

import { RadioGroup } from "components/design-system/Forms/RadioGroup";
import { JoinableBy } from "generated/graphql-types";

type GroupVisibilityRadioGroupProps<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
};

export const GroupVisibilityRadioGroup = <TFieldValues extends FieldValues>({
  name,
}: GroupVisibilityRadioGroupProps<TFieldValues>) => (
  <RadioGroup<TFieldValues>
    name={name}
    groupClassName="mt-4 mb-0"
    options={[
      {
        label: "Open",
        description: "Anyone can find and join this group.",
        value: JoinableBy.Workspace,
        icon: "Eye",
      },
      {
        label: "Closed",
        description:
          "Anyone can find this group but must request to join to see its chats and threads.",
        value: JoinableBy.Approval,
        icon: "Lock",
      },
    ]}
    optionStyle="bordered"
  />
);
