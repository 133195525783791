import { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Mailbox, nodeAs } from "@utility-types";
import { Checkbox, Form, TextInput } from "components/design-system/Forms";
import { fileToFileUpload } from "components/MessageEditor/stream-helpers";
import {
  FetchDomainsQuery,
  FetchJoinApprovalsQuery,
  ThreadListQuery,
  useFetchDomainsLazyQuery,
  useFetchJoinApprovalsLazyQuery,
  useThreadListLazyQuery,
  useUpdateProfileMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useFileUploader from "hooks/useFileUploader";
import useForceUpdate from "hooks/useForceUpdate";
import useAppStateStore from "store/useAppStateStore";
import useOnboardingStore from "store/useOnboardingStore";
import { isNative } from "utils/platform";

import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";
import ProfileImage from "./ProfileImage";

type CallBack = Parameters<typeof useFileUploader>[0]["onChange"];
type State = Parameters<CallBack>[0];

type FormValues = {
  avatarURL?: string;
  name: string;
  bio?: string;
  termsOfService: boolean;
};

const FormContent = ({
  formSubmitting,
  setFile,
}: {
  formSubmitting: boolean;
  setFile: (f: File) => void;
}) => {
  const { signOut } = useAuthData();

  const { authenticated, reset, user } = useOnboardingStore(
    ({ authenticated, reset, user }) => ({
      authenticated,
      reset,
      user,
    })
  );
  const { watch, setValue, getValues } = useFormContext<FormValues>();
  const { name, termsOfService } = watch();

  useEffect(() => {
    // received an updated name from Firebase, such as Sign In with Apple
    if (user?.name && !getValues().name) {
      setValue("name", user.name);
    }
  }, [user?.name, getValues, setValue]);

  const handleClickBack = () => {
    reset();
    signOut();
  };

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setValue("avatarURL", URL.createObjectURL(file));
    setFile(file);
  };

  return (
    <ContentWrapper
      title="Tell us about yourself."
      headline="Just the basics. You can change this later."
    >
      <div className="flex flex-col gap-16">
        <ProfileImage onChange={onImageChange} name={name ?? ""} />
        <TextInput
          name="name"
          placeholder="Name"
          variant="line"
          config={{ required: true }}
          disabled={formSubmitting}
        />
        <TextInput
          name="bio"
          placeholder="Title..."
          variant="line"
          disabled={formSubmitting}
        />
      </div>
      <Checkbox
        className="text-border-disabled"
        wrapperClassName="!mt-32"
        name="termsOfService"
        disabled={formSubmitting}
      >
        <div className="text-footnote text-text-secondary flex items-center">
          Accept Glue's&nbsp;
          <a
            className="underline"
            href="https://glue.ai/terms"
            rel="noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>
          &nbsp;and&nbsp;
          <a
            className="underline"
            href="https://glue.ai/privacy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
          ?
        </div>
      </Checkbox>
      <Footer
        onClickBack={handleClickBack}
        submitDisabled={!termsOfService || formSubmitting}
        hideBackButton={authenticated && !isNative()}
        requireChanges={!authenticated}
        formSubmitting={formSubmitting}
        hideSkip
      />
    </ContentWrapper>
  );
};

const CompleteYourProfile = () => {
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const uploadsRef = useRef<State>(new Map());
  const formValuesRef = useRef<FormValues>();
  const forceUpdate = useForceUpdate();

  const {
    user,
    setState,
    currentStep,
    workspace: currentWorkspace,
    skippedJoinGroups,
    userSubscribed,
  } = useOnboardingStore(
    ({
      user,
      setState,
      currentStep,
      workspace,
      skippedJoinGroups,
      userSubscribed,
    }) => ({
      user,
      setState,
      currentStep,
      workspace,
      skippedJoinGroups,
      userSubscribed,
    })
  );

  const { handleAuthSignIn } = useAppStateStore(({ handleAuthSignIn }) => ({
    handleAuthSignIn,
  }));

  const { authData, fetchAuthData } = useAuthData();

  const [updateProfile] = useUpdateProfileMutation({
    errorPolicy: "all",
  });

  const [fetchDomains] = useFetchDomainsLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [fetchJoinApprovals] = useFetchJoinApprovalsLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [fetchThreadList] = useThreadListLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const goToNextView = useCallback(() => {
    let domains: FetchDomainsQuery["domains"]["edges"] = [];
    let workspaceInvites: FetchJoinApprovalsQuery["joinApprovals"]["edges"] =
      [];
    let groupInvites: FetchJoinApprovalsQuery["joinApprovals"]["edges"] = [];
    let threadList: ThreadListQuery["threads"]["edges"] = [];
    let userWorkspace: typeof currentWorkspace & { groupsCount: number };

    const promises = [
      fetchAuthData({ refresh: true }),
      new Promise(resolve => {
        resolve(
          (async () => {
            const { data } = await fetchDomains();
            domains = data?.domains.edges ?? [];
          })()
        );
      }),
      new Promise(resolve => {
        resolve(
          (async () => {
            const { data } = await fetchJoinApprovals();
            workspaceInvites =
              data?.joinApprovals.edges.filter(j =>
                nodeAs(j.node.joinable, ["WorkspacePreview"])
              ) ?? [];
            groupInvites =
              data?.joinApprovals.edges.filter(j =>
                nodeAs(j.node.joinable, ["GroupPreview"])
              ) ?? [];
          })()
        );
      }),
      new Promise(resolve => {
        resolve(
          (async () => {
            if (currentWorkspace) return;
            const { workspaces } = await fetchAuthData();
            const firstWorkspaceEdge = workspaces.edges[0];
            if (!firstWorkspaceEdge) return;
            // User has been added to a workspace before signing into Glue
            const firstWorkspace = firstWorkspaceEdge.node;
            userWorkspace = {
              ...firstWorkspace,
              admin: firstWorkspace.admin?.name ?? "",
              members: firstWorkspace.members.totalCount,
              role: firstWorkspaceEdge.memberRole,
              groupsCount: firstWorkspaceEdge.node.groups.totalCount,
              type: "added",
            };
          })()
        );
      }),
      new Promise(resolve => {
        resolve(
          (async () => {
            const { data } = await fetchThreadList({
              variables: { mailbox: Mailbox.Inbox },
            });
            threadList = data?.threads.edges ?? [];
          })()
        );
      }),
    ];

    Promise.all(promises)
      .then(() => {
        const workspacesAvailableByDomain = domains.flatMap(
          domain => domain.node.workspaces?.edges
        );

        // is native app, no workspace invites, no workspaces available by domain, user hasn't created a workspace yet and has not been added to a workspace via Slack import
        if (
          isNative() &&
          !workspaceInvites.length &&
          !workspacesAvailableByDomain?.length &&
          !currentWorkspace &&
          !userWorkspace
        ) {
          setState({
            authenticated: true,
            view: "NoWorkspaceWall",
            currentStep: currentStep + 1,
            totalSteps: 2,
          });
          return;
        }

        // No workspace invites, no group invites, no workspaces to join by domain, user hasn't created a workspace yet and has not been added to a workspace via Slack import
        if (
          ((!isNative() &&
            !workspaceInvites.length &&
            !groupInvites.length &&
            !workspacesAvailableByDomain?.length) ||
            currentWorkspace?.type === "created") &&
          !userWorkspace &&
          currentWorkspace?.type !== "added"
        ) {
          setState({
            authenticated: true,
            view: userSubscribed ? "CreateWorkspace" : "SelectTeamSize",
            currentStep: currentStep + 1,
            totalSteps: userSubscribed ? 6 : 7,
          });
          return;
        }

        if (
          workspacesAvailableByDomain?.length ||
          workspaceInvites.length ||
          userWorkspace ||
          currentWorkspace?.type === "added"
        ) {
          const skipJoinGroups =
            skippedJoinGroups ||
            (userWorkspace && (userWorkspace?.groupsCount ?? 0) === 0);
          setState({
            authenticated: true,
            view: "JoinWorkspace",
            hasWorkspacesToJoin: true,
            currentStep: currentStep + 1,
            totalSteps: skipJoinGroups ? 3 : 4,
            workspace: currentWorkspace ?? userWorkspace,
            skippedJoinGroups: skipJoinGroups,
          });
          return;
        }

        if (
          !workspaceInvites.length &&
          (groupInvites.length || threadList.length)
        ) {
          setState({
            authenticated: true,
            view: "Review",
            currentStep: currentStep + 1,
            totalSteps: 2,
            goBackTo: "CompleteYourProfile",
          });
          return;
        }
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  }, [
    fetchAuthData,
    fetchDomains,
    fetchJoinApprovals,
    currentWorkspace,
    fetchThreadList,
    setState,
    currentStep,
    userSubscribed,
    skippedJoinGroups,
  ]);

  const uploadChange = (state: State) => {
    const newFile = [...state.values()][0];
    if (!newFile) return;

    const url = newFile.url ?? newFile.uploadInfo.previewUri;
    if (!url) return;

    const formValues = formValuesRef.current;
    updateProfile({
      variables: {
        input: {
          ...formValues,
          avatarURL: url ?? "",
        },
      },
    })
      .then(() => {
        goToNextView();
      })
      .catch(() => setFormSubmitting(false));
  };

  useFileUploader({
    onChange: uploadChange,
    orderedUploads: uploadsRef,
  });

  const updateProfileWithImage = (values: FormValues) => {
    if (!profilePicture) return;

    const temp = new Map();
    temp.set(profilePicture.name, fileToFileUpload(profilePicture));
    uploadsRef.current = temp;
    formValuesRef.current = values;

    forceUpdate();
  };

  const signInSuccessful = (values: FormValues) => {
    if (profilePicture) {
      updateProfileWithImage(values);
      return;
    }
    updateProfile({ variables: { input: { ...values } } })
      .then(() => {
        goToNextView();
      })
      .catch(() => setFormSubmitting(false));
  };

  const handleSubmit = (values: FormValues) => {
    setFormSubmitting(true);
    !authData?.me
      ? handleAuthSignIn?.()
          .then(() => {
            signInSuccessful(values);
          })
          .finally(() => setFormSubmitting(false))
      : signInSuccessful(values);
  };

  return (
    <Form<FormValues>
      className="w-full"
      onSubmit={handleSubmit}
      initialFocusOnField="name"
      useFormProps={{
        defaultValues: {
          name: authData?.me.name ?? user?.name ?? "",
          avatarURL: authData?.me.avatarURL ?? user?.avatarURL ?? "",
          bio: authData?.me.bio ?? "",
          termsOfService: !!authData?.me,
        },
      }}
    >
      <FormContent
        setFile={setProfilePicture}
        formSubmitting={formSubmitting}
      />
    </Form>
  );
};

export default CompleteYourProfile;
