import { memo } from "react";
import { GroupStyle } from "stream-chat-react";

import { StreamImageAttachment } from "@utility-types";
import {
  PhotoSwipeGallery,
  defaultPhotoSwipeGalleryOptions,
} from "components/PhotoSwipe";
import tw from "utils/tw";

import GalleryImage from "./GalleryImage";
import SingleImage from "./SingleImage";

type Props = {
  disablePointerEvents?: boolean;
  firstGroupStyle?: GroupStyle;
  galleryCols?: number;
  galleryWidth?: number;
  id: string;
  images: StreamImageAttachment[];
};

function Image({
  disablePointerEvents = false,
  firstGroupStyle,
  galleryCols = 3,
  galleryWidth = 768,
  id,
  images,
}: Props): JSX.Element | null {
  const isGallery = images.length > 1;
  const gridGap = isGallery ? 6 : 0;

  return (
    <div
      className={tw(
        "my-6 overflow-hidden w-full",
        disablePointerEvents && "pointer-events-none"
      )}
      style={{ maxWidth: galleryWidth }}
    >
      <div
        className={tw("relative", isGallery ? "grid" : "flex flex-wrap", {
          "pt-8": firstGroupStyle !== "single" && firstGroupStyle !== "top",
        })}
        style={
          isGallery
            ? {
                gap: gridGap,
                gridTemplateColumns: `repeat(${galleryCols}, 1fr)`,
              }
            : {}
        }
      >
        <PhotoSwipeGallery
          id={id}
          options={{
            ...defaultPhotoSwipeGalleryOptions,
            showAnimationDuration: isGallery ? 0 : 580,
            showHideOpacity: isGallery,
          }}
        >
          {isGallery
            ? images.map(image => {
                return (
                  <GalleryImage
                    key={image.image_url}
                    galleryWidth={galleryWidth}
                    gridCols={galleryCols}
                    gridGap={gridGap}
                    image={image}
                  />
                );
              })
            : images.map(image => (
                <SingleImage
                  key={image.image_url}
                  galleryWidth={galleryWidth}
                  image={image}
                />
              ))}
        </PhotoSwipeGallery>
      </div>
    </div>
  );
}

export default memo(
  Image,
  (prev, next) =>
    prev.images.map(({ file_id }) => file_id).toString() ===
    next.images.map(({ file_id }) => file_id).toString()
);
