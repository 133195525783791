import { useEffect, useMemo } from "react";
import { Flipped, Flipper } from "react-flip-toolkit";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useHistory } from "react-router";

import { ThreadEdgeSimple } from "@utility-types";
import { flipAnimate, flipSpring } from "components/Animated/utils";
import { EmptyListPrimitive } from "components/design-system/EmptyListPrimitive";
import { SectionItem } from "components/design-system/ui/sections-sidebar";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import { useEducationWelcomeCard } from "components/Education";
import FindThread from "components/FindThread";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  routeParams as getRouteParams,
  locationFromRoute,
  routeToThread,
} from "components/routing/utils";
import Skeleton, {
  cloneElementForSkeletons,
} from "components/Skeleton/Skeleton";
import { useThreadListData } from "components/threads-list/hooks";
import { ThreadsMailbox, ThreadsOrder } from "generated/graphql";
import useOnce from "hooks/useOnce";
import useAppStateStore from "store/useAppStateStore";
import env from "utils/processEnv";
import tw from "utils/tw";

import { useInboxSidebarSectionsState } from "../inbox/providers/InboxSidebarSectionsProvider";
import useThreadListSearch from "../search/hooks/useThreadListSearch";

import AIListItem from "./AIListItem";
import { AI_SIDEBAR_ID } from "./AIMain";
import AISidebarAccessories from "./AISidebarAccessories";
import AIThreadFloatingMenu from "./AIThreadFloatingMenu";
import useGroupByDate from "./hooks/useGroupByDate";

const AIThreadList = ({
  scrollRef,
  setScrollRef,
}: {
  scrollRef?: HTMLDivElement | HTMLUListElement | null;
  setScrollRef?: (el: HTMLDivElement | null) => void;
  skipAutoSelect?: boolean;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const history = useHistory();
  const { route } = usePartitionState(({ route }) => ({ route }));
  const routeParams = getRouteParams(locationFromRoute(route));
  const { threadID: selectedID } = routeParams;

  const { showCampaign, onWelcomeCardViewed, onWelcomeCardDismissed } =
    useEducationWelcomeCard("aiSuperTab");

  useOnce(() => {
    onWelcomeCardViewed();
  });

  const pageSize = Math.ceil(window.innerHeight / 32);

  const threadListData = useThreadListData({
    mailbox: ThreadsMailbox.Ai,
    order: ThreadsOrder.Created,
    pageSize,
    recipientID: env.glueAIBotID,
  });

  const {
    hasNextPage,
    loadNextPage,
    result: { error, loading, threadEdges },
  } = threadListData;

  const { foundThreads, isFindingThreads, searching, setMatch } =
    useThreadListSearch(ThreadsMailbox.Ai);

  const edges = useMemo(
    () =>
      isFindingThreads ? foundThreads : (threadEdges?.slice().reverse() ?? []),
    [foundThreads, isFindingThreads, threadEdges]
  );

  const [scrollSentryRef, { rootRef: scrollListRef }] = useInfiniteScroll({
    disabled: !!error,
    hasNextPage,
    loading,
    onLoadMore: loadNextPage,
    rootMargin: "0px 0px 200px 0px",
  });

  const dateRanges = useGroupByDate(
    edges.map(edge => ({
      createdAt: edge.node.createdAt,
      item: edge,
    }))
  );

  const dateRangesFlat: (string | ThreadEdgeSimple)[] = useMemo(() => {
    return Array.from(dateRanges).reduce<(string | ThreadEdgeSimple)[]>(
      (acc, [dateRange, threads]) => {
        acc.push(dateRange);
        threads.forEach(thread => {
          acc.push(thread);
        });
        return acc;
      },
      []
    );
  }, [dateRanges]);

  const navigateToThreadID = (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>
      | undefined,
    threadID?: string
  ) => {
    if (!threadID) return;
    const to = e?.ctrlKey || e?.metaKey ? "secondary" : "primary";
    history.push(routeToThread({ superTab: "ai", threadID, to }));
  };

  const { swipedOpenItemId, setState: setInboxSidebarSectionsState } =
    useInboxSidebarSectionsState(({ swipedOpenItemId }) => ({
      swipedOpenItemId,
    }));
  const setSwipedOpenItemId = (id?: string) => {
    setInboxSidebarSectionsState({ swipedOpenItemId: id });
  };

  useEffect(() => {
    scrollListRef(scrollRef);
  }, [scrollRef, scrollListRef]);

  return (
    <ViewScrollContainer
      accessories={
        <AISidebarAccessories
          showCampaign={showCampaign}
          onWelcomeCardDismissed={onWelcomeCardDismissed}
        />
      }
      className="flex flex-col grow min-h-0 pt-8 px-8"
      id={breakpointMD ? AI_SIDEBAR_ID : undefined}
      scrollRef={setScrollRef}
    >
      <div className="grow min-h-0">
        <FindThread className="grow min-w-0 px-8" onChange={setMatch} />

        {dateRangesFlat.length > 0 ? (
          <Flipper
            key={threadEdges ? "ready" : "loading"}
            element="ol"
            className="overflow-x-hidden"
            flipKey={dateRangesFlat
              .map(item => (typeof item === "string" ? item : item.id))
              .join()}
          >
            {dateRangesFlat.map(item =>
              typeof item === "string" ? (
                <Flipped
                  key={item}
                  flipId={item}
                  onAppear={el => flipAnimate(el)}
                  onExit={(_el, _i, remove) => remove()}
                  spring={flipSpring}
                >
                  <div
                    key={item}
                    className={tw(
                      "flex items-center h-32 pr-4 pl-8 text-footnote-bold text-text-secondary",
                      {
                        "mt-16": item !== dateRangesFlat[0],
                      }
                    )}
                  >
                    {item}
                  </div>
                </Flipped>
              ) : (
                <SectionItem
                  key={item.node.id}
                  flipId={item.node.id}
                  itemData={item}
                  onClick={e => navigateToThreadID(e, item.node.id)}
                  setSwipedOpenItemId={setSwipedOpenItemId}
                  swipedOpenItemId={swipedOpenItemId}
                  type="aiThread"
                >
                  <AIListItem
                    isSelected={selectedID === item.node.id}
                    subject={item.node.subject}
                  >
                    <AIThreadFloatingMenu threadEdge={item} />
                  </AIListItem>
                </SectionItem>
              )
            )}

            {hasNextPage && (
              <>
                <div ref={scrollSentryRef} data-testid="ai-sidebar-load-more" />
                {cloneElementForSkeletons(
                  <Skeleton className="h-44 md:h-32 rounded-lg" width="100%" />,
                  5
                )}
              </>
            )}
          </Flipper>
        ) : (loading && !threadEdges) || searching ? (
          cloneElementForSkeletons(
            <Skeleton className="h-44 md:h-32 rounded-lg" width="100%" />,
            5
          )
        ) : (
          <EmptyListPrimitive
            icon="Search"
            padding="px-8 py-16"
            primaryText="No conversations found"
            secondaryText="Try adjusting your search or check for typos."
          />
        )}
      </div>
    </ViewScrollContainer>
  );
};

export default AIThreadList;
