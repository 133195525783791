import { AppUnfurlSetup, EphemeralMessage } from "@utility-types";
import useEphemeralMessage from "hooks/state/useEphemeralMessage";
import generateRandomId from "utils/generateRandomId";
import { isNativeAndroid } from "utils/platform";

const useAppUnfurlSetupMessage = () => {
  const { postEphemeralMessage } = useEphemeralMessage();

  const sendSetupMessages = (
    unfurlMessageID: string,
    threadID: string,
    appUnfurlSetups: AppUnfurlSetup[]
  ) => {
    if (isNativeAndroid()) return;

    const appUserIDs = new Set<string>();
    appUnfurlSetups.forEach(appUnfurlSetup => {
      const appUser = appUnfurlSetup.appUser;

      // Do not send more than 1 setup message per app
      if (appUserIDs.has(appUser.id)) {
        return;
      }

      appUserIDs.add(appUser.id);

      const ephemeralMessageID = `${appUser.id}-${generateRandomId()}`;
      const createdAt = new Date().toISOString();
      const text = `${appUnfurlSetup.message}\n\n**[Connect](masonry:${appUnfurlSetup.path})**`;
      const ephemeralMessage: EphemeralMessage = {
        id: ephemeralMessageID,
        text,
        threadID,
        user: {
          __typename: "User",
          id: appUser.id,
          name: appUser.name,
          avatarURL: appUser.avatarURL,
          bio: appUser.bio,
          workspaceIDs: appUser.workspaceIDs,
          addressDomains: [],
        },
        unfurlMessageID,
        createdAt,
        updatedAt: null,
      };
      postEphemeralMessage(ephemeralMessage);
    });
  };

  return { sendSetupMessages };
};

export default useAppUnfurlSetupMessage;
