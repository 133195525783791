import { useCallback, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { nodeAs } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { Pill } from "components/design-system/Pill";
import { SelectDropdown } from "components/design-system/SelectDropdown";
import CreateGroupModal from "components/group/CreateGroup/CreateGroupModal";
import {
  GroupsOrder,
  useFetchWorkspaceGroupsDirectoryQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import useFeatureFlagStore from "store/useFeatureFlagStore";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import {
  WORKSPACE_GENERAL_DESCRIPTION,
  WORKSPACE_GENERAL_NAME,
} from "../consts";

import GroupDirectorySkeletons from "./GroupDirectorySkeletons";
import GroupsDirectoryRow, {
  GroupsDirectoryRowPrimitive,
} from "./GroupsDirectoryRow";
import GroupsDirectorySearchInput from "./GroupsDirectorySearchInput";

enum PrimaryFilters {
  All = "All",
  New = "New",
  MyGroups = "My groups",
  Recent = "Recent",
}
enum SecondaryFilters {
  Archived = "Archived",
}
type Filters = PrimaryFilters | SecondaryFilters;

const getFilterText = (filter: Filters, breakpointMD: boolean) => {
  if (filter === PrimaryFilters.All && !breakpointMD) return "All Groups";
  return filter;
};

const FilterOrders = {
  [PrimaryFilters.All]: GroupsOrder.Name,
  [PrimaryFilters.New]: GroupsOrder.Added,
  [PrimaryFilters.MyGroups]: GroupsOrder.Name,
  [PrimaryFilters.Recent]: GroupsOrder.Interaction,
  [SecondaryFilters.Archived]: GroupsOrder.Name,
};

const GroupDirectory = ({
  searchState,
  workspaceID,
}: {
  searchState: [string, React.Dispatch<React.SetStateAction<string>>];
  workspaceID: string;
}) => {
  const { ARCHIVE_GROUPS_FOR_EVERYONE } = useFeatureFlagStore(
    ({ flags: { ARCHIVE_GROUPS_FOR_EVERYONE } }) => ({
      ARCHIVE_GROUPS_FOR_EVERYONE,
    })
  );
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const { authData, authReady } = useAuthData();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const [selectedFilter, setSelectedFilter] = useState<Filters>(
    PrimaryFilters.All
  );

  const pageSize = Math.ceil(window.innerHeight / 64); // 64px is the height of a row

  const [searchTerm, setSearchTerm] = searchState;

  const isMyGroupsSelected = selectedFilter === PrimaryFilters.MyGroups;
  const isRecentSelected = selectedFilter === PrimaryFilters.Recent;
  const isArchivedSelected = selectedFilter === SecondaryFilters.Archived;

  const { data, fetchMore, loading } = useFetchWorkspaceGroupsDirectoryQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !(workspaceID && authData),
    variables: {
      groupsFirst: isRecentSelected ? undefined : pageSize,
      groupsLast: isRecentSelected ? pageSize : undefined,
      groupsOrder: FilterOrders[selectedFilter],
      groupsArchived: isArchivedSelected ? true : undefined,
      id: `${workspaceID}-${authData?.me.id}`,
      match: searchTerm,
      member: isMyGroupsSelected ? true : undefined,
    },
  });

  const workspaceEdge = nodeAs(data?.node, ["WorkspaceEdge"]);

  const {
    endCursor: afterCursor,
    hasNextPage,
    hasPreviousPage,
    startCursor: beforeCursor,
  } = workspaceEdge?.node.groups.pageInfo ?? {
    endCursor: null,
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
  };

  const loadGroups = useCallback(
    async (cursor: string) => {
      if (!authReady) return;
      fetchMore({
        variables: {
          groupsAfter: isRecentSelected ? undefined : cursor,
          groupsBefore: isRecentSelected ? cursor : undefined,
        },
      });
    },
    [fetchMore, isRecentSelected, authReady]
  );

  const [scrollSentryRef, { rootRef: listRef }] = useInfiniteScroll({
    hasNextPage: isRecentSelected ? hasPreviousPage : hasNextPage,
    loading,
    onLoadMore: useCallback(() => {
      const cursor = isRecentSelected ? beforeCursor : afterCursor;
      if (!cursor) return;
      loadGroups(cursor);
    }, [afterCursor, beforeCursor, isRecentSelected, loadGroups]),
    rootMargin: "0px 0px 200px 0px",
  });

  const handleCreateGroup = () => {
    if (!workspaceEdge) return;
    openModal(<CreateGroupModal workspaceID={workspaceEdge.node.id} />);
  };

  const orderedGroups = useMemo(() => {
    const groupIDs =
      workspaceEdge?.node.groups.edges.map(group => group.node.id) ?? [];
    return isRecentSelected ? groupIDs.slice().reverse() : groupIDs;
  }, [isRecentSelected, workspaceEdge?.node.groups.edges]);

  const workspaceGeneralGroupName = `${workspaceEdge?.node.name} ${WORKSPACE_GENERAL_NAME}`;
  const searchMatchesGeneralGroup =
    !!searchTerm &&
    workspaceGeneralGroupName.toLowerCase().includes(searchTerm.toLowerCase());

  const showGeneralGroupRow =
    workspaceEdge &&
    !isArchivedSelected &&
    (!searchTerm || searchMatchesGeneralGroup);

  const renderNoSearchResults = () => {
    if (loading || showGeneralGroupRow || orderedGroups.length) return null;

    if (isArchivedSelected && !searchTerm) {
      return (
        <div className="w-full flex justify-center mt-16">
          <div className="px-24 py-16 text-center max-w-[375px] cursor-default select-none">
            <p className="m-0 text-body-bold text-text-primary">
              Nothing... yet!
            </p>
            <p className="m-0 text-body text-text-secondary">
              Archived groups will appear here.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="w-full flex justify-center mt-16">
        <div className="px-24 py-16 text-center max-w-[375px] cursor-default select-none">
          <p className="m-0 text-body-bold text-text-primary">
            No groups found
          </p>
          <p className="m-0 text-body text-text-secondary">
            Try using different keywords or check for typos.
          </p>
        </div>
      </div>
    );
  };

  const renderGeneralGroupRow = () => {
    if (!showGeneralGroupRow) return null;

    return (
      <GroupsDirectoryRowPrimitive
        description={WORKSPACE_GENERAL_DESCRIPTION}
        edge={workspaceEdge}
        title={workspaceGeneralGroupName}
        isGeneralGroup
      />
    );
  };

  const shouldDisplaySkeletons =
    (loading && (!data || !!searchTerm)) ||
    (isRecentSelected ? hasPreviousPage : hasNextPage);

  return (
    <div
      ref={listRef}
      className="flex flex-col gap-16 grow overflow-auto py-16"
    >
      <div className="flex items-center justify-between max-w-[832px] mx-auto px-16 w-full">
        <div className="w-full">
          <Form useFormProps={{ defaultValues: { search: searchTerm } }}>
            <GroupsDirectorySearchInput onChange={setSearchTerm} />
          </Form>
        </div>
      </div>

      <div className="flex gap-16 items-start justify-between max-w-[832px] mx-auto px-16 w-full">
        {breakpointMD ? (
          <ul
            className="flex items-center justify-start gap-8"
            data-testid="Groups Directory Pills"
          >
            {Object.values(PrimaryFilters).map(filter => (
              <Pill
                key={filter}
                name={filter}
                onClick={setSelectedFilter}
                selected={filter === selectedFilter}
              >
                {getFilterText(filter, breakpointMD)}
              </Pill>
            ))}
            {ARCHIVE_GROUPS_FOR_EVERYONE && (
              <>
                <div className="p-4">
                  <div className="border-l-1 w-0 h-20 border-border-strong" />
                </div>
                <Pill
                  name={SecondaryFilters.Archived}
                  onClick={setSelectedFilter}
                  selected={selectedFilter === SecondaryFilters.Archived}
                  variant="secondary"
                  icon="ArchiveFilled"
                  iconPosition="leading"
                >
                  {getFilterText(SecondaryFilters.Archived, breakpointMD)}
                </Pill>
              </>
            )}
          </ul>
        ) : (
          <SelectDropdown
            options={[
              {
                items: Object.values(PrimaryFilters).map(filterName => ({
                  onClick: () => setSelectedFilter(filterName),
                  text: getFilterText(filterName, breakpointMD),
                })),
                name: "primary",
              },
              ...(ARCHIVE_GROUPS_FOR_EVERYONE
                ? [
                    {
                      items: [
                        {
                          icon: "Archive" as const,
                          onClick: () =>
                            setSelectedFilter(SecondaryFilters.Archived),
                          text: SecondaryFilters.Archived,
                        },
                      ],
                      name: "secondary",
                    },
                  ]
                : []),
            ]}
            text={getFilterText(selectedFilter, breakpointMD)}
          />
        )}

        {workspaceEdge?.node.id && (
          <Button
            buttonStyle="simplePrimary"
            className="font-black text-sm !px-0 text-nowrap"
            icon="Plus"
            iconClassName="mr-6"
            iconSize={20}
            onClick={handleCreateGroup}
          >
            Create Group
          </Button>
        )}
      </div>

      <div className="max-w-[832px] mx-auto px-16 w-full">
        {renderNoSearchResults()}

        <div
          className={tw(
            "bg-background-body rounded-lg shadow-level1 overflow-hidden",
            "[&>div]:border-b-1 [&>div]:border-border-subtle", // Row borders
            "[&>div:last-child]:border-none" // Remove last row border
          )}
        >
          {renderGeneralGroupRow()}

          {orderedGroups?.map(groupID => (
            <GroupsDirectoryRow key={groupID} groupID={groupID} />
          ))}

          <GroupDirectorySkeletons
            ref={scrollSentryRef}
            count={4}
            on={shouldDisplaySkeletons}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupDirectory;
