import { ButtonHTMLAttributes } from "react";

import { Icon } from "components/design-system/icons";
import tw from "utils/tw";

type Props = Pick<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
  icon: "ThumbsUp" | "ThumbsDown";
};

export const FeedbackButton = ({ icon, ...props }: Props) => {
  return (
    <button
      {...props}
      className={tw(
        "text-icon-secondary hover:text-icon-secondary-hover",
        "py-3 first:pl-7 first:pr-3 last:pl-3 last:pr-7"
      )}
    >
      <Icon icon={icon} size={16} strokeWidth={1.5} />
    </button>
  );
};
