import { format } from "date-fns";

import { CustomEmoji } from "@utility-types/graphql";
import { Icon } from "components/design-system/icons";
import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { ConfirmationAlert, StandardModal } from "components/Modals";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import { useDeleteCustomEmojiMutation } from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";

import useCustomEmojisList from "../../emoji/hooks/useCustomEmojisList";
import { Button } from "../Button";

const ManageEmojisModal = ({
  openCreateModal,
  ...props
}: { openCreateModal: (modalID?: string) => void } & ModalProps) => {
  const { openModal, closeModal } = useModalStore(
    ({ openModal, closeModal }) => ({ openModal, closeModal })
  );
  const { authData } = useAuthData();

  const [deleteCustomEmoji] = useDeleteCustomEmojiMutation();

  const { emojisPerWorkspace, emojiDataLoading } = useCustomEmojisList(true);

  const { evictNode } = useCacheEvict();

  const deleteEmoji = (emoji: CustomEmoji) => {
    openModal(
      <ConfirmationAlert
        header={`Are you sure you want to delete this emoji (:${emoji.name}:)?`}
        confirmLabel="Delete"
        onConfirm={() =>
          deleteCustomEmoji({
            variables: { id: emoji.id },
            update: (cache, { data }) => {
              if (!data?.deleteCustomEmoji) return;
              evictNode(emoji, cache);
            },
          })
        }
      />
    );
  };

  const emojiCount = (count: number) => `${count} emoji${count > 1 ? "s" : ""}`;
  const emojiWorkspaceHeader = (workspace: string, count: number) =>
    (authData?.workspaces.edges.length ?? 0) > 1
      ? `${workspace} ${count > 0 ? `(${emojiCount(count)})` : ""}`
      : emojiCount(count);

  const EmojiSkeleton = () => {
    return (
      <>
        <span className="px-20 pb-8 text-subhead-bold">
          {emojiWorkspaceHeader("", 0)}
        </span>
        {cloneElementForSkeletons(
          <div className="flex px-20 py-8 border-b-1 border-border-subtle">
            <Skeleton height="32px" width="32px" />
            <div className="flex flex-col ml-8">
              <Skeleton width="100px" />
              <Skeleton width="280px" />
            </div>
          </div>,
          8
        )}
      </>
    );
  };

  return (
    <StandardModal {...props}>
      <Header className="!px-20">Manage emojis</Header>
      <Main className="flex flex-col pt-16 border-t-1 border-border-container min-h-[300px]">
        {emojiDataLoading && !emojisPerWorkspace.length ? (
          <EmojiSkeleton />
        ) : !emojisPerWorkspace.length ? (
          <div className="flex flex-col justify-center items-center px-24 grow">
            <Icon className="text-icon-secondary" icon="Reaction" size={24} />
            <span className="text-body text-text-secondary mt-8">
              No custom emojis... yet.
            </span>
          </div>
        ) : (
          emojisPerWorkspace?.map(workspaceEmojis => (
            <div className="mb-16" key={workspaceEmojis.workspaceName}>
              <span className="px-20 text-subhead-bold">
                {emojiWorkspaceHeader(
                  workspaceEmojis.workspaceName,
                  workspaceEmojis.emojis.length
                )}
              </span>
              {workspaceEmojis.emojis.length ? (
                workspaceEmojis.emojis.map(emoji => {
                  const { createdAt, createdBy, id, imageURL, name } = emoji;
                  return (
                    <div
                      className="w-full flex items-center py-8 px-20 h-56 group/emoji border-b-1 border-border-subtle"
                      key={id}
                    >
                      <div className="flex justify-center w-32 h-32">
                        <img
                          alt="Uploaded emoji by user"
                          width={32}
                          height={32}
                          style={{ objectFit: "contain" }}
                          src={glueImageURL(imageURL, {
                            fit: "max",
                            h: 32,
                            w: 32,
                          })}
                          srcSet={`${glueImageURL(imageURL, {
                            fit: "max",
                            h: 32,
                            w: 32,
                          })} 1x, ${glueImageURL(imageURL, {
                            fit: "max",
                            h: 64,
                            w: 64,
                          })} 2x`}
                        />
                      </div>
                      <div className="flex flex-col ml-8">
                        <span className="text-subhead-bold">:{name}:</span>
                        <span className="text-footnote text-text-subtle">
                          Added by {createdBy.name} on{" "}
                          {format(new Date(createdAt), "MMMM d, yyy")}
                        </span>
                      </div>
                      <div className="grow flex justify-end">
                        <Button
                          className={tw("hidden", {
                            "group-hover/emoji:block":
                              workspaceEmojis.isAdmin ||
                              createdBy.id === authData?.me.id,
                          })}
                          buttonStyle="secondaryDestructive"
                          onClick={() => deleteEmoji(emoji)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex items-center h-56 px-20 text-footnote border-b-1 border-border-subtle">
                  <span>No custom emoji in this workspace... yet.</span>
                </div>
              )}
            </div>
          ))
        )}
      </Main>
      <Footer className="!px-16" flexboxClassName="justify-between">
        <Button
          buttonStyle="subtle"
          onClick={() => openCreateModal(props.modalId)}
        >
          Add another emoji
        </Button>
        <div className="flex">
          <Button onClick={() => closeModal(props.modalId)}>Done</Button>
        </div>
      </Footer>
    </StandardModal>
  );
};

export default ManageEmojisModal;
