import { nodeAs, ThreadEdgeSimple } from "@utility-types";
import { MemberRole, MessageableBy } from "generated/graphql";

import useAuthData from "./useAuthData";
import useChatRecipient from "./useChatRecipient";

type Reasons = {
  /** The thread is a group chat for a group that has been archived for everyone. */
  archived?: boolean;
  /** The thread has been marked as restricted so only admins can reply. */
  restricted?: boolean;
};

type Result = {
  /** If true, the thread edge is messageable. If false, the reason can be found in {@link Result.reasons | reasons}. */
  value: boolean;
  /**
   * If {@link Result.value | value} is false, the reasons why the thread edge is not messageable are defined here.
   *
   * Multiple reasons can be true at the same time - it is up to the consumer to decide how to handle this.
   */
  reasons: Reasons;
};

const useThreadEdgeMessageable = (edge?: ThreadEdgeSimple): Result => {
  const { authData } = useAuthData();
  const groupRecipient = useChatRecipient(["GroupPreview"])(edge?.node);

  // assume the edge is messageable if it is undefined to prevent flashing
  // the UI related to non-messageable threads
  let value = true;
  const reasons: Reasons = {};

  if (edge) {
    if (
      edge.node.isPersistentChat &&
      nodeAs(groupRecipient, ["GroupPreview"])?.archivedAt
    ) {
      value = false;
      reasons.archived = true;
    }

    if (
      edge.node.messageableBy !== MessageableBy.Recipient &&
      edge.node.recipients.edges?.[0]?.node.id !== authData?.me.id &&
      edge.recipientRole !== MemberRole.Admin
    ) {
      value = false;
      reasons.restricted = true;
    }
  }

  return { value, reasons };
};

export default useThreadEdgeMessageable;
