import { Button } from "components/design-system/Button";
import { EmptyListPrimitive } from "components/design-system/EmptyListPrimitive";
import { Skeleton } from "components/Skeleton";

export const LoadingSkeletons = () => (
  <div className="flex items-center gap-8 pb-8">
    <Skeleton
      className="dark:bg-background-subtle"
      height="32px"
      width="32px"
    />
    <div className="flex flex-col">
      <Skeleton
        className="dark:bg-background-subtle"
        height="20px"
        width="250px"
      />
      <Skeleton
        className="dark:bg-background-subtle"
        height="18px"
        width="110px"
      />
    </div>
  </div>
);

export const LabelSkeleton = () => (
  <Skeleton className="dark:bg-background-subtle" height="18px" width="110px" />
);

export const EmptyMembersList = ({
  handleAddMember,
}: { handleAddMember: () => void }) => (
  <div className="mt-32">
    <EmptyListPrimitive
      primaryText="No members found"
      secondaryText="Try adjusting your search or check for typos."
      icon="Search"
    >
      <Button onClick={handleAddMember}>Add a member</Button>
    </EmptyListPrimitive>
  </div>
);
