import { InformationBubble } from "components/design-system/InformationBubble";
import GroupProfileModal, {
  GroupProfileTab,
} from "components/group/GroupModal/GroupProfileModal";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";

import { BannerActionButton } from "./BannerActionButton";

export const ArchivedGroupChatBanner = ({
  isAdmin = false,
  groupID,
}: {
  isAdmin: boolean;
  groupID?: string;
}) => {
  const { breakpointMD } = useAppStateStore(state => ({
    breakpointMD: state.breakpointMD,
  }));
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const onClickCTA =
    isAdmin && groupID
      ? () => {
          openModal(
            <GroupProfileModal
              groupID={groupID}
              defaultTab={GroupProfileTab.Settings}
            />
          );
        }
      : undefined;
  return (
    <div className="px-16 pb-16">
      <InformationBubble className="!rounded-lg" iconProps={{ size: 16 }}>
        <div className="flex items-center justify-between gap-4">
          <span className="text-text-secondary">
            This group is archived for everyone.{" "}
            {isAdmin
              ? "As an admin, you can unarchive."
              : "Only admins can unarchive."}
            {onClickCTA && !breakpointMD && (
              <>
                {" "}
                <BannerActionButton
                  label="Settings"
                  onClick={onClickCTA}
                  inline
                />
              </>
            )}
          </span>
          {onClickCTA && breakpointMD && (
            <BannerActionButton label="Settings" onClick={onClickCTA} />
          )}
        </div>
      </InformationBubble>
    </div>
  );
};
