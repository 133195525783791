import { ComponentProps } from "react";

import { ColorSchemeTypes } from "@utility-types";
import illustrationNotification from "assets/illustration-notification.svg";
import { Illustration } from "components/design-system/illustrations";
import { Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import useLocalSettingsStore from "store/useLocalSettingsStore";

import { StandardModal } from "./StandardModal";

type Props = ModalProps;

export const MacSystemNotificationsModal = (props: Props) => {
  const { colorSchemePreview, colorScheme } = useLocalSettingsStore(
    ({ colorSchemePreview, colorScheme }) => ({
      colorSchemePreview,
      colorScheme,
    })
  );

  const currentColorScheme =
    colorSchemePreview === colorScheme ? colorScheme : colorSchemePreview;
  const illustration: ComponentProps<typeof Illustration>["illustration"] =
    currentColorScheme === ColorSchemeTypes.Dark
      ? "MacAlertNight"
      : "MacAlertDay";

  return (
    <StandardModal
      closeButtonPosition="right"
      contentClassName="w-[640px] h-[420px] rounded-lg pt-24"
      {...props}
    >
      <Main className="flex flex-col items-center justify-center self-stretch py-32 px-20">
        <div className="flex flex-col items-center gap-24 max-w-400">
          <div className="flex flex-col items-center self-stretch gap-16">
            <img
              alt="Notification Bell Icon"
              src={illustrationNotification}
              width={56}
              height={56}
            />
            <div className="flex flex-col items-center self-stretch text-center">
              <div className="text-title-3 text-text-primary">
                Allow Glue notifications on macOS
              </div>
              <div className="text-headline text-text-primary">
                Click the <span className="text-headline-bold">Options</span>{" "}
                dropdown and select{" "}
                <span className="text-headline-bold">Allow</span> when prompted
                in the top-right corner of your screen.
              </div>
            </div>
          </div>
          <Illustration
            illustration={illustration}
            className="w-[359px] h-[127px]"
          />
        </div>
      </Main>
    </StandardModal>
  );
};
