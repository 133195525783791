
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "ActivityMeta": [
      "ActivityReactedToMessageMeta"
    ],
    "ActivityObject": [
      "GroupPreview",
      "JoinApproval",
      "Link",
      "Message",
      "ThreadPreview",
      "User"
    ],
    "ActivityTarget": [
      "GroupPreview",
      "ThreadPreview",
      "WorkspacePreview"
    ],
    "Attachment": [
      "ExternalObject",
      "File",
      "ThreadPreview"
    ],
    "Connection": [
      "AddressConnection",
      "AppConnection",
      "CustomEmojiConnection",
      "DeviceConnection",
      "DomainConnection",
      "DraftConnection",
      "FileConnection",
      "GroupConnection",
      "GroupMemberConnection",
      "JoinApprovalConnection",
      "LLMModelConnection",
      "LambdaConnection",
      "LambdaSecretConnection",
      "LinkConnection",
      "MessageConnection",
      "MessagePinConnection",
      "NotificationConnection",
      "ReactionConnection",
      "ThreadConnection",
      "UserConnection",
      "UserPreviewConnection",
      "WorkspaceAppConnection",
      "WorkspaceConnection",
      "WorkspaceMemberConnection"
    ],
    "Edge": [
      "AddressEdge",
      "AppEdge",
      "CustomEmojiEdge",
      "DeviceEdge",
      "DomainEdge",
      "DraftEdge",
      "FileEdge",
      "GroupEdge",
      "GroupMemberEdge",
      "GroupPreviewEdge",
      "JoinApprovalEdge",
      "LLMModelEdge",
      "LambdaEdge",
      "LambdaSecretEdge",
      "LinkEdge",
      "MessageEdge",
      "MessagePinEdge",
      "NotificationEdge",
      "ReactionEdge",
      "ThreadEdge",
      "ThreadPreviewEdge",
      "UserEdge",
      "UserPreviewEdge",
      "WorkspaceAppEdge",
      "WorkspaceEdge",
      "WorkspaceMemberEdge",
      "WorkspacePreviewEdge"
    ],
    "GroupOrPreview": [
      "Group",
      "GroupPreview"
    ],
    "IWorkspace": [
      "Workspace",
      "WorkspacePreview"
    ],
    "Joinable": [
      "GroupPreview",
      "ThreadPreview",
      "WorkspacePreview"
    ],
    "Linked": [
      "ExternalObject",
      "File",
      "GroupPreview",
      "MessagePreview",
      "ThreadPreview",
      "User"
    ],
    "Node": [
      "Address",
      "App",
      "CustomEmoji",
      "Device",
      "Domain",
      "DomainEdge",
      "Draft",
      "ExternalObject",
      "File",
      "Group",
      "GroupEdge",
      "GroupMemberEdge",
      "GroupPreview",
      "GroupPreviewEdge",
      "JoinApproval",
      "LLMModel",
      "Lambda",
      "LambdaSecret",
      "Link",
      "Message",
      "MessageEdge",
      "MessageMetadata",
      "MessagePin",
      "MessagePreview",
      "Notification",
      "Reaction",
      "SearchResult",
      "SlackImport",
      "Thread",
      "ThreadEdge",
      "ThreadPreview",
      "ThreadPreviewEdge",
      "ThreadRecipientEdge",
      "ThreadSummaryPoint",
      "User",
      "UserEdge",
      "UserPreviewEdge",
      "Workspace",
      "WorkspaceAppEdge",
      "WorkspaceAppWebhook",
      "WorkspaceEdge",
      "WorkspaceInviteLink",
      "WorkspaceMemberEdge",
      "WorkspacePreview",
      "WorkspacePreviewEdge",
      "WorkspaceSettings",
      "WorkspaceSubscription"
    ],
    "Recipient": [
      "GroupPreview",
      "User",
      "WorkspacePreview"
    ],
    "SearchItem": [
      "Message",
      "ThreadPreview"
    ],
    "UploadTicketResponse": [
      "File",
      "UploadTicket"
    ],
    "WorkspaceOrPreviewEdge": [
      "WorkspaceEdge",
      "WorkspacePreviewEdge"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActivityMeta": [
      "ActivityReactedToMessageMeta"
    ],
    "ActivityObject": [
      "GroupPreview",
      "JoinApproval",
      "Link",
      "Message",
      "ThreadPreview",
      "User"
    ],
    "ActivityTarget": [
      "GroupPreview",
      "ThreadPreview",
      "WorkspacePreview"
    ],
    "Attachment": [
      "ExternalObject",
      "File",
      "ThreadPreview"
    ],
    "Connection": [
      "AddressConnection",
      "AppConnection",
      "CustomEmojiConnection",
      "DeviceConnection",
      "DomainConnection",
      "DraftConnection",
      "FileConnection",
      "GroupConnection",
      "GroupMemberConnection",
      "JoinApprovalConnection",
      "LLMModelConnection",
      "LambdaConnection",
      "LambdaSecretConnection",
      "LinkConnection",
      "MessageConnection",
      "MessagePinConnection",
      "NotificationConnection",
      "ReactionConnection",
      "ThreadConnection",
      "UserConnection",
      "UserPreviewConnection",
      "WorkspaceAppConnection",
      "WorkspaceConnection",
      "WorkspaceMemberConnection"
    ],
    "Edge": [
      "AddressEdge",
      "AppEdge",
      "CustomEmojiEdge",
      "DeviceEdge",
      "DomainEdge",
      "DraftEdge",
      "FileEdge",
      "GroupEdge",
      "GroupMemberEdge",
      "GroupPreviewEdge",
      "JoinApprovalEdge",
      "LLMModelEdge",
      "LambdaEdge",
      "LambdaSecretEdge",
      "LinkEdge",
      "MessageEdge",
      "MessagePinEdge",
      "NotificationEdge",
      "ReactionEdge",
      "ThreadEdge",
      "ThreadPreviewEdge",
      "UserEdge",
      "UserPreviewEdge",
      "WorkspaceAppEdge",
      "WorkspaceEdge",
      "WorkspaceMemberEdge",
      "WorkspacePreviewEdge"
    ],
    "GroupOrPreview": [
      "Group",
      "GroupPreview"
    ],
    "IWorkspace": [
      "Workspace",
      "WorkspacePreview"
    ],
    "Joinable": [
      "GroupPreview",
      "ThreadPreview",
      "WorkspacePreview"
    ],
    "Linked": [
      "ExternalObject",
      "File",
      "GroupPreview",
      "MessagePreview",
      "ThreadPreview",
      "User"
    ],
    "Node": [
      "Address",
      "App",
      "CustomEmoji",
      "Device",
      "Domain",
      "DomainEdge",
      "Draft",
      "ExternalObject",
      "File",
      "Group",
      "GroupEdge",
      "GroupMemberEdge",
      "GroupPreview",
      "GroupPreviewEdge",
      "JoinApproval",
      "LLMModel",
      "Lambda",
      "LambdaSecret",
      "Link",
      "Message",
      "MessageEdge",
      "MessageMetadata",
      "MessagePin",
      "MessagePreview",
      "Notification",
      "Reaction",
      "SearchResult",
      "SlackImport",
      "Thread",
      "ThreadEdge",
      "ThreadPreview",
      "ThreadPreviewEdge",
      "ThreadRecipientEdge",
      "ThreadSummaryPoint",
      "User",
      "UserEdge",
      "UserPreviewEdge",
      "Workspace",
      "WorkspaceAppEdge",
      "WorkspaceAppWebhook",
      "WorkspaceEdge",
      "WorkspaceInviteLink",
      "WorkspaceMemberEdge",
      "WorkspacePreview",
      "WorkspacePreviewEdge",
      "WorkspaceSettings",
      "WorkspaceSubscription"
    ],
    "Recipient": [
      "GroupPreview",
      "User",
      "WorkspacePreview"
    ],
    "SearchItem": [
      "Message",
      "ThreadPreview"
    ],
    "UploadTicketResponse": [
      "File",
      "UploadTicket"
    ],
    "WorkspaceOrPreviewEdge": [
      "WorkspaceEdge",
      "WorkspacePreviewEdge"
    ]
  }
};
      export default result;
    