import { ReactNode, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import Button from "components/design-system/Button/Button";
import { Form, SubmitButton, TextInput } from "components/design-system/Forms";
import { InformationBubble } from "components/design-system/InformationBubble";
import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import { StandardModal } from "./StandardModal";

type Props = {
  headerTitle?: string;
  title: ReactNode;
  subtitle: string;
  confirmationWord?: string;
  informationBubbleText?: string;
  confirmButtonText: string;
  footerAction?: JSX.Element;
  onConfirm: () => Promise<void>;
};

type FormValues = {
  confirmation: string;
};

const FormContent = ({
  headerTitle = "Confirmation required",
  title,
  subtitle,
  informationBubbleText,
  confirmationWord = "DELETE",
  confirmButtonText,
  footerAction,
  modalId,
}: Omit<Props, "onConfirm"> & ModalProps) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const {
    watch,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useFormContext<FormValues>();
  const { confirmation } = watch();
  const submitDisabled = confirmation !== confirmationWord;

  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));

  useEffect(() => {
    if (isSubmitSuccessful) {
      closeModal(modalId);
    }
  }, [closeModal, isSubmitSuccessful, modalId]);

  return (
    <>
      <Header
        variant="bordered"
        textAlign={breakpointMD ? "left" : "center"}
        className={tw("md:px-32 md:!pr-20", {
          "!px-16": !breakpointMD,
        })}
      >
        <h3 className="my-0 text-headline-bold">{headerTitle}</h3>
      </Header>
      <Main
        className={tw("flex flex-col gap-10 px-32 py-20", {
          "grow min-h-300": !breakpointMD,
        })}
      >
        <div className="flex flex-col gap-[1lh]">
          <span className="text-body-bold text-text-primary">{title}</span>
          <span className="text-body text-text-primary">{subtitle}</span>
        </div>
        {informationBubbleText && (
          <InformationBubble iconProps={{ className: "text-icon-alert" }}>
            {informationBubbleText}
          </InformationBubble>
        )}
        <div className="flex flex-col">
          <span className="text-subhead-bold">
            Type "{confirmationWord}" to confirm
          </span>
          <TextInput name="confirmation" wrapperClassName="!mt-4 !mb-0 " />
        </div>
      </Main>
      <Footer
        variant="bordered"
        className="!px-20"
        flexboxClassName={tw("justify-end", {
          "justify-between": !!footerAction,
        })}
      >
        {footerAction}
        <div
          className={tw(
            "flex",
            breakpointMD
              ? "flex-row"
              : "flex-col-reverse gap-16 w-full justify-end items-center"
          )}
        >
          <Button
            buttonStyle="simpleSecondary"
            type="button"
            onClick={() => closeModal(modalId)}
            disabled={isSubmitting}
            className={!breakpointMD ? "w-full justify-center" : ""}
          >
            Cancel
          </Button>
          <SubmitButton
            buttonStyle="primaryDestructive"
            disabled={submitDisabled || isSubmitting || isSubmitSuccessful}
            className={!breakpointMD ? "w-full justify-center" : ""}
          >
            {confirmButtonText}
          </SubmitButton>
        </div>
      </Footer>
    </>
  );
};

/**
 * A Modal that requires the user to type a confirmation word to confirm the action. Intended for use in dangerous and/or irreversible actions.
 */
const ConfirmationRequiredModal = ({
  headerTitle = "Confirmation required",
  title,
  subtitle,
  confirmationWord,
  confirmButtonText,
  footerAction,
  onConfirm,
  informationBubbleText,
  ...props
}: Props &
  Omit<ModalProps, "closeButton" | "closeButtonPosition" | "heightAuto">) => {
  return (
    <StandardModal
      {...props}
      heightAuto
      closeButton
      closeButtonPosition="right"
    >
      <Form<FormValues> onSubmit={onConfirm}>
        <FormContent
          headerTitle={headerTitle}
          title={title}
          subtitle={subtitle}
          confirmationWord={confirmationWord}
          footerAction={footerAction}
          modalId={props.modalId}
          confirmButtonText={confirmButtonText}
          informationBubbleText={informationBubbleText}
        />
      </Form>
    </StandardModal>
  );
};

export default ConfirmationRequiredModal;
