import { Placement } from "@floating-ui/react";
import { ComponentProps, forwardRef, useEffect, useState } from "react";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

import Avatar from "components/design-system/Avatar/Avatar";
import { Icon } from "components/design-system/icons";
import { Skeleton } from "components/Skeleton";
import useElementSize from "hooks/useElementSize";
import useMenuKeyboardShortcuts from "hooks/useMenuKeyboardShortcuts";
import tw from "utils/tw";

import useFloatingElement from "../FloatingUi/useFloatingElement";

import { TextInput } from "./TextInput";
import { Option, SelectProps } from "./types";

type FakeSelectProps = {
  className?: string;
  disabled?: boolean;
  hasError: boolean;
  isOpen: boolean;
  loading?: boolean;
  mode?: "icon-only" | "default";
  placeholder?: string;
  selectedOption?: Option;
};

const OptionIcon = ({
  icon,
  iconSrc,
  name,
}: {
  icon?: ComponentProps<typeof Icon>["icon"];
  iconSrc?: string;
  name?: string;
}) => {
  return iconSrc !== undefined ? (
    <Avatar
      avatarURL={iconSrc}
      name={name}
      rounded="rounded-sm"
      size="x-small"
    />
  ) : icon ? (
    <Icon icon={icon} size={20} />
  ) : null;
};

const FakeSelect = forwardRef<HTMLDivElement, FakeSelectProps>(
  (
    {
      className,
      disabled,
      hasError,
      loading,
      mode = "default",
      isOpen,
      placeholder,
      selectedOption,
    },
    ref
  ) => (
    <div
      tabIndex={0}
      ref={ref}
      className={tw(
        "flex items-center border rounded select-none cursor-pointer h-36",
        mode === "default" ? "gap-8 p-10" : "gap-4 p-8",
        !selectedOption && "text-text-subtle",
        className,
        disabled
          ? "border-border-disabled"
          : hasError
            ? "border-border-alert"
            : isOpen
              ? "border-border-active"
              : "border-border-container"
      )}
    >
      {loading ? (
        <Skeleton className="h-20 min-w-20 w-full" flex />
      ) : (
        <>
          <OptionIcon {...selectedOption} />

          {mode === "default" &&
            (selectedOption ? selectedOption.label : placeholder)}
        </>
      )}
      <Icon
        icon={isOpen ? "DropUp" : "DropDown"}
        size="20"
        className={tw(
          "ml-auto",
          disabled ? "text-text-disabled" : "text-icon-secondary"
        )}
      />
    </div>
  )
);

const SelectDropdown = <TFieldValues extends FieldValues>({
  className,
  disabled,
  error,
  hideErrorMessage,
  loading,
  mode,
  name,
  options,
  placeholder,
  placement = "bottom",
  selectValueByDefault = true,
  wrapperClassName,
}: SelectProps<TFieldValues> & {
  className?: string;
  error?: string;
  loading?: boolean;
  mode?: "icon-only" | "default";
  placement?: Placement;
  selectValueByDefault?: boolean;
  wrapperClassName?: string;
}) => {
  const {
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useFormContext<TFieldValues>();
  const selectedValue = watch(name);
  const selectedOption = options.find(o => o.value === selectedValue);

  // Sets the initial value for the hidden input which contains the valuable data for the API
  useEffect(() => {
    if (selectedValue || !selectValueByDefault) return;
    const firstOption = options[0];
    if (!firstOption) return;
    setValue(
      name,
      firstOption.value as PathValue<TFieldValues, Path<TFieldValues>>,
      { shouldDirty: true }
    );
  }, [name, options, setValue, selectedValue, selectValueByDefault]);

  const { anchorProps, floatingProps, isOpen, setIsOpen } = useFloatingElement({
    strategy: "fixed",
    placement,
    openOnClick: true,
  });

  const [inputRef, { inlineSize: inputWidth }] =
    useElementSize<HTMLDivElement>();

  const floatingStyles =
    "style" in floatingProps && typeof floatingProps.style === "object"
      ? floatingProps.style
      : {};

  const handleSelectOption = (value: string) => {
    setValue(name, value as PathValue<TFieldValues, Path<TFieldValues>>, {
      shouldDirty: true,
    });
    setIsOpen(false);
  };

  const [selectedItemRef, setSelectedItemRef] = useState<HTMLDivElement | null>(
    null
  );
  const { selectedItem, setSelectedIndex } = useMenuKeyboardShortcuts({
    data: options,
    onSelectItem: o => handleSelectOption(o.value),
    selectedItemRef,
    scrollProps: { behavior: "smooth", block: "center", inline: "center" },
    isOpen,
    preventReset: true,
  });

  // Make sure the selected item index is correctly set when opening the dropdown
  useEffect(() => {
    if (!isOpen) return;
    setSelectedIndex(options.findIndex(o => o.value === selectedValue));
  }, [options, selectedValue, setSelectedIndex, isOpen]);

  return (
    <>
      <div
        className={tw(wrapperClassName, {
          "pointer-events-none": disabled || isSubmitting,
        })}
        {...anchorProps}
      >
        <FakeSelect
          ref={inputRef}
          hasError={!!errors[name] || !!error}
          className={className}
          disabled={disabled}
          loading={loading}
          mode={mode}
          selectedOption={selectedOption}
          isOpen={isOpen}
          placeholder={placeholder ?? "Select an item..."}
        />
        <TextInput
          disabled={isSubmitting}
          hideErrorMessage={hideErrorMessage}
          name={name}
          type="hidden"
          wrapperClassName="!my-0"
        />
      </div>

      {isOpen && (
        <div
          {...floatingProps}
          className="bg-background-body border-1 border-border-container overflow-y-auto rounded-md shadow-level2 z-1 -mt-6 max-h-300"
          style={{
            width: mode === "icon-only" ? "auto" : `${inputWidth}px`,
            ...floatingStyles,
          }}
        >
          {options.map(({ value, icon, iconSrc, label }, i) => (
            <div
              ref={e =>
                selectedItem?.value === value ? setSelectedItemRef(e) : null
              }
              key={value}
              // FIXME: role="option" must be used within a `select` element
              // role="option"
              aria-selected={selectedValue === value}
              className={tw(
                "p-8 hover:bg-background-list-hover cursor-pointer flex gap-8",
                { "bg-background-list-hover": selectedItem?.value === value }
              )}
              onClick={() => {
                setSelectedIndex(i);
                handleSelectOption(value);
              }}
            >
              <OptionIcon icon={icon} iconSrc={iconSrc} name={label} />
              <span className="text-subhead">{label}</span>
              {selectedValue === value && (
                <Icon
                  className="text-icon-primary-selected ml-auto"
                  icon="Check"
                  size={20}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SelectDropdown;
