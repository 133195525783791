import { devtools, persist } from "zustand/middleware";

import { Recipient } from "@utility-types";
import {
  MemberRole,
  UserMilestoneTeamSize as TeamSize,
} from "generated/graphql-types";

import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "OnboardingStore";

type User = Pick<Recipient, "avatarURL" | "name">;

export enum ViewOptions {
  CompleteYourProfile = "CompleteYourProfile",
  PlanAndPayment = "PlanAndPayment",
  JoinWorkspace = "JoinWorkspace",
  SelectTeamSize = "SelectTeamSize",
  CreateWorkspace = "CreateWorkspace",
  NoWorkspaceWall = "NoWorkspaceWall",
  JoinGroups = "JoinGroups",
  InviteMembers = "InviteMembers",
  ChooseApps = "ChooseApps",
  CreateGroup = "CreateGroup",
  Review = "Review",
}

export type Views = keyof typeof ViewOptions;

type Workspace = {
  id: string;
  admin: string;
  avatarURL: string | null;
  joinable?: boolean;
  name: string;
  members: number;
  role: MemberRole;
  type: "invited" | "joined" | "created" | "added";
};

type Group = {
  id: string;
  emoji?: string;
  name: string;
  type: "joined" | "created";
};

export const teamSizeOptions = {
  [TeamSize.Seed]: "1-10",
  [TeamSize.Early]: "11-50",
  [TeamSize.Growth]: "51-200",
  [TeamSize.Expansion]: "201-1000",
  [TeamSize.Enterprise]: "1001+",
};

type OnboardingStore = {
  authenticated?: boolean;
  apps?: { id: string; avatarURL: string }[];
  currentStep: number;
  totalSteps: number;
  groups?: Group[];
  hasWorkspacesToJoin?: boolean;
  members?: string[];
  onboarding?: boolean;
  reset: () => void;
  setState: (values: Partial<OnboardingStore>) => void;
  skippedJoinGroups?: boolean;
  teamSize?: keyof typeof teamSizeOptions;
  isEnterprise?: boolean;
  goBackTo?: Views; // Only meant to be used in the Review view for now
  user?: User;
  userSubscribed?: boolean;
  view: Views;
  workspace?: Workspace;
  skipSubscribeForDomain?: string;
  noWorkspaceEmailSent?: boolean;
};

const initialState: Omit<OnboardingStore, "setState" | "reset"> = {
  authenticated: undefined,
  view: "CompleteYourProfile",
  totalSteps: 5,
  currentStep: 1,
  user: undefined,
  hasWorkspacesToJoin: undefined,
  skippedJoinGroups: undefined,
  goBackTo: undefined,
  workspace: undefined,
  groups: undefined,
  apps: undefined,
  members: undefined,
  skipSubscribeForDomain: undefined,
  noWorkspaceEmailSent: undefined,
};

const useOnboardingStore = glueCreateZustand<OnboardingStore>({
  name: STORE_NAME,
})(
  devtools(
    persist(
      set => ({
        setState: values => set(state => ({ ...state, ...values })),
        reset: () => set(() => ({ ...initialState, onboarding: false })),
        ...initialState,
      }),
      { name: STORE_NAME }
    ),
    { name: STORE_NAME, serialize: true }
  )
);

export default useOnboardingStore;
