const CollapsibleDiv = ({
  children,
  collapsed = false,
}: WithChildren<{
  collapsed?: boolean;
}>) => {
  return (
    <div
      className="grid"
      style={{
        gridTemplateRows: collapsed ? "0fr" : "1fr",
        transition: "grid-template-rows 0.2s ease-in-out",
      }}
    >
      <div className="overflow-hidden">{children}</div>
    </div>
  );
};

export default CollapsibleDiv;
