import { scan } from "react-scan"; // must be imported before react
// eslint-disable-next-line import/order
import { useLayoutEffect } from "react";

import useFeatureFlagStore from "store/useFeatureFlagStore";

const useDeveloperTools = () => {
  const { REACT_SCAN } = useFeatureFlagStore(({ flags: { REACT_SCAN } }) => ({
    REACT_SCAN,
  }));

  useLayoutEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      return;
    }

    scan({
      enabled: !!REACT_SCAN,
      showToolbar: !!REACT_SCAN,
      animationSpeed: "slow",
      log: false,
    });
  }, [REACT_SCAN]);
};

export default useDeveloperTools;
