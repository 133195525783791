import { StreamImageAttachment } from "@utility-types";
import { getThumbnailDimensions } from "components/views/groups/Shared/utils/parseImage";
import tw from "utils/tw";

import PhotoSwipeImageWrapper from "./PhotoSwipeImageWrapper";

const GalleryImage = ({
  galleryWidth,
  gridCols,
  gridGap,
  image,
}: {
  galleryWidth: number;
  gridCols: number;
  gridGap: number;
  image: StreamImageAttachment;
}) => {
  const {
    fallback,
    image_blurhash: imageBlurHash,
    image_height: imageHeight = 768,
    image_url: imageURL,
    image_width: imageWidth = 768,
  } = image;

  const gridCellWidth = (galleryWidth - gridGap * (gridCols - 1)) / gridCols;

  const maxThumbnailHeight = gridCellWidth;
  const maxThumbnailWidth = gridCellWidth;
  const ratio = imageWidth / imageHeight;

  const targetHeight = Math.min(maxThumbnailHeight, imageHeight);
  const targetWidth = Math.min(maxThumbnailWidth, imageWidth);

  const { height: thumbnailHeight, width: thumbnailWidth } =
    getThumbnailDimensions({
      imageWidth,
      ratio,
      targetHeight,
      targetWidth,
    });

  const resize = {
    fit: "max" as const,
    h: thumbnailHeight,
    w: thumbnailWidth,
  };

  const objectCover =
    thumbnailHeight >= maxThumbnailHeight &&
    thumbnailWidth >= maxThumbnailWidth;

  return (
    <PhotoSwipeImageWrapper
      alt={fallback}
      blurHash={imageBlurHash}
      className={tw(
        "absolute inset-0 block w-full h-full",
        objectCover ? "object-cover" : "object-contain"
      )}
      figureClassName={tw(
        "border border-border-container cursor-pointer overflow-hidden rounded",
        "before:block before:content-[''] before:pt-[100%]"
      )}
      height={thumbnailHeight}
      image={image}
      resize={resize}
      src={imageURL}
      width={thumbnailWidth}
    />
  );
};

export default GalleryImage;
