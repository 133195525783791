import { format } from "date-fns";
import { noop } from "lodash-es";
import { useHistory } from "react-router-dom";

import { nodeAs } from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Chip } from "components/design-system/Chip";
import { Icon } from "components/design-system/icons";
import FacePile from "components/design-system/ui/FacePile";
import useIdentityBadgeData from "components/design-system/ui/IdentityBadge/hooks/useIdentityBadgeData";
import GroupProfileModal from "components/group/GroupModal/GroupProfileModal";
import {
  currentPathWithoutDrawer,
  routeToGroup,
} from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { WorkspaceModal } from "components/workspace/WorkspaceModal";
import useGroupMembership from "components/workspace-group/hooks/useGroupMembership";
import { JoinableBy, useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import useModalStore from "store/useModalStore";
import { formatGroupName } from "utils/group/formatGroupName";

import { GroupProfileSection } from "./GroupProfileSection";
import WorkspaceItem from "./WorkspaceItem";
import WorkspaceSectionSkeleton from "./WorkspaceSectionSkeleton";

type Props = { groupID: string; pane?: "secondary" };

export default function GroupProfilePane({ groupID, pane }: Props) {
  const { authData, authReady } = useAuthData();
  const history = useHistory();

  const {
    actionPending,
    cancelRequestToJoin,
    joinGroup,
    joinRequested,
    requestToJoin,
    loading,
  } = useGroupMembership(groupID);

  const { data: groupData } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id,
    variables: {
      id: `${groupID}-${authData?.me.id}`,
    },
  });

  const edge = nodeAs(groupData?.node, [
    "GroupEdge",
    "GroupPreviewEdge",
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ]);
  const { memberEdge } = useMemberEdge(edge);
  const node = edge?.node;

  const group = nodeAs(edge, ["GroupEdge", "GroupPreviewEdge"])?.node;
  const isGroupPreview = group?.__typename === "GroupPreview";
  const workspace = nodeAs(edge, [
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ])?.node;

  const { type, tooltip } = useIdentityBadgeData(node);
  const externalTooltip = type !== "internal" ? tooltip : undefined;
  const { name: groupName, emoji } = formatGroupName(node);
  const description = group?.description;
  const isClosed = group?.joinableBy !== JoinableBy.Workspace;
  const totalCount = node?.members.totalCount || 0;
  const users = node?.members.edges.map(e => e.node) || [];

  const archivedAt = group?.archivedAt
    ? format(new Date(group.archivedAt), "M/d/y")
    : undefined;
  const archivedBy = group?.archivedBy?.name;
  const archivedByLabel =
    archivedBy && archivedAt
      ? archivedBy
        ? `Archived by ${archivedBy} on ${archivedAt}`
        : `Archived on ${archivedAt}`
      : undefined;

  const {
    buttonAction,
    buttonText,
    buttonStyle,
  }: {
    buttonAction: () => void;
    buttonStyle?: "secondary" | "primary";
    buttonText: JSX.Element | string;
  } = (() => {
    if (!edge || memberEdge) {
      return {
        buttonAction: () =>
          history.push(routeToGroup({ groupID, to: "canonical" })),
        buttonText: "View",
        buttonStyle: "primary",
      };
    }

    if (joinRequested) {
      return {
        buttonAction: cancelRequestToJoin,
        buttonText: "Undo request",
        buttonStyle: "secondary",
      };
    }

    if (joinGroup) {
      return {
        buttonAction: joinGroup,
        buttonText: "Join",
        buttonStyle: "primary",
      };
    }

    return {
      buttonAction: requestToJoin,
      buttonText: "Request",
      buttonStyle: "primary",
    };
  })();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const openGroupSettingsModal =
    group?.__typename === "Group"
      ? () => {
          openModal(<GroupProfileModal groupID={group.id} />);
        }
      : workspace?.__typename === "Workspace"
        ? () => {
            openModal(<WorkspaceModal workspaceID={workspace.id} />);
          }
        : noop;

  const isLoading = !node || loading;
  const workspaceID = group?.workspaceID ?? workspace?.id;

  return (
    <div className="flex flex-col gap-24 h-full w-full">
      <header className="flex flex-col items-center p-24 pt-32 relative w-full border-b-1 border-border-container">
        {pane === "secondary" ? (
          <Button
            buttonStyle="subtle"
            buttonType="text"
            className="absolute left-16 top-16 p-10 -ml-6 mr-8"
            icon="Close"
            iconSize={20}
            onClick={() => history.push(currentPathWithoutDrawer())}
          />
        ) : null}
        <BackStackButton position="profile" />

        <Avatar
          avatarURL={workspace?.avatarURL ?? undefined}
          background="transparent"
          emojiProps={{ emoji: emoji }}
          loading={isLoading}
          name={groupName}
          rounded="rounded-lg"
          size="xx-large"
        />
        <div className="flex items-center mt-10 text-title-1 text-text-primary">
          {!loading ? groupName : <Skeleton width="180px" height="32px" />}
        </div>

        <div className="flex flex-row gap-8 mt-8 items-center">
          <button onClick={openGroupSettingsModal} className="hidden md:flex">
            <FacePile
              hasExternal={!!externalTooltip}
              tooltip={externalTooltip}
              totalCount={totalCount}
              users={isLoading ? undefined : users.slice(0, 3)}
            />
          </button>
          {isClosed && <Chip icon="LockFilled" label="Closed" />}
          {archivedByLabel && (
            <Chip
              icon="ArchiveFilled"
              label={"Archived"}
              tooltip={archivedByLabel}
              tooltipStyle="inverted"
              tooltipPlacement="top"
            />
          )}
        </div>
      </header>

      <main className="flex flex-col gap-24 grow overflow-auto px-24">
        {description && (
          <GroupProfileSection
            title="Description"
            loading={isLoading}
            titleSkeletonWidth="90px"
          >
            <div className="select-text text-body text-text-secondary truncate">
              {isLoading ? <Skeleton width={"80px"} /> : description}
            </div>
          </GroupProfileSection>
        )}

        {workspaceID ? (
          <GroupProfileSection title="Workspaces" loading={isLoading}>
            <div className="flex flex-col gap-8 mt-8">
              {isLoading ? (
                <WorkspaceSectionSkeleton />
              ) : (
                <WorkspaceItem workspaceID={workspaceID} />
              )}
            </div>
          </GroupProfileSection>
        ) : null}

        {archivedByLabel ? (
          <GroupProfileSection
            title="Archived group"
            loading={isLoading}
            titleSkeletonWidth="120px"
          >
            <div className="text-body text-text-secondary">
              {isLoading ? <Skeleton width={"80px"} /> : archivedByLabel}
            </div>
          </GroupProfileSection>
        ) : null}

        <Button
          buttonStyle={buttonStyle}
          className="justify-center w-full"
          disabled={loading}
          onClick={buttonAction}
        >
          {actionPending ? (
            <Icon
              className="animate-spin opacity-100 transition-opacity"
              icon="LoaderCircle"
              size={20}
            />
          ) : (
            buttonText
          )}
        </Button>

        {!isGroupPreview ? (
          <Button
            buttonStyle="secondary"
            className="justify-center w-full"
            disabled={loading}
            onClick={openGroupSettingsModal}
          >
            Group settings
          </Button>
        ) : null}
      </main>
    </div>
  );
}
