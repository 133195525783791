import { ComponentProps, forwardRef, memo } from "react";

import tw from "utils/tw";

import { Tooltip } from "../FloatingUi";
import { Icon } from "../icons";

import styles from "./Chip.module.css";

type ChipProps = {
  label: string;
  icon?: ComponentProps<typeof Icon>["icon"];
  iconSize?: ComponentProps<typeof Icon>["size"];
  tooltip?: ComponentProps<typeof Tooltip>["content"];
  tooltipStyle?: ComponentProps<typeof Tooltip>["tooltipStyle"];
  tooltipPlacement?: Pick<
    ComponentProps<typeof Tooltip>,
    "placement"
  >["placement"];
};

export const Chip = memo(
  forwardRef<HTMLDivElement, ChipProps>(
    (
      { icon, iconSize = 16, label, tooltip, tooltipPlacement, tooltipStyle },
      ref
    ) => {
      return (
        <Tooltip
          content={tooltip}
          placement={tooltipPlacement}
          className={styles.tooltip}
          tooltipStyle={tooltipStyle}
        >
          <div
            ref={ref}
            className={tw(styles.chip, tooltip && styles["with-tooltip"])}
          >
            {icon && (
              <Icon icon={icon} size={iconSize} className={styles.icon} />
            )}
            <span className={styles.label}>{label}</span>
          </div>
        </Tooltip>
      );
    }
  )
);
