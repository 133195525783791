import { forwardRef } from "react";

import { WorkspaceEdge } from "@utility-types";
import { ModalProps } from "components/ModalKit/Modal";
import {
  FetchWorkspaceOrPreviewEdgeDocument,
  WorkspacesAndGroupsListDocument,
  useUpdateWorkspaceMutation,
} from "generated/graphql-operations";

import WorkspaceModalForm, {
  FormRef,
  WorkspaceInput,
} from "./WorkspaceModalForm";

type Props = {
  loading?: boolean;
  workspaceEdge?: WorkspaceEdge;
} & ModalProps;

const WorkspaceSettings = forwardRef<FormRef, Props>(
  ({ loading, workspaceEdge, ...props }: Props, ref): JSX.Element => {
    const [updateWorkspace] = useUpdateWorkspaceMutation({
      errorPolicy: "all",
    });

    const onSave = (workspaceID: string, input: WorkspaceInput) => {
      if (!workspaceEdge) return Promise.resolve();

      return updateWorkspace({
        refetchQueries: [
          WorkspacesAndGroupsListDocument,
          FetchWorkspaceOrPreviewEdgeDocument,
        ],
        variables: { id: workspaceID, input },
      });
    };

    return (
      <WorkspaceModalForm
        ref={ref}
        workspaceEdge={workspaceEdge}
        loading={loading}
        onSave={onSave}
        editMode
        {...props}
      />
    );
  }
);

export default WorkspaceSettings;
