import { Placement } from "@floating-ui/react";
import { capitalize } from "lodash-es";
import { useEffect, useMemo } from "react";
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";

import usePrevious from "hooks/usePrevious";
import tw from "utils/tw";

import useFloatingList from "../FloatingUi/useFloatingList";
import { Icon, IconName } from "../icons";

export type Option = {
  icon: IconName;
  name: string;
  displayName?: string;
  description: string;
  value: string;
};

type Props<T extends FieldValues, TName extends Path<T> = Path<T>> = {
  name: TName;
  icon: IconName;
  placeholder?: string;
  placement?: Placement;
  options: Option[];
  onDropdownClose?: (values: Record<Option["value"], boolean>) => void;
  variant?: "default" | "compact";
};

const MultiOptionDropdown = <T extends FieldValues>({
  name,
  icon,
  placeholder = "Select options",
  placement = "bottom-start",
  options,
  onDropdownClose,
  variant = "default",
}: Props<T>) => {
  const { control } = useFormContext<T>();
  const {
    field: { onChange, value },
  } = useController<T>({ name, control });
  const selectedValues: Record<Option["value"], boolean> = useMemo(
    () => value ?? {},
    [value]
  );
  const noOptionsSelected = Object.values(selectedValues).every(
    value => !value
  );

  const {
    anchorProps,
    floatingProps,
    isOpen,
    activeIndex,
    listRef,
    getItemProps,
  } = useFloatingList({
    placement,
    strategy: "fixed",
  });

  const prevIsOpen = usePrevious(isOpen);
  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      onDropdownClose?.(selectedValues);
    }
  }, [isOpen, onDropdownClose, prevIsOpen, selectedValues]);

  const handleOptionSelect = (value: Option["value"]) => {
    onChange({ ...selectedValues, [value]: !selectedValues[value] });
  };

  const formatInputText = () => {
    if (noOptionsSelected) return placeholder;

    let formattedValues = "";
    Object.entries(selectedValues)
      .filter(([_, value]) => value)
      .forEach(([key], index) => {
        const option = options.find(option => option.value === key);
        const displayValue = option?.displayName ?? option?.name;
        formattedValues +=
          index === 0
            ? capitalize(displayValue)
            : ` & ${displayValue?.toLowerCase()}`;
      });
    return formattedValues;
  };

  return (
    <>
      <div
        className={tw(
          "flex items-center gap-4 p-4 border border-border-container rounded-md w-min",
          "hover:cursor-pointer hover:border-border-container-hover hover:bg-background-body-hover",
          "focus-visible-shadow",
          "group/multi-option-dropdown"
        )}
        tabIndex={0}
        {...anchorProps}
      >
        {noOptionsSelected ? (
          <Icon
            className="text-icon-subtle group-hover/multi-option-dropdown:text-icon-subtle-hover"
            icon={icon}
            size={16}
          />
        ) : (
          Object.entries(selectedValues)
            .filter(([_, value]) => value)
            .map(([value]) => {
              const icon = options.find(option => option.value === value)?.icon;
              return (
                icon !== undefined && (
                  <Icon
                    key={value}
                    className="text-icon-secondary group-hover/multi-option-dropdown:text-icon-secondary-hover"
                    icon={icon}
                    size={16}
                  />
                )
              );
            })
        )}
        {variant === "default" && (
          <span
            className={tw(
              "text-footnote text-nowrap group-hover/multi-option-dropdown:text-text-secondary",
              noOptionsSelected ? "text-text-subtle" : "!text-text-secondary"
            )}
          >
            {formatInputText()}
          </span>
        )}
        <div className="border-l border-border-container pl-4">
          <Icon
            className={tw(
              "text-icon-secondary group-hover/multi-option-dropdown:text-icon-secondary-hover duration-350 ease-spring",
              isOpen && "-rotate-180"
            )}
            icon={"ChevronDown"}
            size={16}
          />
        </div>
      </div>

      {isOpen && (
        <div
          {...floatingProps}
          aria-multiselectable="true"
          className="bg-background-body border-1 border-border-container overflow-y-auto overflow-x-hidden rounded-lg mt-2 shadow-level2 z-1 max-h-300 py-8 outline-none"
        >
          {options.map(({ name, icon, description, value }, index) => (
            <div
              key={name}
              className="flex items-center px-16 py-8 hover:bg-background-body-hover hover:cursor-pointer focus-visible:bg-background-body-hover outline-none"
              tabIndex={activeIndex === index ? 0 : -1}
              ref={node => {
                listRef.current[index] = node;
              }}
              aria-selected={selectedValues[value]}
              {...getItemProps({
                role: "option",
                onClick: () => handleOptionSelect(value),
                onKeyDown: e => {
                  if (e.key === "Enter") {
                    handleOptionSelect(value);
                  }
                },
              })}
            >
              <Icon icon={icon} className="text-icon-secondary" size={20} />
              <div className="flex flex-col ml-12 overflow-hidden">
                <span className="text-subhead truncate">{name}</span>
                <span className="text-footnote text-text-secondary truncate">
                  {description}
                </span>
              </div>
              <div className="grow" />
              <Icon
                icon="Check"
                className={tw("text-icon-primary-selected ml-12", {
                  invisible: !selectedValues[value],
                })}
                size={20}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MultiOptionDropdown;
