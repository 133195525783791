import { useApolloClient } from "@apollo/client";
import { ComponentProps, useEffect, useState } from "react";

import { JoinApproval } from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { JoinRequestCard } from "components/design-system/ui/JoinRequest/JoinRequestCard";
import {
  FetchJoinApprovalsDocument,
  FetchWorkspaceOrPreviewEdgeDocument,
  NotificationsDocument,
  WorkspacesAndGroupsListDocument,
  useApproveJoinApprovalMutation,
  useFetchJoinApprovalsQuery,
  useIgnoreJoinApprovalMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import filterActiveQueries from "utils/filterActiveQueries";

type Requests = ComponentProps<typeof JoinRequestCard>["node"];

const InboxJoinRequests = () => {
  const apolloClient = useApolloClient();
  const { fetchAuthData, authReady } = useAuthData();
  const [requests, setRequests] = useState<Requests[]>([]);
  const today = new Date();

  const { data } = useFetchJoinApprovalsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });

  useEffect(() => {
    if (!data?.joinApprovals.edges.length) return;
    const diff: JoinApproval[] = data.joinApprovals.edges
      .filter(e => !requests?.find(r => r.id === e.node.id))
      .map(e => e.node);
    if (diff.length) setRequests([...requests, ...diff]);
  }, [requests, data?.joinApprovals.edges]);

  const mutationOptions = {
    awaitRefetchQueries: true,
    errorPolicy: "all" as const,
    refetchQueries: filterActiveQueries(apolloClient, [
      FetchJoinApprovalsDocument,
      NotificationsDocument,
      FetchWorkspaceOrPreviewEdgeDocument,
      WorkspacesAndGroupsListDocument,
    ]),
  };

  const [approveJoin] = useApproveJoinApprovalMutation(mutationOptions);

  const [ignoreJoin] = useIgnoreJoinApprovalMutation(mutationOptions);

  const handleAction = (action: "approve" | "ignore", id: string) => {
    const requestsCopy = requests;
    const requestsUpdated = requestsCopy.map(request => {
      if (request.id === id) {
        const actionAt =
          action === "approve" ? { approvedAt: today } : { declinedAt: today };
        return { ...request, ...actionAt };
      }
      return request;
    });
    (action === "approve"
      ? approveJoin({ variables: { joinApprovalID: id } })
      : ignoreJoin({ variables: { joinApprovalID: id } })
    ).then(() => {
      setRequests(requestsUpdated);
      fetchAuthData({ refresh: true });
    });
  };

  return (
    <div className="flex flex-col justify-center px-20 w-full h-full bg-background-list">
      <BackStackButton position="profile" />

      <div className="overflow-y-auto">
        {requests.map(node => (
          <JoinRequestCard
            key={node.id}
            node={node}
            handleAction={handleAction}
          />
        ))}
      </div>
    </div>
  );
};

export default InboxJoinRequests;
