import { InformationBubble } from "components/design-system/InformationBubble";

import { BannerActionButton } from "./BannerActionButton";

type Props =
  | {
      isPersistentChat: boolean;
      isThreadStarter?: never;
      onClickCTA: () => void;
      variant: "thread";
    }
  | {
      isPersistentChat: boolean;
      isThreadStarter: boolean;
      onClickCTA: () => void;
      variant: "editor";
    };

const NotMessageableBanner = ({
  isPersistentChat,
  isThreadStarter = false,
  variant = "thread",
  onClickCTA,
}: Props) => {
  const repliesAreOffContent = (
    <>
      {isPersistentChat ? "Chat" : "Thread"} replies are{" "}
      <span className="text-footnote-bold">off</span>.{" "}
    </>
  );

  return (
    <div
      className={
        variant === "thread"
          ? "px-16 pb-16"
          : "flex bg-background-secondary pt-8 pb-16 px-16 md:px-20 -mb-10 rounded-t-xl md:rounded-t-lg text-footnote"
      }
    >
      {variant === "thread" ? (
        <InformationBubble className="!rounded-lg" iconProps={{ size: 16 }}>
          <div className="flex items-center justify-between gap-4">
            <span>
              {repliesAreOffContent}
              {isPersistentChat
                ? "Only admins can reply."
                : "Only the thread starter and admins can reply."}
            </span>
            <BannerActionButton label="Learn more" onClick={onClickCTA} />
          </div>
        </InformationBubble>
      ) : (
        <div className="flex items-center gap-4">
          <span>
            {repliesAreOffContent}
            You can reply because you're{" "}
            {isThreadStarter ? "the thread starter" : "an admin"}.
          </span>
          <BannerActionButton label="Update settings" onClick={onClickCTA} />
        </div>
      )}
    </div>
  );
};

export default NotMessageableBanner;
