import { Form, InputButton } from "components/design-system/Forms";
import { Header } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { ConfirmationAlert, StandardModal } from "components/Modals";
import {
  LambdaSecretFieldsFragment,
  LambdaSecretsDocument,
  useDeleteSecretMutation,
  useLambdaSecretsQuery,
} from "generated/graphql";
import useModalStore from "store/useModalStore";

import EditSecretModal from "./EditSecretModal";

type Props = ModalProps & {
  lambdaId: string;
};

const ManageSecretsModal = ({ lambdaId, ...props }: Props) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const { data } = useLambdaSecretsQuery({
    variables: {
      lambdaId,
    },
  });

  const [deleteSecret] = useDeleteSecretMutation();

  const handleEditSecret = (secret: LambdaSecretFieldsFragment) => {
    openModal(<EditSecretModal lambdaId={lambdaId} secret={secret} />);
  };

  const handleCreateSecret = () => {
    openModal(<EditSecretModal lambdaId={lambdaId} />);
  };

  const handleDelete = (secret: LambdaSecretFieldsFragment) => {
    openModal(
      <ConfirmationAlert
        confirmLabel="Delete"
        header={`Delete the "${secret.name}" secret?`}
        onConfirm={() =>
          deleteSecret({
            refetchQueries: [LambdaSecretsDocument],
            variables: {
              secretID: secret.id,
            },
          })
        }
        isDestructive
      />
    );
  };

  const secrets = data?.lambdaSecrets.edges.map(edge => edge.node) || [];

  return (
    <StandardModal {...props}>
      <Header variant="bordered">
        <h3 className="m-0">Manage Secrets</h3>
      </Header>
      {/* TODO: create secret button */}
      <Form>
        <div className="flex justify-end p-8 mt-16">
          <InputButton
            buttonStyle="none"
            buttonType="text"
            icon="PlusCircle"
            onClick={() => {
              handleCreateSecret();
            }}
          >
            Create Secret
          </InputButton>
        </div>
        {secrets.map(secret => (
          <div
            key={secret.id}
            className="flex items-center justify-between border rounded-md p-8 m-8"
          >
            <div className="flex items-center">{secret.name}</div>
            <div className="flex items-center">
              <InputButton
                buttonStyle="none"
                buttonType="text"
                icon="Compose"
                onClick={() => {
                  handleEditSecret(secret);
                }}
                tooltip="Edit Secret"
              />
              &nbsp;
              <InputButton
                buttonStyle="simpleDestructive"
                buttonType="text"
                icon="Trash"
                onClick={() => {
                  handleDelete(secret);
                }}
                tooltip="Delete Secret"
              />
            </div>
          </div>
        ))}
      </Form>
    </StandardModal>
  );
};

export default ManageSecretsModal;
