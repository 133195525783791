import { useCallback, useRef, useState } from "react";

import { nodeAs } from "@utility-types";
import { Button } from "components/design-system/Button";
import { useExternalFormRef } from "components/design-system/Forms/hooks";
import NotAMemberIcons from "components/design-system/NotAMemberIcons";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer } from "components/ModalKit/Parts";
import { Header, Main } from "components/ModalKit/Parts";
import { ProfileItemHeader, StandardModal } from "components/Modals";
import { Tabs } from "components/Tabs";
import { useFetchWorkspaceOrPreviewEdgeQuery } from "generated/graphql-operations";
import { MemberRole } from "generated/graphql-types";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import useAppStateStore from "store/useAppStateStore";
import { isNative } from "utils/platform";

import MembersSearch from "../../workspace-group/MembersSearch";

import WorkspaceAdvanced from "./WorkspaceAdvanced";
import WorkspaceApps from "./WorkspaceApps";
import WorkspaceBilling from "./WorkspaceBilling";
import WorkspaceGroups from "./WorkspaceGroups";
import WorkspaceMembers from "./WorkspaceMembers";
import type { FormRef } from "./WorkspaceModalForm";
import WorkspaceSettings from "./WorkspaceSettings";

export enum WorkspaceModalTabs { // in order of UI appearance
  Groups = "groups",
  Members = "members",
  Apps = "apps",
  Settings = "settings",
  Billing = "billing",
  Advanced = "advanced",
}

type Props = {
  defaultTab?: WorkspaceModalTabs;
  workspaceID?: string;
} & ModalProps;

const WorkspaceProfileModal = ({
  defaultTab,
  workspaceID,
  ...props
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [searchLoading, setSearchLoading] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authData, authReady } = useAuthData();

  const { data, error, fetchMore, loading, refetch } =
    useFetchWorkspaceOrPreviewEdgeQuery({
      fetchPolicy: authReady ? "cache-and-network" : "cache-only",
      nextFetchPolicy: "cache-first",
      skip: !(workspaceID && authData),
      variables: {
        id: `${workspaceID}-${authData?.me.id}`,
      },
    });

  const { memberEdge, previewEdge } = useMemberEdge(
    nodeAs(data?.node, ["WorkspaceEdge", "WorkspacePreviewEdge"])
  );

  const workspaceEdge = previewEdge || memberEdge;

  const isAdmin = memberEdge?.memberRole === MemberRole.Admin;

  const tabs = [
    {
      tab: WorkspaceModalTabs.Groups,
    },
    {
      tab: WorkspaceModalTabs.Members,
    },
  ];

  if (breakpointMD) {
    tabs.push({
      tab: WorkspaceModalTabs.Apps,
    });
  }

  if (isAdmin) {
    const adminTabs = [
      { tab: WorkspaceModalTabs.Settings },
      { tab: WorkspaceModalTabs.Advanced },
    ];

    if (breakpointMD && !isNative()) {
      adminTabs.splice(1, 0, { tab: WorkspaceModalTabs.Billing });
    }

    tabs.push(...adminTabs);
  }

  const [selectedTab, setSelectedTab] = useState<WorkspaceModalTabs>(
    tabs.find(t => t.tab === defaultTab)?.tab ?? WorkspaceModalTabs.Groups
  );

  const {
    ref: formRef,
    setRef: setFormRef,
    disabled: submitDisabled,
  } = useExternalFormRef<FormRef>();

  const onMemberSearch = useCallback(
    (search?: string) => {
      setSearchQuery(search);
      if (search === undefined) return;
      !!search.length && setSearchLoading(true);
      refetch({ membersFilter: { match: search } }).finally(() => {
        setSearchLoading(false);
      });
    },
    [refetch]
  );

  return (
    <StandardModal
      header={
        <Header
          className="!justify-start md:h-48 md:min-h-0 md:pt-16"
          mobileCtaLabel={
            selectedTab === WorkspaceModalTabs.Settings ? "Save" : undefined
          }
          mobileCtaProps={{
            disabled: submitDisabled,
            onClick: () => {
              formRef.current?.submitForm();
            },
          }}
          variant="borderless"
        >
          <ProfileItemHeader target={{ workspace: workspaceEdge?.node }} />
        </Header>
      }
      mdHeightAuto={false}
      {...props}
    >
      <Main ref={scrollContainerRef} className="relative">
        <div className="bg-background-body sticky top-0 z-1">
          <Tabs<WorkspaceModalTabs>
            className="px-16 md:px-32 w-full border-b-1 border-border-container"
            onClickTab={setSelectedTab}
            selectedTab={selectedTab}
            tabs={tabs}
          />
        </div>

        <div className="px-16 md:px-32">
          {previewEdge ? (
            <div className="join-group" data-testid="JoinGroup">
              <NotAMemberIcons background="bg-background-body" />
              <span className="text-title">
                You're not a member of this workspace.
              </span>
            </div>
          ) : (
            <>
              {selectedTab === WorkspaceModalTabs.Groups && memberEdge && (
                <WorkspaceGroups
                  scrollContainerRef={scrollContainerRef}
                  fetchMore={fetchMore}
                  hasError={!!error}
                  workspace={memberEdge.node}
                  loading={loading}
                />
              )}

              {selectedTab === WorkspaceModalTabs.Members && memberEdge && (
                <>
                  <MembersSearch onMemberSearch={onMemberSearch} />
                  <WorkspaceMembers
                    scrollContainerRef={scrollContainerRef}
                    fetchMore={fetchMore}
                    hasError={!!error}
                    isAdmin={isAdmin}
                    modalId={props.modalId}
                    workspace={memberEdge.node}
                    searchLoading={searchLoading}
                    searchQuery={searchQuery}
                    loading={loading}
                  />
                </>
              )}

              {selectedTab === WorkspaceModalTabs.Apps && (
                <WorkspaceApps workspaceEdge={memberEdge} />
              )}

              {selectedTab === WorkspaceModalTabs.Settings && workspaceID && (
                <WorkspaceSettings
                  ref={setFormRef}
                  loading={loading}
                  workspaceEdge={memberEdge}
                  {...props}
                />
              )}

              {selectedTab === WorkspaceModalTabs.Billing && workspaceID && (
                <WorkspaceBilling workspaceID={workspaceID} />
              )}

              {selectedTab === WorkspaceModalTabs.Advanced && memberEdge && (
                <WorkspaceAdvanced workspace={memberEdge.node} />
              )}
            </>
          )}
        </div>
      </Main>
      {selectedTab === WorkspaceModalTabs.Settings && workspaceID ? (
        <Footer>
          <Button
            icon="Check"
            disabled={submitDisabled}
            onClick={formRef.current?.submitForm}
          >
            Save Workspace
          </Button>
        </Footer>
      ) : null}
    </StandardModal>
  );
};

export default WorkspaceProfileModal;
