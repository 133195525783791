import { useState } from "react";

import tw from "utils/tw";

import { Dropdown } from "../FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "../FloatingUi/DropdownActions";
import { Icon } from "../icons";

import styles from "./SelectDropdown.module.css";

type DropdownInputProps = {
  options: ActionSheetItemGroups[];
  text: string;
};

export const SelectDropdown = ({ options, text }: DropdownInputProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Dropdown
      content={<DropdownActions actions={options} />}
      placement="bottom-start"
      offsetMain={4}
      open={isDropdownOpen}
      setOpen={setIsDropdownOpen}
    >
      <button
        type="button"
        className={tw(styles.button, isDropdownOpen ? styles.open : "")}
      >
        {text}
        <Icon
          icon={isDropdownOpen ? "DropUp" : "DropDown"}
          size="20"
          className={styles.icon}
        />
      </button>
    </Dropdown>
  );
};
