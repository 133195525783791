import { memo, useMemo } from "react";
import { Link } from "react-router-dom";

import Avatar from "components/design-system/Avatar/Avatar";
import Tooltip from "components/design-system/FloatingUi/Tooltip";
import { routeToGroup, routeToUser } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import useElementSize from "hooks/useElementSize";
import useElementTextWidth from "hooks/useElementTextWidth";
import getRandomInt from "utils/getRandomInt";
import formatRecipientNames, {
  RecipientEnvelope,
} from "utils/thread/formatRecipientNames";

const AVATAR_W = 20;
const MAX_W = 200;
const SEPARATOR = ",\u00A0\u00A0";

const RecipientsLine = ({
  maxCount = 3,
  me,
  recipients,
  excludeID,
}: RecipientEnvelope & {
  maxCount?: number;
}) => {
  const [parentRef, { inlineSize: parentWidthRaw }] =
    useElementSize<HTMLDivElement>();
  const parentWidth = Math.floor(parentWidthRaw);

  const { measureText } = useElementTextWidth(parentRef);

  const recipientsNames = useMemo(
    () => formatRecipientNames({ me, recipients, excludeID }),
    [me, recipients, excludeID]
  );

  const trimmedRecipients = useMemo(() => {
    const commaWidth = measureText(SEPARATOR);

    const measureWidth = (r: { name: string }) =>
      Math.min(AVATAR_W + measureText(r.name), MAX_W) + commaWidth;

    const displayRecipients: typeof recipientsNames = [];
    const initialRecipients = recipientsNames.slice(0, maxCount);

    let totalWidth = 0;
    initialRecipients.some(r => {
      totalWidth += measureWidth(r);

      const extra = recipientsNames.length - displayRecipients.length - 1;
      const extraWidth = extra ? measureText(`+${extra}`) : 0;
      if (totalWidth + extraWidth > parentWidth) {
        return true;
      }

      displayRecipients.push(r);
    });

    if (displayRecipients.length === 0 && recipientsNames[0]) {
      displayRecipients.push(recipientsNames[0]);
    }

    return displayRecipients;
  }, [maxCount, measureText, parentWidth, recipientsNames]);

  const remainingCount = recipientsNames.length - trimmedRecipients.length;

  return (
    <div
      ref={parentRef}
      className="flex text-footnote flex-1 whitespace-nowrap"
      data-testid="recipients-line"
    >
      {recipients ? (
        <>
          {trimmedRecipients.map((r, i) => {
            const isLast = i === trimmedRecipients.length - 1;
            return (
              <Tooltip
                key={r.id}
                content={!r.isGroup ? "View profile" : "Go to group"}
                placement="bottom"
                tooltipStyle="inverted"
              >
                <Link
                  className="group/recipient flex items-center min-w-0 select-none"
                  onClick={e => e.stopPropagation()}
                  style={{ maxWidth: MAX_W }}
                  to={
                    r.isGroup
                      ? routeToGroup({ groupID: r.id })
                      : routeToUser({ userID: r.id, to: "canonical" })
                  }
                >
                  <Avatar
                    avatarURL={r.avatarURL}
                    background={r.isGroup ? "transparent" : "subtle"}
                    emojiProps={{ emoji: r.emoji }}
                    margin="mr-4"
                    name={r.name}
                    rounded="rounded-sm"
                    size="tiny"
                  />
                  <span className="truncate text-text-secondary group-hover/recipient:text-text-secondary-hover group-hover/recipient:underline">
                    {r.name}
                  </span>
                  {!isLast || remainingCount > 0 ? SEPARATOR : null}
                </Link>
              </Tooltip>
            );
          })}
          {remainingCount > 0 && <span>+{remainingCount}</span>}
        </>
      ) : (
        <Skeleton height="100%" width={`${getRandomInt(200, 300)}px`} />
      )}
    </div>
  );
};

export default memo(RecipientsLine);
