import { useCallback, useRef, useState } from "react";

type FormRefState<T> = {
  ref: React.MutableRefObject<T | null>;
  setRef: (node: T | null) => void;
  disabled: boolean;
};

const useExternalFormRef = <
  T extends { submitDisabled: boolean; submitForm: () => void },
>(): FormRefState<T> => {
  const ref = useRef<T | null>(null);
  const [disabled, setDisabled] = useState(false);

  const setRef = useCallback(
    (node: T | null) => {
      ref.current = node;
      if (node && disabled !== node.submitDisabled) {
        setDisabled(node.submitDisabled);
      }
    },
    [disabled]
  );

  return { ref, setRef, disabled };
};

export default useExternalFormRef;
