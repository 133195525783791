import { Button } from "components/design-system/Button";
import useModalStore from "store/useModalStore";
import useOnboardingStore from "store/useOnboardingStore";

const SimulateOnboarding = ({ modalID }: { modalID?: string }) => {
  const { setState } = useOnboardingStore(({ setState }) => ({ setState }));
  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));
  return (
    <div>
      <Button
        onClick={() => {
          setState({ onboarding: true });
          closeModal(modalID);
        }}
      >
        Simulate Onboarding
      </Button>
    </div>
  );
};

export default SimulateOnboarding;
