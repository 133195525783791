import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Address } from "@utility-types";
import EditableAvatar from "components/Avatar/EditableAvatar";
import { CollapsibleSection } from "components/design-system/CollapsibleSection";
import {
  Checkbox,
  Form,
  SubmitButton,
  useDisableSubmit,
} from "components/design-system/Forms";
import { TextInput } from "components/design-system/Forms";
import ComboBox from "components/design-system/Forms/ComboBox";
import Hr from "components/design-system/Hr";
import { Icon } from "components/design-system/icons";
import WorkspaceItem from "components/design-system/ui/ProfilePane/WorkspaceItem";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import {
  UserProfileDataQuery,
  useUserProfileDataQuery,
  useUpdateProfileMutation,
  useUpdateSettingsMutation,
  UserSettingsDocument,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useTimezoneList from "hooks/useTimezoneList";
import useModalStore from "store/useModalStore";
import { formatTimezoneList, getTimezoneAutomatically } from "utils/timezone";

import DeleteAccountModal from "./DeleteAccountModal";
import { FormData } from "./types";
import {
  convertTimeStringToMinutes,
  convertMinutesToTimeString,
  validateWorkSchedule,
} from "./utils";
import WorkSchedule from "./WorkSchedule";

const getWorkScheduleDayPayload = (
  enabled: boolean,
  start?: string,
  end?: string
): { start: number; end: number } | null => {
  if (!enabled || !start || !end) return null;
  return {
    start: convertTimeStringToMinutes(start),
    end: convertTimeStringToMinutes(end),
  };
};

/**
 * This component is used to validate the work schedule after the form has been submitted;
 * allows for real-time dismissal of schedule errors as the user makes corrections.
 */
const WorkScheduleOverseer = ({
  attemptedSubmit,
  setWorkScheduleErrors,
}: {
  attemptedSubmit: boolean;
  setWorkScheduleErrors: Dispatch<SetStateAction<string[]>>;
}) => {
  const {
    formState: { isSubmitSuccessful },
  } = useFormContext();
  const formValues = useWatch<FormData>();

  useEffect(() => {
    if (!attemptedSubmit) return;

    const daysWithErrors = validateWorkSchedule(formValues);
    setWorkScheduleErrors(daysWithErrors);
  }, [attemptedSubmit, formValues, isSubmitSuccessful, setWorkScheduleErrors]);

  return null;
};

const EditProfile = (
  props: ModalProps & {
    addresses: Address[];
    profileData: UserProfileDataQuery;
    workScheduleErrors: string[];
  }
) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const disabled = useDisableSubmit();
  const { setValue, watch } = useFormContext();

  const { addresses, profileData, workScheduleErrors, ...modalProps } = props;
  const avatarURL = watch("avatarURL");
  const timezone = watch("timezone");
  const timezoneAuto = watch("timezoneAuto");

  const timezones = useTimezoneList();

  useEffect(() => {
    if (!timezoneAuto || !timezones) return;

    const autoTimezone = getTimezoneAutomatically(timezones);
    if (autoTimezone === timezone) return;

    setValue("timezone", autoTimezone, { shouldDirty: true });
  }, [setValue, timezone, timezoneAuto, timezones]);

  const handleChangeAvatar = (avatarURL: string) => {
    setValue("avatarURL", avatarURL, { shouldDirty: true });
  };

  return (
    <StandardModal
      contentHandlesSafeArea={false}
      header={
        <Header
          mobileCtaLabel="Save"
          mobileCtaProps={{ disabled, type: "submit" }}
          variant="bordered"
        >
          <h3 className="m-0">Edit Profile</h3>
        </Header>
      }
      {...modalProps}
    >
      <Main className="flex flex-col grow px-16 md:px-32">
        <div className="flex flex-col md:flex-row pt-24 pb-12">
          <div className="mr-24">
            <div className="flex justify-center">
              <EditableAvatar
                avatarURL={avatarURL}
                name={profileData.me.name}
                onAvatarUpdate={handleChangeAvatar}
                rounded="rounded-lg"
              />
            </div>
          </div>
          <div className="flex flex-col md:grow">
            <div className="flex flex-col grow md:flex-row">
              <TextInput<FormData>
                config={{ required: true }}
                label="Name"
                name="name"
                wrapperClassName="mt-0 md:mr-24 grow"
              />
              <TextInput<FormData>
                label="Title"
                name="bio"
                wrapperClassName="mt-0 grow"
              />
            </div>
            <TextInput<FormData>
              className="disabled:!bg-background-ghost"
              label="Account Email (private)"
              name="email"
              wrapperClassName="mt-0"
              disabled
            />
          </div>
        </div>

        <Hr />

        <div className="flex flex-col gap-16 mt-20 mb-32">
          <div className="text-body-bold text-text-primary group-hover/collapsible-section-label:text-text-primary-hover">
            Time and place
          </div>

          <div className="grid md:grid-cols-[200px_1fr] gap-8 items-center">
            <div className="text-body text-text-primary">Time zone:</div>
            <ComboBox<FormData>
              config={{ required: true }}
              disabled={timezoneAuto}
              formatInput={(inputValue: string) => {
                // Add leading zero after plus/minus sign for single digits;
                // for example, will match "(UTC-08:00)" for inputs like "-08" and "-8"
                return inputValue
                  .replace(/^-([1-9])/, "-0$1")
                  .replace(/^\+([1-9])/, "+0$1");
              }}
              maxHeight="max-h-[192px]"
              name="timezone"
              options={timezones ? formatTimezoneList(timezones) : []}
              placeholder="Select your time zone"
              wrapperClassName="!my-0"
            />

            <div data-grid-cell />
            <Checkbox<FormData>
              label="Set time zone automatically"
              name="timezoneAuto"
              wrapperClassName="!my-0"
            />

            <div data-grid-cell />
            <div data-grid-cell />

            <div className="text-body text-text-primary">Location:</div>
            <TextInput<FormData>
              className="disabled:!bg-background-ghost"
              name="location"
              placeholder="Your location (optional)"
              wrapperClassName="!my-0"
            />
          </div>
        </div>

        <Hr />

        <WorkSchedule workScheduleErrors={workScheduleErrors} />

        <Hr />

        <div className="flex flex-col gap-20 my-20">
          <CollapsibleSection label="Workspaces" collapsed>
            <div className="grid grid-rows-1 gap-8 mt-20">
              {profileData.workspaces?.edges?.map(wks => (
                <WorkspaceItem key={wks.id} workspaceID={wks.node.id} />
              ))}
            </div>
          </CollapsibleSection>

          <CollapsibleSection label="Account options" collapsed>
            <button
              className="bg-background-body border border-border-container flex items-center justify-start mt-20 px-16 py-14 rounded-lg text-subhead-bold text-text-alert hover:text-text-alert-hover w-full"
              onClick={() =>
                openModal(<DeleteAccountModal parentModalId={props.modalId} />)
              }
              type="button"
            >
              <Icon className="mr-6" icon="Trash" size={20} />
              Delete Account
            </button>
          </CollapsibleSection>
        </div>
      </Main>

      <Footer className="hidden md:flex">
        <SubmitButton>Save</SubmitButton>
      </Footer>
    </StandardModal>
  );
};

const EditProfileModal = (props: ModalProps): JSX.Element | null => {
  const { authReady, fetchAuthData } = useAuthData();
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [workScheduleErrors, setWorkScheduleErrors] = useState<string[]>([]);

  const { data: profileData } = useUserProfileDataQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });

  const [updateProfile] = useUpdateProfileMutation({
    errorPolicy: "all",
  });
  const [updateSettings] = useUpdateSettingsMutation({
    errorPolicy: "all",
  });

  const onSubmit = useCallback(
    (data: FormData) => {
      if (!authReady || !profileData) return;

      setAttemptedSubmit(true);

      const daysWithErrors = validateWorkSchedule(data);
      if (daysWithErrors.length) {
        setWorkScheduleErrors(daysWithErrors);
        return;
      }
      setWorkScheduleErrors([]);

      const promises = [
        updateProfile({
          variables: {
            input: {
              avatarURL: data.avatarURL,
              bio: data.bio,
              name: data.name,
            },
          },
        }),
        updateSettings({
          update: (cache, { data }) => {
            if (!data) return;
            cache.writeQuery({
              query: UserSettingsDocument,
              data: {
                settings: {
                  ...data.updateSettings,
                  workSchedule: {
                    ...data.updateSettings.workSchedule,
                  },
                },
              },
            });
          },
          variables: {
            settings: {
              location: data.location,
              timezone: {
                auto: data.timezoneAuto,
                name: data.timezone ?? null,
              },
              workSchedule: {
                enabled: data.workScheduleEnabled,
                sun: getWorkScheduleDayPayload(
                  data.sunEnabled,
                  data.sunStart,
                  data.sunEnd
                ),
                mon: getWorkScheduleDayPayload(
                  data.monEnabled,
                  data.monStart,
                  data.monEnd
                ),
                tue: getWorkScheduleDayPayload(
                  data.tueEnabled,
                  data.tueStart,
                  data.tueEnd
                ),
                wed: getWorkScheduleDayPayload(
                  data.wedEnabled,
                  data.wedStart,
                  data.wedEnd
                ),
                thu: getWorkScheduleDayPayload(
                  data.thuEnabled,
                  data.thuStart,
                  data.thuEnd
                ),
                fri: getWorkScheduleDayPayload(
                  data.friEnabled,
                  data.friStart,
                  data.friEnd
                ),
                sat: getWorkScheduleDayPayload(
                  data.satEnabled,
                  data.satStart,
                  data.satEnd
                ),
              },
            },
          },
        }),
      ];

      return Promise.all(promises)
        .then(() => fetchAuthData({ refresh: true }))
        .finally(() => {
          closeModal(`${props.modalId}`);
        });
    },
    [
      authReady,
      profileData,
      updateProfile,
      updateSettings,
      fetchAuthData,
      closeModal,
      props.modalId,
    ]
  );

  if (!profileData) return null;
  const addresses = profileData.addresses?.edges?.map(item => item.node);
  const user = profileData.me;
  const settings = profileData.settings;

  return (
    <Form
      className="contents"
      onSubmit={onSubmit}
      useFormProps={{
        defaultValues: {
          avatarURL: user.avatarURL,
          bio: user.bio,
          email: addresses?.map(a => a.address).join(", "),
          location: settings?.location ?? undefined,
          name: user.name,
          timezone: settings?.timezone?.name ?? undefined,
          timezoneAuto: settings?.timezone?.auto ?? false,
          workScheduleEnabled: settings?.workSchedule?.enabled ?? false,

          sunEnabled: !!settings?.workSchedule?.sun,
          sunStart: convertMinutesToTimeString(
            settings?.workSchedule?.sun?.start,
            "09:00"
          ),
          sunEnd: convertMinutesToTimeString(
            settings?.workSchedule?.sun?.end,
            "17:00"
          ),
          monEnabled: !!settings?.workSchedule?.mon,
          monStart: convertMinutesToTimeString(
            settings?.workSchedule?.mon?.start,
            "09:00"
          ),
          monEnd: convertMinutesToTimeString(
            settings?.workSchedule?.mon?.end,
            "17:00"
          ),
          tueEnabled: !!settings?.workSchedule?.tue,
          tueStart: convertMinutesToTimeString(
            settings?.workSchedule?.tue?.start,
            "09:00"
          ),
          tueEnd: convertMinutesToTimeString(
            settings?.workSchedule?.tue?.end,
            "17:00"
          ),
          wedEnabled: !!settings?.workSchedule?.wed,
          wedStart: convertMinutesToTimeString(
            settings?.workSchedule?.wed?.start,
            "09:00"
          ),
          wedEnd: convertMinutesToTimeString(
            settings?.workSchedule?.wed?.end,
            "17:00"
          ),
          thuEnabled: !!settings?.workSchedule?.thu,
          thuStart: convertMinutesToTimeString(
            settings?.workSchedule?.thu?.start,
            "09:00"
          ),
          thuEnd: convertMinutesToTimeString(
            settings?.workSchedule?.thu?.end,
            "17:00"
          ),
          friEnabled: !!settings?.workSchedule?.fri,
          friStart: convertMinutesToTimeString(
            settings?.workSchedule?.fri?.start,
            "09:00"
          ),
          friEnd: convertMinutesToTimeString(
            settings?.workSchedule?.fri?.end,
            "17:00"
          ),
          satEnabled: !!settings?.workSchedule?.sat,
          satStart: convertMinutesToTimeString(
            settings?.workSchedule?.sat?.start,
            "09:00"
          ),
          satEnd: convertMinutesToTimeString(
            settings?.workSchedule?.sat?.end,
            "17:00"
          ),
        },
      }}
    >
      <EditProfile
        addresses={addresses}
        profileData={profileData}
        workScheduleErrors={workScheduleErrors}
        {...props}
      />
      <WorkScheduleOverseer
        attemptedSubmit={attemptedSubmit}
        setWorkScheduleErrors={setWorkScheduleErrors}
      />
    </Form>
  );
};

export default EditProfileModal;
