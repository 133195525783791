import { ComponentProps, useMemo } from "react";

import {
  GroupEdge,
  GroupPreviewEdge,
  WorkspaceEdge,
  WorkspacePreviewEdge,
  nodeAs,
  nodeIs,
} from "@utility-types";
import { Button } from "components/design-system/Button";
import Icon from "components/design-system/icons/Icon";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import ProfileItemPrimitive from "components/design-system/ui/ProfileItemPrimitive";
import GroupProfileModal, {
  GroupProfileTab,
} from "components/group/GroupModal/GroupProfileModal";
import { ConfirmationAlert } from "components/Modals";
import { routeToGroup } from "components/routing/utils";
import useGroupMembership from "components/workspace-group/hooks/useGroupMembership";
import {
  JoinableBy,
  ThreadSubscription,
  useFetchGroupOrPreviewEdgeQuery,
} from "generated/graphql";
import { MemberRole } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import useRecipientDomains from "hooks/workspace/useRecipientDomains";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import { formatGroupName } from "utils/group/formatGroupName";
import tw from "utils/tw";

const Joined = () => {
  return (
    <div className="flex items-center justify-center text-text-subtle group-hover/row:hidden">
      <Icon className="mr-3" icon="Check" size={12} />
      <span className="font-semibold text-xs">Joined</span>
    </div>
  );
};

const Requested = () => {
  return (
    <div className="flex items-center justify-center text-text-subtle group-hover/row:hidden">
      <span className="font-semibold text-xs">Requested</span>
    </div>
  );
};

type GroupsDirectoryRowPrimitiveProps = {
  badgeProps?: ComponentProps<typeof ProfileItemPrimitive>["badgeProps"];
  description?: string;
  edge: GroupEdge | GroupPreviewEdge | WorkspaceEdge | WorkspacePreviewEdge;
  /** External group rows display different information. */
  isExternal?: boolean;
  title?: string;
  isGeneralGroup?: boolean;
};

export const GroupsDirectoryRowPrimitive = ({
  badgeProps,
  description: descriptionProp,
  edge,
  title,
  isExternal = false,
  isGeneralGroup = false,
}: GroupsDirectoryRowPrimitiveProps) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const formattedGroupName = formatGroupName({ name: edge?.node?.name });

  const handleGroupSubscription = () => {
    if (edge?.__typename !== "GroupEdge") return;
    openModal(
      <GroupProfileModal
        defaultTab={GroupProfileTab.Notifications}
        groupID={edge.node.id}
        isAdmin={edge.memberRole === MemberRole.Admin}
      />
    );
  };

  const {
    actionPending,
    cancelRequestToJoin,
    joinGroup,
    joinRequested,
    leaveGroup,
    requestToJoin,
  } = useGroupMembership(edge.node.id);

  const { memberEdge, previewEdge } = useMemberEdge(edge);
  const { workspace } = useRecipientDomains(edge.node);

  const isSubscribedToAll =
    memberEdge?.__typename === "GroupEdge" &&
    memberEdge.threadSubscription === ThreadSubscription.Inbox;
  const isPreview = !!previewEdge;
  const isArchived =
    nodeIs(edge.node, ["Group", "GroupPreview"]) && !!edge.node.archivedAt;
  const isClosed =
    nodeIs(edge.node, ["Group", "GroupPreview"]) &&
    edge.node.joinableBy !== JoinableBy.Workspace;
  const groupRoute =
    (isClosed || isArchived) && isPreview
      ? routeToGroup({ groupID: edge.node.id, to: "secondary" })
      : routeToGroup({ groupID: edge.node.id });

  const description =
    descriptionProp ??
    nodeAs(edge.node, ["Group", "GroupPreview"])?.description;
  const memberCountText = `${edge.node.members?.totalCount ?? 0} member${
    edge.node.members?.totalCount === 1 ? "" : "s"
  }`;

  const subtitles = useMemo(
    () =>
      isExternal
        ? [
            <span
              key="external description-identity-badge"
              className="flex items-center"
            >
              {workspace && <span className="mr-6">{workspace.name}</span>}
              <IdentityBadge recipient={edge.node} />
            </span>,
            description,
          ]
        : isGeneralGroup && !breakpointMD
          ? [memberCountText]
          : [
              edge.node.admin?.name && breakpointMD
                ? `Admin: ${edge.node.admin.name}`
                : undefined,
              <span
                key="internal-description-identity-badge"
                className="flex items-center gap-6 overflow-hidden"
              >
                {((breakpointMD && description) || workspace) && (
                  <span className="truncate">
                    {(breakpointMD && description) || workspace?.name}
                  </span>
                )}
                <IdentityBadge recipient={edge.node} />
              </span>,
            ],
    [
      breakpointMD,
      description,
      edge.node,
      isExternal,
      isGeneralGroup,
      memberCountText,
      workspace,
    ]
  );

  return (
    <div
      className={tw(
        "group/row pr-20 overflow-hidden relative w-full",
        "flex items-center justify-between",
        "hover:bg-background-list-hover"
      )}
    >
      <div className="grow min-w-0 mr-20 pl-20 py-12">
        <ProfileItemPrimitive
          avatarURL={
            edge && "avatarURL" in edge.node ? edge.node.avatarURL : ""
          }
          badgeProps={badgeProps}
          badgeVisible={!!badgeProps?.avatarURL || !!badgeProps?.emojiProps}
          emojiProps={{ emoji: formattedGroupName.emoji ?? "" }}
          iconProps={{ icon: "Users" }}
          closed={isClosed}
          archived={isArchived}
          name={edge.node.name ?? ""}
          route={groupRoute}
          subtitles={subtitles}
          title={title ?? formattedGroupName.name}
        />
      </div>

      {previewEdge ? joinRequested ? <Requested /> : null : <Joined />}

      <div
        className={tw(
          "group-hover/row:flex hidden",
          "items-center justify-end px-20",
          "absolute inset-y-0 right-0"
        )}
      >
        {previewEdge ? (
          joinRequested ? (
            <Button
              buttonStyle="secondary"
              buttonType="sm"
              className="bg-background-body"
              disabled={actionPending}
              onClick={cancelRequestToJoin}
              type="button"
            >
              Undo request
            </Button>
          ) : isClosed ? (
            <Button
              buttonType="sm"
              disabled={actionPending}
              onClick={requestToJoin}
              type="button"
            >
              Request
            </Button>
          ) : (
            <Button
              buttonType="sm"
              disabled={actionPending}
              onClick={joinGroup}
              type="button"
            >
              Join
            </Button>
          )
        ) : edge.node.__typename === "Workspace" ? (
          <div
            className={tw(
              "bg-background-action-strong px-8 py-6 rounded-md text-text-inverse",
              "flex items-center justify-center"
            )}
          >
            <Icon className="mr-6" icon="Info" size={16} />
            <span className="font-semibold text-xs">General is required.</span>
          </div>
        ) : (
          <>
            {isSubscribedToAll && (
              <Button
                buttonStyle="icon-secondary"
                buttonType="sm"
                className="bg-background-body hover:bg-background-secondary border-1 !border-border-strong mr-8 p-4 rounded-md shadow-level1"
                icon="BellSmallFilled"
                iconSize={20}
                onClick={handleGroupSubscription}
                tooltip="Following all threads"
                type="button"
              />
            )}
            <Button
              buttonStyle="secondaryDestructive"
              buttonType="sm"
              className="bg-background-body"
              disabled={actionPending}
              onClick={() => {
                openModal(
                  <ConfirmationAlert
                    confirmLabel="Leave Group"
                    header={`Leave "${title ?? formattedGroupName.name}"?`}
                    message="You'll lose access to threads sent to the group."
                    onConfirm={async () => {
                      const groupEdge = nodeAs(edge, ["GroupEdge"]);
                      if (!groupEdge) return;
                      return leaveGroup(groupEdge);
                    }}
                  />
                );
              }}
              type="button"
            >
              Leave group
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const GroupsDirectoryRow = ({
  groupID,
  ...props
}: Omit<ComponentProps<typeof GroupsDirectoryRowPrimitive>, "edge"> & {
  groupID: string;
}) => {
  const { authData, authReady } = useAuthData();

  const { data } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    skip: !authData?.me.id || !groupID || groupID.startsWith("wks"),
    variables: { id: `${groupID}-${authData?.me.id}` },
  });

  const edge = nodeAs(data?.node, [
    "GroupEdge",
    "GroupPreviewEdge",
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ]);

  if (!edge) return null;
  return <GroupsDirectoryRowPrimitive edge={edge} {...props} />;
};

export default GroupsDirectoryRow;
