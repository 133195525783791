import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { GroupEdge } from "@utility-types";
import { RecommendedBadge } from "components/design-system/Badge";
import { Button } from "components/design-system/Button";
import { Form, RadioGroup } from "components/design-system/Forms";
import { InformationBubble } from "components/design-system/InformationBubble";
import { PreferencesModal } from "components/user";
import { ThreadSubscription } from "generated/graphql";
import useModalStore from "store/useModalStore";

import useGroupActions from "../GroupProfileBar/hooks/useGroupActions";

type FormData = {
  threadSubscription: ThreadSubscription;
};

type Props = {
  groupEdge: GroupEdge;
};

const GroupNotificationsFormFields = ({
  onSubmit,
}: {
  onSubmit: (data: FormData) => Promise<void | undefined>;
}) => {
  const onSubmitRef = useRef(onSubmit);
  onSubmitRef.current = onSubmit;

  const { watch } = useFormContext<FormData>();

  const threadSubscription = watch("threadSubscription");

  useEffect(() => {
    onSubmitRef.current({ threadSubscription });
  }, [threadSubscription]);

  return (
    <div className="flex flex-col mt-4">
      <div className="flex items-center">
        <span className="font-semibold text-base text-text-primary leading-5 select-none">
          Follow in Inbox
        </span>
      </div>
      <div>
        <RadioGroup<FormData>
          groupClassName="w-full !mb-0 !mt-8"
          name="threadSubscription"
          optionLabelClassName="items-center"
          optionStyle="bordered"
          options={[
            {
              label: "Important threads",
              description:
                "When you are @mentioned, reply to a thread or follow a thread.",
              value: ThreadSubscription.Activity,
              children: <RecommendedBadge />,
            },
            {
              label: "Everything",
              description: "See every thread in Inbox unless you unfollow.",
              value: ThreadSubscription.Inbox,
            },
          ]}
        />
      </div>
    </div>
  );
};

const GroupNotificationsSettings = ({ groupEdge }: Props) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const { updateGroupEdge } = useGroupActions(groupEdge?.node);

  const handleSubmit = async (data: FormData) => updateGroupEdge(data);

  const handlePreferences = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openModal(<PreferencesModal />);
  };

  // We're removing the "Activity" option, as it will be the default
  // but in the mean time we have to treat them equivalently
  let threadSubscription = groupEdge?.threadSubscription;
  if (threadSubscription === ThreadSubscription.Archive) {
    threadSubscription = ThreadSubscription.Activity;
  }

  return (
    <Form<FormData>
      onSubmit={handleSubmit}
      useFormProps={{ defaultValues: { threadSubscription } }}
    >
      <div className="pt-12 flex flex-col">
        <GroupNotificationsFormFields onSubmit={handleSubmit} />
        <div className="mt-32">
          <InformationBubble>
            Individual thread settings can override these group settings.
            <br />
            You can change your device notification settings in{" "}
            <Button
              buttonStyle="none"
              buttonType="none"
              className="!text-footnote text-text-link hover:text-text-link-hover hover:underline !inline"
              onClick={handlePreferences}
            >
              Preferences
            </Button>
          </InformationBubble>
        </div>
      </div>
    </Form>
  );
};

export default GroupNotificationsSettings;
