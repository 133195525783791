import { Workspace } from "@utility-types";
import { Button } from "components/design-system/Button";
import { PendingInvitationsBanner } from "components/MembersList/PendingInvitationsBanner";
import { useRemindWorkspaceMembersMutation } from "generated/graphql";

type Props = {
  workspace: Workspace;
  count: number;
  onCompleteResend: () => void;
};

export const WorkspacePendingInvitationsBanner = ({
  workspace,
  count,
  onCompleteResend,
}: Props) => {
  const [remindWorkspaceMembers] = useRemindWorkspaceMembersMutation();

  const handleResendInvitations = async () => {
    await remindWorkspaceMembers({
      variables: {
        id: workspace.id,
        input: {
          allInvited: true,
          allPending: true,
        },
      },
      onCompleted: onCompleteResend,
    });
  };

  return (
    <PendingInvitationsBanner marginClassName="my-8">
      <p className="md:flex md:gap-8 m-0 ">
        There are {count} pending invitations.
        <Button
          buttonStyle="simplePrimary"
          buttonType="text"
          onClick={handleResendInvitations}
          className="text-footnote text-text-link"
        >
          Resend invitations.
        </Button>
      </p>
    </PendingInvitationsBanner>
  );
};
