import { WorkspaceAppEdge, nodeAs } from "@utility-types";
import { useConfigureApps } from "components/apps";
import {
  AppConfigureMethod,
  FetchThreadEdgeDocument,
  MemberRole,
  type ThreadFieldsFragment,
  type ThreadPreviewFieldsFragment,
  useAddThreadRecipientsMutation,
  useRemoveThreadRecipientsMutation,
} from "generated/graphql";
import useRefetchOnAppActive from "hooks/useRefetchOnAppActive";

const useThreadApps = (
  thread?: ThreadFieldsFragment | ThreadPreviewFieldsFragment
) => {
  const { configureApp, editApp, getAppsAvailable } = useConfigureApps();
  useRefetchOnAppActive([FetchThreadEdgeDocument]);

  const [addThreadRecipients] = useAddThreadRecipientsMutation();
  const [removeThreadRecipients] = useRemoveThreadRecipientsMutation();

  const appsAvailable = getAppsAvailable(
    nodeAs(thread, ["Thread"])?.apps.edges ?? []
  )?.filter(app => app.node);

  const addApp = (workspaceApp: WorkspaceAppEdge) => {
    if (!thread?.id) return;
    const workspaceID = workspaceApp.workspaceID;

    if (workspaceApp.node.configureThread === AppConfigureMethod.Add) {
      addThreadRecipients({
        variables: {
          id: thread.id,
          recipients: [
            { recipient: workspaceApp.appUser.id, role: MemberRole.Default },
          ],
        },
      });
      return;
    }
    configureApp({
      app: workspaceApp.node,
      closeModalProp: false,
      configurableID: thread.id,
      workspaceID,
    });
  };

  const removeApp = (workspaceApp: WorkspaceAppEdge) => {
    if (!thread?.id) return Promise.resolve();
    return removeThreadRecipients({
      variables: { id: thread.id, recipientIDs: workspaceApp.appUser.id },
    });
  };

  const newEditApp = (workspaceApp: WorkspaceAppEdge) => {
    if (!thread?.id) return Promise.resolve();
    return editApp(workspaceApp.node, thread.id, workspaceApp.workspaceID);
  };

  return {
    addApp,
    appsAvailable,
    editApp: newEditApp,
    removeApp,
  };
};

export default useThreadApps;
