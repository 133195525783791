import { ComponentProps, useCallback } from "react";

import {
  GroupEdgeSimple,
  Mailbox,
  UserEdge,
  WorkspaceEdgeSimple,
} from "@utility-types";
import { Button } from "components/design-system/Button";
import { EmptyListPrimitive } from "components/design-system/EmptyListPrimitive";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import useModalStore from "store/useModalStore";

type Props = {
  mailbox: Mailbox | "drafts";
  recipient?: UserEdge | GroupEdgeSimple | WorkspaceEdgeSimple | undefined;
  hideCompose?: boolean;
};

const getEmptyListData = (
  mailbox: Props["mailbox"],
  isGroupRecipient: boolean
): {
  secondaryText?: string;
  icon: ComponentProps<typeof EmptyListPrimitive>["icon"];
} => {
  if (isGroupRecipient) {
    return {
      secondaryText: "Threads sent to this group will appear here.",
      icon: "Thread",
    };
  }

  switch (mailbox) {
    case Mailbox.Inbox:
      return {
        secondaryText: "All threads you follow will appear here.",
        icon: "Bell",
      };
    case Mailbox.Archived:
      return {
        secondaryText: "Your archived threads will appear here.",
        icon: "Archive",
      };
    case Mailbox.Sent:
      return {
        secondaryText: "Threads you create will appear here.",
        icon: "Send",
      };
    case Mailbox.All:
      return {
        secondaryText: "All your threads will appear here.",
        icon: "List",
      };
    default:
      return {
        secondaryText: undefined,
        icon: "Thread",
      };
  }
};

const EmptyList = ({ mailbox, recipient, hideCompose = false }: Props) => {
  const isGroupRecipient = recipient?.__typename === "GroupEdge";
  const { secondaryText, icon } = getEmptyListData(mailbox, isGroupRecipient);
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const openThreadCompose = useCallback(() => {
    openModal(
      <ThreadComposeModal
        initialDraft={recipient ? { recipients: [recipient.node] } : undefined}
      />
    );
  }, [openModal, recipient]);

  return (
    <EmptyListPrimitive
      icon={icon}
      padding={`px-24 ${isGroupRecipient ? "pt-32" : "pt-[78px]"}`}
      justifyContent={isGroupRecipient ? "start" : "center"}
      primaryText="No threads... yet."
      secondaryText={secondaryText}
    >
      {!hideCompose && (
        <Button
          buttonStyle="primary"
          className="!text-subhead-bold"
          onClick={openThreadCompose}
        >
          New thread
        </Button>
      )}
    </EmptyListPrimitive>
  );
};

export default EmptyList;
