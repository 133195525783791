import { type Option as MultiOptionDropdownOption } from "components/design-system/Forms/MultiOptionDropdown";

export enum ThreadPermissions {
  Locked = "locked",
  Restricted = "restricted",
}

export const threadPermissionsOptions: MultiOptionDropdownOption[] = [
  {
    value: ThreadPermissions.Locked,
    name: "Lock recipients",
    description: "Only starter and admins can add recipients or edit title",
    displayName: "Recipients locked",
    icon: "Lock",
  },
  {
    value: ThreadPermissions.Restricted,
    name: "Turn off thread replies",
    description: "Only starter and admins can reply or edit title",
    displayName: "Thread replies off",
    icon: "ChatRoundedOff",
  },
];
