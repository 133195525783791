/**
 * NOTE: Schema types generated by graphql-codegen.
 * Avoid using these types directly, instead use generated fragment types
 * imported from graphql-operations.ts or wrapped in @utility-types/graphql
 */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export enum AiFeedbackReason {
  Clarity = 'clarity',
  Depth = 'depth',
  Other = 'other',
  Problematic = 'problematic',
  Relevance = 'relevance',
  Style = 'style'
}

export type AiSettings = {
  __typename: 'AISettings';
  chatModel: Maybe<Scalars['String']['output']>;
};

export type Activity = {
  __typename: 'Activity';
  actor: User;
  id: Scalars['ID']['output'];
  meta: Maybe<ActivityMeta>;
  object: Maybe<ActivityObject>;
  target: Maybe<ActivityTarget>;
  time: Scalars['String']['output'];
  verb: ActivityVerb;
};

export type ActivityMeta = ActivityReactedToMessageMeta;

export type ActivityObject = GroupPreview | JoinApproval | Link | Message | ThreadPreview | User;

export type ActivityReactedToMessageMeta = {
  __typename: 'ActivityReactedToMessageMeta';
  reaction: Scalars['String']['output'];
};

export type ActivityTarget = GroupPreview | ThreadPreview | WorkspacePreview;

export enum ActivityVerb {
  AddedGroup = 'addedGroup',
  AddedGroupWorkspace = 'addedGroupWorkspace',
  AddedThread = 'addedThread',
  AddedToGroup = 'addedToGroup',
  AddedToThread = 'addedToThread',
  CreatedMessageLink = 'createdMessageLink',
  ReactedToMessage = 'reactedToMessage',
  SentJoinApproval = 'sentJoinApproval',
  SentJoinInvitation = 'sentJoinInvitation',
  SentJoinRequest = 'sentJoinRequest'
}

export enum Addable {
  Anyone = 'anyone',
  None = 'none',
  Workspace = 'workspace'
}

export type Address = Node & {
  __typename: 'Address';
  address: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type AddressConnection = Connection & {
  __typename: 'AddressConnection';
  edges: Array<AddressEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AddressEdge = Edge & {
  __typename: 'AddressEdge';
  cursor: Scalars['String']['output'];
  node: Address;
};

export type App = Node & {
  __typename: 'App';
  avatarURL: Scalars['String']['output'];
  configureGroup: Maybe<AppConfigureMethod>;
  configureGroupPath: Maybe<Scalars['String']['output']>;
  configureThread: Maybe<AppConfigureMethod>;
  configureThreadPath: Maybe<Scalars['String']['output']>;
  configureWorkspace: AppConfigureMethod;
  configureWorkspacePath: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  docsURL: Scalars['String']['output'];
  homeURL: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instructions: Scalars['String']['output'];
  name: Scalars['String']['output'];
  publishedAt: Maybe<Scalars['String']['output']>;
  threadActions: Maybe<Array<ThreadAction>>;
  workspace: WorkspacePreview;
};

export enum AppConfigureMethod {
  Add = 'add',
  Configure = 'configure'
}

export type AppConnection = Connection & {
  __typename: 'AppConnection';
  edges: Array<AppEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AppEdge = Edge & {
  __typename: 'AppEdge';
  cursor: Scalars['String']['output'];
  node: App;
};

export type AppInput = {
  avatarURL?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  docsURL?: InputMaybe<Scalars['String']['input']>;
  homeURL?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  workspaceID: Scalars['ID']['input'];
};

export type AppUnfurlSetup = {
  __typename: 'AppUnfurlSetup';
  appUser: User;
  message: Scalars['String']['output'];
  path: Scalars['String']['output'];
  url: Scalars['String']['output'];
  workspaceAppID: Scalars['ID']['output'];
};

export type Attachment = ExternalObject | File | ThreadPreview;

export type AuthorizeWorkspaceAppInput = {
  appID: Scalars['ID']['input'];
  scopes: Array<Scalars['String']['input']>;
  workspaceID: Scalars['ID']['input'];
};

export type AuthorizeWorkspaceAppResult = {
  __typename: 'AuthorizeWorkspaceAppResult';
  authorizationCode: Scalars['String']['output'];
};

export enum ChatType {
  Group = 'group',
  User = 'user'
}

export type ChatsFilter = {
  chatType: ChatType;
};

export type CompleteMilestone = {
  milestone: UserMilestone;
  teamSize?: InputMaybe<UserMilestoneTeamSize>;
};

export type Config = {
  __typename: 'Config';
  shareFeedbackURL: Maybe<Scalars['String']['output']>;
  streamToken: Scalars['String']['output'];
};

export type Connection = {
  edges: Array<AddressEdge | AppEdge | CustomEmojiEdge | DeviceEdge | DomainEdge | DraftEdge | FileEdge | GroupEdge | GroupMemberEdge | GroupPreviewEdge | JoinApprovalEdge | LlmModelEdge | LambdaEdge | LambdaSecretEdge | LinkEdge | MessageEdge | MessagePinEdge | NotificationEdge | ReactionEdge | ThreadEdge | ThreadPreviewEdge | UserEdge | UserPreviewEdge | WorkspaceAppEdge | WorkspaceEdge | WorkspaceMemberEdge | WorkspacePreviewEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreateAppUnfurlOptOutInput = {
  appID: Scalars['ID']['input'];
};

export type CreateCustomEmojiInput = {
  fileID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  workspaceID: Scalars['ID']['input'];
};

export type CreateExternalObjectInput = {
  url: Scalars['String']['input'];
};

export type CreateFileInput = {
  uploadTicketID: Scalars['String']['input'];
};

export type CreateGroupInput = {
  avatarURL?: InputMaybe<Scalars['String']['input']>;
  chatMessageableBy?: InputMaybe<MessageableBy>;
  description?: InputMaybe<Scalars['String']['input']>;
  joinableBy?: InputMaybe<JoinableBy>;
  members?: InputMaybe<Array<MemberRoleInput>>;
  messageableBy?: InputMaybe<MessageableBy>;
  name: Scalars['String']['input'];
  workspaceID?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateLambdaInput = {
  appID: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  event: Scalars['String']['input'];
  name: Scalars['String']['input'];
  source: Scalars['String']['input'];
};

export type CreatePersistentChatInput = {
  message: MessageInput;
  recipient: Scalars['String']['input'];
};

export type CreateWorkspaceInput = {
  avatarURL?: InputMaybe<Scalars['String']['input']>;
  chatMessageableBy?: InputMaybe<MessageableBy>;
  domains: Array<Scalars['String']['input']>;
  members: Array<MemberRoleInput>;
  messageableBy?: InputMaybe<MessageableBy>;
  name: Scalars['String']['input'];
};

export type CustomEmoji = Node & {
  __typename: 'CustomEmoji';
  createdAt: Scalars['String']['output'];
  createdBy: User;
  id: Scalars['ID']['output'];
  imageURL: Scalars['String']['output'];
  name: Scalars['String']['output'];
  workspaceID: Scalars['ID']['output'];
};

export type CustomEmojiConnection = Connection & {
  __typename: 'CustomEmojiConnection';
  edges: Array<CustomEmojiEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CustomEmojiEdge = Edge & {
  __typename: 'CustomEmojiEdge';
  cursor: Scalars['String']['output'];
  node: CustomEmoji;
};

export type CustomEmojiNameSuggestions = {
  __typename: 'CustomEmojiNameSuggestions';
  names: Array<Scalars['String']['output']>;
};

export type DailySchedule = {
  __typename: 'DailySchedule';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type DailyScheduleInput = {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

export type DeleteMeInput = {
  deactivate: Scalars['Boolean']['input'];
};

export enum DestinationMailbox {
  Inbox = 'inbox'
}

export type Device = Node & {
  __typename: 'Device';
  id: Scalars['ID']['output'];
  platform: Scalars['String']['output'];
};

export type DeviceConnection = Connection & {
  __typename: 'DeviceConnection';
  edges: Array<DeviceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DeviceEdge = Edge & {
  __typename: 'DeviceEdge';
  cursor: Scalars['String']['output'];
  node: Device;
};

export type DeviceHeartbeatInput = {
  presence: DevicePresence;
};

export enum DevicePresence {
  Active = 'active',
  Inactive = 'inactive',
  Offline = 'offline'
}

export type Domain = Node & {
  __typename: 'Domain';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  workspaces: WorkspaceOrPreviewConnection;
};

export type DomainConnection = Connection & {
  __typename: 'DomainConnection';
  edges: Array<DomainEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DomainEdge = Edge & Node & {
  __typename: 'DomainEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  node: Domain;
};

export type Draft = Node & {
  __typename: 'Draft';
  id: Scalars['ID']['output'];
  message: DraftMessage;
  messageableBy: MessageableBy;
  recipients: Array<GroupPreview | User | WorkspacePreview>;
  recipientsAddable: Addable;
  replyToMessage: Maybe<Message>;
  subject: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type DraftConnection = Connection & {
  __typename: 'DraftConnection';
  edges: Array<DraftEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DraftEdge = Edge & {
  __typename: 'DraftEdge';
  cursor: Scalars['String']['output'];
  node: Draft;
};

export type DraftMessage = {
  __typename: 'DraftMessage';
  attachments: Array<Attachment>;
  text: Scalars['String']['output'];
  textPreview: Scalars['String']['output'];
};

export type DraftSuggestion = {
  __typename: 'DraftSuggestion';
  recipients: Array<Scalars['ID']['output']>;
  subject: Maybe<Scalars['String']['output']>;
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: Address | App | CustomEmoji | Device | Domain | DomainEdge | Draft | ExternalObject | File | Group | GroupEdge | GroupMemberEdge | GroupPreview | GroupPreviewEdge | JoinApproval | LlmModel | Lambda | LambdaSecret | Link | Message | MessageEdge | MessageMetadata | MessagePin | MessagePreview | Notification | Reaction | SearchResult | SlackImport | Thread | ThreadEdge | ThreadPreview | ThreadPreviewEdge | ThreadRecipientEdge | ThreadSummaryPoint | User | UserEdge | UserPreviewEdge | Workspace | WorkspaceAppEdge | WorkspaceAppWebhook | WorkspaceEdge | WorkspaceInviteLink | WorkspaceMemberEdge | WorkspacePreview | WorkspacePreviewEdge | WorkspaceSettings | WorkspaceSubscription;
};

export type EmojiNameSuggestionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  workspaceID: Scalars['ID']['input'];
};

export type EmojiSuggestion = {
  __typename: 'EmojiSuggestion';
  emoji: Maybe<Scalars['String']['output']>;
};

export type ExternalObject = Node & {
  __typename: 'ExternalObject';
  audio: Maybe<Array<ExternalObjectMedia>>;
  description: Maybe<Scalars['String']['output']>;
  icon: Maybe<ExternalObjectMedia>;
  id: Scalars['ID']['output'];
  image: Maybe<Array<ExternalObjectMedia>>;
  layout: ExternalObjectLayout;
  objectType: ExternalObjectType;
  siteName: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  url: Scalars['String']['output'];
  video: Maybe<Array<ExternalObjectMedia>>;
};

export enum ExternalObjectLayout {
  Default = 'default',
  LargeImage = 'largeImage'
}

export type ExternalObjectMedia = {
  __typename: 'ExternalObjectMedia';
  alt: Maybe<Scalars['String']['output']>;
  blurHash: Maybe<Scalars['String']['output']>;
  contentType: Maybe<Scalars['String']['output']>;
  height: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export enum ExternalObjectType {
  Audio = 'audio',
  Image = 'image',
  Other = 'other',
  Video = 'video'
}

export enum FeedType {
  All = 'all',
  Groups = 'groups',
  Mentions = 'mentions',
  Reactions = 'reactions',
  Requests = 'requests'
}

export type FeedbackAiInput = {
  includeThread: Scalars['Boolean']['input'];
  reasons: Array<AiFeedbackReason>;
  responseMessageID: Scalars['ID']['input'];
};

export enum FeedbackType {
  Ai = 'ai'
}

export type File = Node & {
  __typename: 'File';
  contentLength: Scalars['String']['output'];
  contentMD5: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  fileType: FileType;
  id: Scalars['ID']['output'];
  metadata: Maybe<FileMetadata>;
  name: Scalars['String']['output'];
  previewable: Maybe<Scalars['Boolean']['output']>;
  url: Scalars['String']['output'];
};

export type FileConnection = Connection & {
  __typename: 'FileConnection';
  edges: Array<FileEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FileEdge = Edge & {
  __typename: 'FileEdge';
  cursor: Scalars['String']['output'];
  node: File;
};

export type FileMetadata = {
  __typename: 'FileMetadata';
  blurHash: Maybe<Scalars['String']['output']>;
  height: Maybe<Scalars['Int']['output']>;
  width: Maybe<Scalars['Int']['output']>;
};

export type FilePreview = {
  __typename: 'FilePreview';
  boxAccessToken: Scalars['String']['output'];
  boxFileID: Scalars['String']['output'];
  embedURL: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export enum FileType {
  Audio = 'audio',
  File = 'file',
  Image = 'image',
  Video = 'video'
}

export type Filter = {
  match?: InputMaybe<Scalars['String']['input']>;
};

export type FormData = {
  __typename: 'FormData';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GenerateEmojiImageInput = {
  prompt: Scalars['String']['input'];
};

export type Group = Node & {
  __typename: 'Group';
  admin: Maybe<User>;
  apps: WorkspaceAppConnection;
  archivedAt: Maybe<Scalars['String']['output']>;
  archivedBy: Maybe<User>;
  avatarURL: Maybe<Scalars['String']['output']>;
  chatMessageableBy: MessageableBy;
  commonWorkspaceIDs: Array<Scalars['ID']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  joinableBy: JoinableBy;
  members: GroupMemberConnection;
  messageableBy: MessageableBy;
  name: Scalars['String']['output'];
  pendingApprovals: JoinApprovalConnection;
  workspaceID: Maybe<Scalars['ID']['output']>;
};


export type GroupMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<GroupMembersOrder>;
};


export type GroupPendingApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupConnection = Connection & {
  __typename: 'GroupConnection';
  edges: Array<GroupEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupEdge = Edge & Node & {
  __typename: 'GroupEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastInteractionAt: Scalars['String']['output'];
  memberRole: MemberRole;
  node: Group;
  persistentChatEdge: ThreadEdge;
  /** @deprecated Use `memberRole` */
  role: Scalars['String']['output'];
  threadSubscription: ThreadSubscription;
  unreadThreadCounts: UnreadCounts;
};

export type GroupMemberConnection = Connection & {
  __typename: 'GroupMemberConnection';
  edges: Array<GroupMemberEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupMemberEdge = Edge & Node & {
  __typename: 'GroupMemberEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  memberRole: MemberRole;
  node: User;
  /** @deprecated Use `memberRole` */
  role: Scalars['String']['output'];
};

export enum GroupMembersOrder {
  Added = 'added',
  Name = 'name'
}

export type GroupOrPreview = Group | GroupPreview;

export type GroupOrPreviewConnection = {
  __typename: 'GroupOrPreviewConnection';
  edges: Array<GroupOrPreviewEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupOrPreviewEdge = {
  __typename: 'GroupOrPreviewEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  node: GroupOrPreview;
};

export type GroupPreview = Joinable & Node & Recipient & {
  __typename: 'GroupPreview';
  admin: Maybe<User>;
  archivedAt: Maybe<Scalars['String']['output']>;
  archivedBy: Maybe<User>;
  avatarURL: Maybe<Scalars['String']['output']>;
  commonWorkspaceIDs: Array<Scalars['ID']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  joinableBy: JoinableBy;
  members: UserPreviewConnection;
  name: Scalars['String']['output'];
  workspaceID: Maybe<Scalars['ID']['output']>;
};


export type GroupPreviewMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupPreviewEdge = Edge & Node & {
  __typename: 'GroupPreviewEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  node: GroupPreview;
};

export type GroupsByWorkspace = {
  __typename: 'GroupsByWorkspace';
  groups: GroupConnection;
  id: Scalars['ID']['output'];
  workspaceEdge: WorkspaceOrPreviewEdge;
};


export type GroupsByWorkspaceGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GroupsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<GroupsOrder>;
};

export type GroupsByWorkspaceConnection = {
  __typename: 'GroupsByWorkspaceConnection';
  edges: Array<GroupsByWorkspaceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GroupsByWorkspaceEdge = {
  __typename: 'GroupsByWorkspaceEdge';
  cursor: Scalars['String']['output'];
  node: GroupsByWorkspace;
};

export type GroupsFilter = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  excludeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  match?: InputMaybe<Scalars['String']['input']>;
  member?: InputMaybe<Scalars['Boolean']['input']>;
  mutualWorkspaceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  mutualWorkspaces?: InputMaybe<Scalars['Boolean']['input']>;
  threadID?: InputMaybe<Scalars['ID']['input']>;
  unreadThreads?: InputMaybe<Scalars['Boolean']['input']>;
  unseenThreads?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceID?: InputMaybe<Scalars['ID']['input']>;
};

export enum GroupsOrder {
  Added = 'added',
  Interaction = 'interaction',
  Name = 'name'
}

export type IWorkspace = {
  avatarURL: Maybe<Scalars['String']['output']>;
  domains: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Invoice = {
  __typename: 'Invoice';
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  periodEnd: Scalars['String']['output'];
  periodStart: Scalars['String']['output'];
  status: InvoiceStatus;
  total: Scalars['Int']['output'];
};

export type InvoiceLineItem = {
  __typename: 'InvoiceLineItem';
  amount: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
};

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Void = 'void'
}

export type JoinApproval = Node & {
  __typename: 'JoinApproval';
  admin: Maybe<User>;
  adminApprovedAt: Maybe<Scalars['String']['output']>;
  approvalStatus: JoinApprovalStatus;
  id: Scalars['ID']['output'];
  joinable: GroupPreview | ThreadPreview | WorkspacePreview;
  joining: GroupPreview | User | WorkspacePreview;
  joiningApprovedAt: Maybe<Scalars['String']['output']>;
  joiningRole: MemberRole;
  requestedAt: Scalars['String']['output'];
  requester: User;
};

export type JoinApprovalConnection = Connection & {
  __typename: 'JoinApprovalConnection';
  edges: Array<JoinApprovalEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type JoinApprovalEdge = Edge & {
  __typename: 'JoinApprovalEdge';
  cursor: Scalars['String']['output'];
  node: JoinApproval;
};

export enum JoinApprovalStatus {
  Approved = 'approved',
  Invited = 'invited',
  Requested = 'requested',
  RequestedOther = 'requestedOther'
}

export type JoinApprovalsFilter = {
  joinableID?: InputMaybe<Scalars['ID']['input']>;
};

export type JoinRequestInput = {
  joinableID: Scalars['ID']['input'];
  recipient?: InputMaybe<Scalars['String']['input']>;
};

export type Joinable = {
  admin: Maybe<User>;
  id: Scalars['ID']['output'];
  joinableBy: JoinableBy;
  name: Scalars['String']['output'];
};

export enum JoinableBy {
  Approval = 'approval',
  Domain = 'domain',
  None = 'none',
  Workspace = 'workspace'
}

export type LlmModel = Node & {
  __typename: 'LLMModel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  provider: LlmProvider;
  providerLogoURL: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
};

export type LlmModelConnection = Connection & {
  __typename: 'LLMModelConnection';
  edges: Array<LlmModelEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LlmModelEdge = Edge & {
  __typename: 'LLMModelEdge';
  cursor: Scalars['String']['output'];
  node: LlmModel;
};

export enum LlmProvider {
  Anthropic = 'anthropic',
  Google = 'google',
  Groq = 'groq',
  Openai = 'openai'
}

export type Lambda = Node & {
  __typename: 'Lambda';
  activeVersion: Scalars['Int']['output'];
  appId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  event: LambdaEvent;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  source: Scalars['String']['output'];
  sourceDraft: Scalars['String']['output'];
  testThread: Maybe<Thread>;
};

export type LambdaConnection = Connection & {
  __typename: 'LambdaConnection';
  edges: Array<LambdaEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LambdaEdge = Edge & {
  __typename: 'LambdaEdge';
  cursor: Scalars['String']['output'];
  node: Lambda;
};

export enum LambdaEvent {
  MemberAdded = 'member_added',
  Message = 'message',
  RecipientAdded = 'recipient_added'
}

export type LambdaSecret = Node & {
  __typename: 'LambdaSecret';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LambdaSecretConnection = Connection & {
  __typename: 'LambdaSecretConnection';
  edges: Array<LambdaSecretEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LambdaSecretEdge = Edge & {
  __typename: 'LambdaSecretEdge';
  cursor: Scalars['String']['output'];
  node: LambdaSecret;
};

export type LeaveFeedbackInput = {
  ai?: InputMaybe<FeedbackAiInput>;
  attachmentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  feedbackType: FeedbackType;
  platform: Platform;
  text?: InputMaybe<Scalars['String']['input']>;
  workspaceID: Scalars['ID']['input'];
};

export type Link = Node & {
  __typename: 'Link';
  disposition: LinkDisposition;
  id: Scalars['ID']['output'];
  linked: Linked;
  linkedCategory: LinkedCategory;
  message: MessagePreview;
  thread: ThreadPreview;
};

export type LinkConnection = Connection & {
  __typename: 'LinkConnection';
  edges: Array<LinkEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum LinkDisposition {
  Attachment = 'attachment',
  Inline = 'inline'
}

export type LinkEdge = Edge & {
  __typename: 'LinkEdge';
  cursor: Scalars['String']['output'];
  node: Link;
};

export type Linked = ExternalObject | File | GroupPreview | MessagePreview | ThreadPreview | User;

export enum LinkedCategory {
  File = 'file',
  Link = 'link',
  Media = 'media',
  Other = 'other'
}

export type LinksFilter = {
  linkedCategories?: InputMaybe<Array<LinkedCategory>>;
  match?: InputMaybe<Scalars['String']['input']>;
  recipientIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MarkThreadEdgeArchivedInput = {
  isArchived: Scalars['Boolean']['input'];
  remindAt?: InputMaybe<Scalars['String']['input']>;
};

export type MarkThreadEdgeReadInput = {
  isRead: Scalars['Boolean']['input'];
  lastReadID?: InputMaybe<Scalars['ID']['input']>;
};

export type MarkThreadEdgeReminderInput = {
  remindAt?: InputMaybe<Scalars['String']['input']>;
};

export type MarkThreadEdgeSeenInput = {
  isSeen: Scalars['Boolean']['input'];
};

export type MarkThreadEdgeStarredInput = {
  isStarred: Scalars['Boolean']['input'];
};

export type MarkThreadEdgeSubscribedInput = {
  subscription: ThreadSubscription;
};

export type MasonryRequestInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  path: Scalars['String']['input'];
  requestType: Scalars['String']['input'];
  session?: InputMaybe<Scalars['JSON']['input']>;
  surface?: InputMaybe<Scalars['JSON']['input']>;
  timestamp: Scalars['String']['input'];
};

export type MasonryResponse = {
  __typename: 'MasonryResponse';
  actions: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  context: Maybe<Scalars['JSON']['output']>;
  data: Maybe<Scalars['JSON']['output']>;
  responseType: Scalars['String']['output'];
};

export type MasonrySessionOriginationInput = {
  appID?: InputMaybe<Scalars['ID']['input']>;
  groupID?: InputMaybe<Scalars['ID']['input']>;
  messageID?: InputMaybe<Scalars['ID']['input']>;
  messageSenderID?: InputMaybe<Scalars['ID']['input']>;
  threadID?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
  unfurlMessageID?: InputMaybe<Scalars['String']['input']>;
  workspaceID?: InputMaybe<Scalars['ID']['input']>;
};

export type MasonrySessionResponse = {
  __typename: 'MasonrySessionResponse';
  context: Scalars['JSON']['output'];
  origination: Scalars['JSON']['output'];
};

export enum MemberRole {
  Admin = 'admin',
  Default = 'default'
}

export type MemberRoleInput = {
  member: Scalars['String']['input'];
  memberRole?: InputMaybe<MemberRole>;
  role: Scalars['String']['input'];
};

export type MembersFilter = {
  match?: InputMaybe<Scalars['String']['input']>;
};

export type Message = Node & {
  __typename: 'Message';
  attachments: Array<Attachment>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ownReactions: Array<Reaction>;
  quotedMessage: Maybe<QuotedMessage>;
  reactionCounts: Array<ReactionCount>;
  reactions: ReactionConnection;
  replyThreadID: Maybe<Scalars['ID']['output']>;
  streamID: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  textPreview: Scalars['String']['output'];
  threadID: Scalars['ID']['output'];
  updatedAt: Maybe<Scalars['String']['output']>;
  user: User;
};


export type MessageReactionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MessageAiResponseInfo = {
  __typename: 'MessageAIResponseInfo';
  model: LlmModel;
  sourcesDescription: Scalars['String']['output'];
  toUserID: Scalars['ID']['output'];
};

export type MessageConnection = Connection & {
  __typename: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
  replyCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  unreadCounts: UnreadCounts;
};

export type MessageEdge = Edge & Node & {
  __typename: 'MessageEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  node: Message;
};

export type MessageInput = {
  attachments: Array<Scalars['ID']['input']>;
  externalID?: InputMaybe<Scalars['String']['input']>;
  quotedMessageID?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type MessageMetadata = Node & {
  __typename: 'MessageMetadata';
  aiResponseInfo: Maybe<MessageAiResponseInfo>;
  id: Scalars['ID']['output'];
  messageID: Scalars['ID']['output'];
};

export type MessagePin = Node & {
  __typename: 'MessagePin';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Message;
  updatedAt: Scalars['String']['output'];
  user: User;
};

export type MessagePinConnection = Connection & {
  __typename: 'MessagePinConnection';
  edges: Array<MessagePinEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MessagePinEdge = Edge & {
  __typename: 'MessagePinEdge';
  cursor: Scalars['String']['output'];
  node: MessagePin;
};

export type MessagePinInput = {
  messageID: Scalars['ID']['input'];
};

export type MessagePinsFilter = {
  threadID: Scalars['ID']['input'];
};

export type MessagePreview = Node & {
  __typename: 'MessagePreview';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  threadID: Scalars['ID']['output'];
  user: User;
};

export enum MessageableBy {
  Admin = 'admin',
  Anyone = 'anyone',
  Recipient = 'recipient',
  Workspace = 'workspace'
}

export type Mutation = {
  __typename: 'Mutation';
  addGroupMembers: Group;
  addThreadRecipients: Thread;
  addWorkspaceApp: WorkspaceAppEdge;
  addWorkspaceMembers: Workspace;
  approveJoinApproval: JoinApproval;
  authorizeWorkspaceApp: Maybe<AuthorizeWorkspaceAppResult>;
  cancelJoinApproval: Scalars['Boolean']['output'];
  cancelWorkspaceSubscription: WorkspaceSubscription;
  completeMilestone: Scalars['Boolean']['output'];
  connectWithUsers: UserConnection;
  createApp: App;
  createAppUnfurlOptOut: Maybe<Scalars['Boolean']['output']>;
  createCustomEmoji: CustomEmoji;
  createExternalObject: ExternalObject;
  createFile: File;
  createGroup: Group;
  createLLMAPIKey: WorkspaceLlmapiKey;
  createLambda: Lambda;
  createLambdaSecret: LambdaSecret;
  createPersistentChat: Thread;
  createSlackImport: SlackImport;
  createWorkspace: Workspace;
  createWorkspaceApp: WorkspaceAppEdge;
  deleteApp: Scalars['Boolean']['output'];
  deleteCustomEmoji: Scalars['Boolean']['output'];
  deleteDraft: Scalars['Boolean']['output'];
  deleteFile: Scalars['Boolean']['output'];
  deleteGroup: Scalars['Boolean']['output'];
  deleteLLMAPIKey: Scalars['Boolean']['output'];
  deleteLambda: Scalars['Boolean']['output'];
  deleteLambdaSecret: Scalars['Boolean']['output'];
  deleteMe: Scalars['Boolean']['output'];
  deleteMessage: Scalars['Boolean']['output'];
  deleteSlackImport: Scalars['Boolean']['output'];
  deleteThread: Scalars['Boolean']['output'];
  deleteWorkspace: Scalars['Boolean']['output'];
  deviceHeartbeat: Scalars['Boolean']['output'];
  enableLambda: Lambda;
  generateEmojiImage: File;
  ignoreJoinApproval: Scalars['Boolean']['output'];
  joinGroup: Group;
  joinThreadEdgeChannel: Scalars['Boolean']['output'];
  joinWorkspace: Workspace;
  leaveFeedback: Scalars['Boolean']['output'];
  leaveGroup: Scalars['Boolean']['output'];
  leaveThread: Scalars['Boolean']['output'];
  leaveWorkspace: Scalars['Boolean']['output'];
  loadSlackImport: SlackImport;
  markAllThreadEdges: Scalars['Int']['output'];
  markNotificationEdgeRead: NotificationEdge;
  /** @deprecated Use `markThreadEdges` */
  markThreadEdgeArchived: ThreadEdge;
  /** @deprecated Use `markThreadEdges` */
  markThreadEdgeRead: ThreadEdge;
  /** @deprecated Use `markThreadEdges` */
  markThreadEdgeStarred: ThreadEdge;
  /** @deprecated Use `markThreadEdges` */
  markThreadEdgeSubscribed: ThreadEdge;
  markThreadEdges: Array<ThreadEdge>;
  masonryRequest: MasonryResponse;
  masonrySession: MasonrySessionResponse;
  performThreadAction: ThreadActionResult;
  pinMessage: MessagePin;
  registerDevice: Device;
  remindWorkspaceMembers: Scalars['Boolean']['output'];
  removeGroupMembers: Group;
  removeThreadRecipients: Thread;
  removeWorkspaceApp: Scalars['Boolean']['output'];
  removeWorkspaceMembers: Workspace;
  resetWorkspaceInviteLink: Scalars['Boolean']['output'];
  saveDraft: Draft;
  sendDraft: Thread;
  sendJoinRequest: JoinApproval;
  sendMessage: SendMessageOutput;
  startSlackImport: SlackImport;
  syncAppObjects: Scalars['Boolean']['output'];
  unfurlURL: UnfurlUrlResult;
  unpinMessage: Scalars['Boolean']['output'];
  updateApp: App;
  updateGroup: Group;
  updateGroupEdge: GroupEdge;
  updateJoinApproval: JoinApproval;
  updateLambda: Lambda;
  updateLambdaSecret: Scalars['Boolean']['output'];
  updateMe: User;
  updateMessage: Message;
  updateSettings: UserSettings;
  updateSlackImport: SlackImport;
  updateThread: Thread;
  updateThreadMetadata: ThreadMetadata;
  updateUserEdge: UserEdge;
  updateWorkspace: Workspace;
  updateWorkspaceInviteLink: WorkspaceInviteLink;
  updateWorkspaceSettings: WorkspaceSettings;
  updateWorkspaceSubscription: WorkspaceSubscription;
  uploadTicket: UploadTicketResponse;
};


export type MutationAddGroupMembersArgs = {
  id: Scalars['ID']['input'];
  members: Array<MemberRoleInput>;
};


export type MutationAddThreadRecipientsArgs = {
  id: Scalars['ID']['input'];
  recipients: Array<RecipientRoleInput>;
};


export type MutationAddWorkspaceAppArgs = {
  input: WorkspaceAppInput;
};


export type MutationAddWorkspaceMembersArgs = {
  id: Scalars['ID']['input'];
  members: Array<MemberRoleInput>;
};


export type MutationApproveJoinApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAuthorizeWorkspaceAppArgs = {
  input: AuthorizeWorkspaceAppInput;
};


export type MutationCancelJoinApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancelWorkspaceSubscriptionArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type MutationCompleteMilestoneArgs = {
  input: CompleteMilestone;
};


export type MutationConnectWithUsersArgs = {
  users: Array<Scalars['String']['input']>;
};


export type MutationCreateAppArgs = {
  input: AppInput;
};


export type MutationCreateAppUnfurlOptOutArgs = {
  input: CreateAppUnfurlOptOutInput;
};


export type MutationCreateCustomEmojiArgs = {
  input: CreateCustomEmojiInput;
};


export type MutationCreateExternalObjectArgs = {
  input?: InputMaybe<CreateExternalObjectInput>;
};


export type MutationCreateFileArgs = {
  input?: InputMaybe<CreateFileInput>;
  uploadTicketID?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateLlmapiKeyArgs = {
  input: WorkspaceLlmapiKeyInput;
};


export type MutationCreateLambdaArgs = {
  input: CreateLambdaInput;
};


export type MutationCreateLambdaSecretArgs = {
  lambdaID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationCreatePersistentChatArgs = {
  input: CreatePersistentChatInput;
};


export type MutationCreateSlackImportArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationCreateWorkspaceAppArgs = {
  input: AppInput;
};


export type MutationDeleteAppArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCustomEmojiArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDraftArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLlmapiKeyArgs = {
  id: Scalars['ID']['input'];
  workspaceID: Scalars['ID']['input'];
};


export type MutationDeleteLambdaArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLambdaSecretArgs = {
  secretID: Scalars['ID']['input'];
};


export type MutationDeleteMeArgs = {
  input: DeleteMeInput;
};


export type MutationDeleteMessageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSlackImportArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type MutationDeleteThreadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeviceHeartbeatArgs = {
  id: Scalars['ID']['input'];
  input: DeviceHeartbeatInput;
};


export type MutationEnableLambdaArgs = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type MutationGenerateEmojiImageArgs = {
  input: GenerateEmojiImageInput;
};


export type MutationIgnoreJoinApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationJoinGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationJoinThreadEdgeChannelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationJoinWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLeaveFeedbackArgs = {
  input: LeaveFeedbackInput;
};


export type MutationLeaveGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLeaveThreadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLeaveWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLoadSlackImportArgs = {
  fileID: Scalars['ID']['input'];
  workspaceID: Scalars['ID']['input'];
};


export type MutationMarkAllThreadEdgesArgs = {
  archived?: InputMaybe<MarkThreadEdgeArchivedInput>;
  filter?: InputMaybe<ThreadsFilter>;
  read?: InputMaybe<MarkThreadEdgeReadInput>;
  reminder?: InputMaybe<MarkThreadEdgeReminderInput>;
  seen?: InputMaybe<MarkThreadEdgeSeenInput>;
  starred?: InputMaybe<MarkThreadEdgeStarredInput>;
  subscribed?: InputMaybe<MarkThreadEdgeSubscribedInput>;
};


export type MutationMarkNotificationEdgeReadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarkThreadEdgeArchivedArgs = {
  id: Scalars['ID']['input'];
  input: MarkThreadEdgeArchivedInput;
};


export type MutationMarkThreadEdgeReadArgs = {
  id: Scalars['ID']['input'];
  input: MarkThreadEdgeReadInput;
};


export type MutationMarkThreadEdgeStarredArgs = {
  id: Scalars['ID']['input'];
  input: MarkThreadEdgeStarredInput;
};


export type MutationMarkThreadEdgeSubscribedArgs = {
  id: Scalars['ID']['input'];
  input: MarkThreadEdgeSubscribedInput;
};


export type MutationMarkThreadEdgesArgs = {
  archived?: InputMaybe<MarkThreadEdgeArchivedInput>;
  ids: Array<Scalars['ID']['input']>;
  read?: InputMaybe<MarkThreadEdgeReadInput>;
  reminder?: InputMaybe<MarkThreadEdgeReminderInput>;
  seen?: InputMaybe<MarkThreadEdgeSeenInput>;
  starred?: InputMaybe<MarkThreadEdgeStarredInput>;
  subscribed?: InputMaybe<MarkThreadEdgeSubscribedInput>;
};


export type MutationMasonryRequestArgs = {
  input: MasonryRequestInput;
};


export type MutationMasonrySessionArgs = {
  input: MasonrySessionOriginationInput;
};


export type MutationPerformThreadActionArgs = {
  input: ThreadActionInput;
};


export type MutationPinMessageArgs = {
  input: MessagePinInput;
};


export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};


export type MutationRemindWorkspaceMembersArgs = {
  id: Scalars['ID']['input'];
  input: RemindWorkspaceMembersInput;
};


export type MutationRemoveGroupMembersArgs = {
  id: Scalars['ID']['input'];
  memberIDs: Array<Scalars['ID']['input']>;
};


export type MutationRemoveThreadRecipientsArgs = {
  id: Scalars['ID']['input'];
  recipientIDs: Array<Scalars['ID']['input']>;
};


export type MutationRemoveWorkspaceAppArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveWorkspaceMembersArgs = {
  id: Scalars['ID']['input'];
  memberIDs: Array<Scalars['ID']['input']>;
};


export type MutationResetWorkspaceInviteLinkArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type MutationSaveDraftArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: SaveDraftInput;
};


export type MutationSendDraftArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: SaveDraftInput;
};


export type MutationSendJoinRequestArgs = {
  input: JoinRequestInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationStartSlackImportArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type MutationSyncAppObjectsArgs = {
  input: SyncAppObjectsInput;
};


export type MutationUnfurlUrlArgs = {
  input?: InputMaybe<UnfurlUrlInput>;
};


export type MutationUnpinMessageArgs = {
  input: MessagePinInput;
};


export type MutationUpdateAppArgs = {
  id: Scalars['ID']['input'];
  input: AppInput;
};


export type MutationUpdateGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdateGroupInput;
};


export type MutationUpdateGroupEdgeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateGroupEdgeInput;
};


export type MutationUpdateJoinApprovalArgs = {
  id: Scalars['ID']['input'];
  input: UpdateJoinApprovalInput;
};


export type MutationUpdateLambdaArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLambdaInput;
};


export type MutationUpdateLambdaSecretArgs = {
  secretID: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};


export type MutationUpdateMessageArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMessageInput;
};


export type MutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};


export type MutationUpdateSlackImportArgs = {
  config?: InputMaybe<SlackImportConfigInput>;
  workspaceID: Scalars['ID']['input'];
};


export type MutationUpdateThreadArgs = {
  id: Scalars['ID']['input'];
  input: UpdateThreadInput;
};


export type MutationUpdateThreadMetadataArgs = {
  input: UpdateThreadMetadataInput;
};


export type MutationUpdateUserEdgeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserEdgeInput;
};


export type MutationUpdateWorkspaceArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWorkspaceInput;
};


export type MutationUpdateWorkspaceInviteLinkArgs = {
  input: UpdateWorkspaceInviteLinkInput;
};


export type MutationUpdateWorkspaceSettingsArgs = {
  input: UpdateWorkspaceSettingsInput;
};


export type MutationUpdateWorkspaceSubscriptionArgs = {
  input: WorkspaceSubscriptionInput;
  workspaceID: Scalars['ID']['input'];
};


export type MutationUploadTicketArgs = {
  input: UploadTicketInput;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type Notification = Node & {
  __typename: 'Notification';
  activities: Array<Activity>;
  activityCount: Scalars['Int']['output'];
  actorCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  verb: ActivityVerb;
};

export type NotificationConnection = Connection & {
  __typename: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  unreadCount: Scalars['Int']['output'];
  unseenCount: Scalars['Int']['output'];
};

export type NotificationEdge = Edge & {
  __typename: 'NotificationEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isRead: Scalars['Boolean']['output'];
  isSeen: Scalars['Boolean']['output'];
  node: Notification;
};

export enum NotificationPermissionStatus {
  Denied = 'denied',
  Granted = 'granted',
  Prompt = 'prompt'
}

export type NotificationsFilter = {
  feedType: FeedType;
};

export enum NotifyMessagesSetting {
  Direct = 'direct',
  Inbox = 'inbox',
  None = 'none'
}

export enum NotifyMessagesSoundSetting {
  Direct = 'direct',
  Inbox = 'inbox',
  None = 'none'
}

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Maybe<Scalars['String']['output']>;
};

export enum Platform {
  Android = 'android',
  Ios = 'ios',
  Mac = 'mac',
  Web = 'web'
}

export type Query = {
  __typename: 'Query';
  addresses: AddressConnection;
  apps: AppConnection;
  config: Config;
  customEmojis: CustomEmojiConnection;
  devices: DeviceConnection;
  domains: DomainConnection;
  draftSuggestion: DraftSuggestion;
  drafts: DraftConnection;
  emojiNameSuggestion: CustomEmojiNameSuggestions;
  emojiSuggestion: EmojiSuggestion;
  filePreview: FilePreview;
  groups: GroupConnection;
  groupsByWorkspace: GroupsByWorkspaceConnection;
  joinApprovals: JoinApprovalConnection;
  joinRequest: Maybe<JoinApproval>;
  joinRequests: JoinApprovalConnection;
  lambdaSecrets: LambdaSecretConnection;
  lambdas: LambdaConnection;
  links: LinkConnection;
  llmModels: LlmModelConnection;
  localGroups: GroupOrPreviewConnection;
  localThreads: ThreadConnection;
  localUsers: UserConnection;
  localWorkspaces: WorkspaceConnection;
  me: User;
  messageMetadata: Maybe<MessageMetadata>;
  messagePins: MessagePinConnection;
  node: Maybe<Address | App | CustomEmoji | Device | Domain | DomainEdge | Draft | ExternalObject | File | Group | GroupEdge | GroupMemberEdge | GroupPreview | GroupPreviewEdge | JoinApproval | LlmModel | Lambda | LambdaSecret | Link | Message | MessageEdge | MessageMetadata | MessagePin | MessagePreview | Notification | Reaction | SearchResult | SlackImport | Thread | ThreadEdge | ThreadPreview | ThreadPreviewEdge | ThreadRecipientEdge | ThreadSummaryPoint | User | UserEdge | UserPreviewEdge | Workspace | WorkspaceAppEdge | WorkspaceAppWebhook | WorkspaceEdge | WorkspaceInviteLink | WorkspaceMemberEdge | WorkspacePreview | WorkspacePreviewEdge | WorkspaceSettings | WorkspaceSubscription>;
  notifications: NotificationConnection;
  persistentChats: ThreadConnection;
  searchMessages: SearchResultConnection;
  settings: UserSettings;
  slackImport: Maybe<SlackImport>;
  threadMetadata: ThreadMetadata;
  threadSummaryPoints: ThreadSummaryPointConnection;
  threads: ThreadConnection;
  timezones: Array<TimezoneInfo>;
  users: UserConnection;
  workspaceAppConfig: WorkspaceAppConfig;
  workspaceInviteLink: WorkspaceInviteLink;
  workspaceInviteLinkSigned: Scalars['String']['output'];
  workspaceSettings: WorkspaceSettings;
  workspaceSubscription: WorkspaceSubscription;
  workspaceSubscriptionBilling: SubscriptionBillingSession;
  workspaceSubscriptionCheckout: SubscriptionCheckoutSession;
  workspaceSubscriptionPlans: Array<SubscriptionPlan>;
  workspaces: WorkspaceConnection;
};


export type QueryAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomEmojisArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  workspaceID?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDraftSuggestionArgs = {
  input?: InputMaybe<SendMessageInput>;
  replyToMessageID?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDraftsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryEmojiNameSuggestionArgs = {
  input: EmojiNameSuggestionInput;
};


export type QueryEmojiSuggestionArgs = {
  name: Scalars['String']['input'];
};


export type QueryFilePreviewArgs = {
  fileID: Scalars['ID']['input'];
};


export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GroupsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<GroupsOrder>;
};


export type QueryJoinApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<JoinApprovalsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJoinRequestArgs = {
  joinableID: Scalars['ID']['input'];
};


export type QueryJoinRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<JoinApprovalsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLambdaSecretsArgs = {
  lambdaId: Scalars['ID']['input'];
};


export type QueryLambdasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLinksArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LinksFilter>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLlmModelsArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type QueryLocalGroupsArgs = {
  filter?: InputMaybe<GroupsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLocalThreadsArgs = {
  filter?: InputMaybe<ThreadsFilter>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLocalUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLocalWorkspacesArgs = {
  filter?: InputMaybe<WorkspacesFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMessageMetadataArgs = {
  messageID: Scalars['ID']['input'];
};


export type QueryMessagePinsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter: MessagePinsFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationsFilter>;
  markEdgesSeen?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryPersistentChatsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  filter: ChatsFilter;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ThreadsOrder>;
};


export type QuerySearchMessagesArgs = {
  input: SearchInput;
};


export type QuerySlackImportArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type QueryThreadMetadataArgs = {
  threadID: Scalars['ID']['input'];
};


export type QueryThreadSummaryPointsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter: ThreadSummaryFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryThreadsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ThreadsFilter>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ThreadsOrder>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<UsersOrder>;
};


export type QueryWorkspaceAppConfigArgs = {
  appID: Scalars['ID']['input'];
  configurableID: Scalars['ID']['input'];
  workspaceID: Scalars['ID']['input'];
};


export type QueryWorkspaceInviteLinkArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type QueryWorkspaceInviteLinkSignedArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type QueryWorkspaceSettingsArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type QueryWorkspaceSubscriptionArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type QueryWorkspaceSubscriptionBillingArgs = {
  workspaceID: Scalars['ID']['input'];
};


export type QueryWorkspaceSubscriptionCheckoutArgs = {
  input: WorkspaceSubscriptionInput;
  workspaceID: Scalars['ID']['input'];
};


export type QueryWorkspacesArgs = {
  filter?: InputMaybe<WorkspacesFilter>;
};

export type QuotedMessage = {
  __typename: 'QuotedMessage';
  attachments: Array<Attachment>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  streamID: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  textPreview: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['String']['output']>;
  user: User;
};

export type Reaction = Node & {
  __typename: 'Reaction';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  user: User;
};

export type ReactionConnection = Connection & {
  __typename: 'ReactionConnection';
  edges: Array<ReactionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReactionCount = {
  __typename: 'ReactionCount';
  c: Scalars['Int']['output'];
  r: Scalars['String']['output'];
};

export type ReactionEdge = Edge & {
  __typename: 'ReactionEdge';
  cursor: Scalars['String']['output'];
  node: Reaction;
};

export type Recipient = {
  avatarURL: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RecipientConnection = {
  __typename: 'RecipientConnection';
  edges: Array<RecipientEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RecipientEdge = {
  __typename: 'RecipientEdge';
  cursor: Scalars['String']['output'];
  node: GroupPreview | User | WorkspacePreview;
};

export type RecipientRoleInput = {
  recipient: Scalars['String']['input'];
  recipientRole?: InputMaybe<MemberRole>;
  role: Scalars['String']['input'];
};

export type RegisterDeviceInput = {
  appBuild: Scalars['String']['input'];
  appVersion: Scalars['String']['input'];
  fcmToken?: InputMaybe<Scalars['String']['input']>;
  manufacturer: Scalars['String']['input'];
  model: Scalars['String']['input'];
  osVersion: Scalars['String']['input'];
  permissionStatus?: InputMaybe<NotificationPermissionStatus>;
  platform: Scalars['String']['input'];
  presence?: InputMaybe<DevicePresence>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

export type RemindWorkspaceMembersInput = {
  allInvited?: InputMaybe<Scalars['Boolean']['input']>;
  allPending?: InputMaybe<Scalars['Boolean']['input']>;
  memberIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SaveDraftInput = {
  mailboxes?: InputMaybe<Array<DestinationMailbox>>;
  message: MessageInput;
  messageableBy?: InputMaybe<MessageableBy>;
  recipients: Array<Scalars['String']['input']>;
  recipientsAddable?: InputMaybe<Addable>;
  replyToMessageID?: InputMaybe<Scalars['ID']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type SearchInput = {
  boostExponentialDecay?: InputMaybe<Scalars['Float']['input']>;
  boostWeight?: InputMaybe<Scalars['Float']['input']>;
  knnCandidatesMultiplier?: InputMaybe<Scalars['Float']['input']>;
  knnSimilarity?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  match: Scalars['String']['input'];
  matchFuzziness?: InputMaybe<Scalars['Float']['input']>;
  matchKNN?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchItem = Message | ThreadPreview;

export type SearchResult = Node & {
  __typename: 'SearchResult';
  id: Scalars['ID']['output'];
  item: SearchItem;
  score: Scalars['Float']['output'];
  type: SearchResultType;
};

export type SearchResultConnection = {
  __typename: 'SearchResultConnection';
  edges: Array<SearchResultEdge>;
};

export type SearchResultEdge = {
  __typename: 'SearchResultEdge';
  node: SearchResult;
};

export enum SearchResultType {
  Keyword = 'keyword',
  Semantic = 'semantic'
}

export type SendMessageInput = {
  message: MessageInput;
  thread?: InputMaybe<ThreadInput>;
  threadID?: InputMaybe<Scalars['ID']['input']>;
};

export type SendMessageOutput = {
  __typename: 'SendMessageOutput';
  message: Maybe<Message>;
  thread: Thread;
};

export type SlackImport = Node & {
  __typename: 'SlackImport';
  config: Maybe<SlackImportConfig>;
  id: Scalars['ID']['output'];
  notificationsThreadID: Maybe<Scalars['ID']['output']>;
  progress: Maybe<SlackImportProgress>;
  status: SlackImportStatus;
};

export type SlackImportConfig = {
  __typename: 'SlackImportConfig';
  groups: Array<Scalars['String']['output']>;
  includeChannels: Array<Scalars['String']['output']>;
  skipChannels: Array<Scalars['String']['output']>;
};

export type SlackImportConfigInput = {
  groups: Array<Scalars['String']['input']>;
  includeChannels: Array<Scalars['String']['input']>;
  skipChannels: Array<Scalars['String']['input']>;
};

export type SlackImportProgress = {
  __typename: 'SlackImportProgress';
  channels: SlackImportProgressItem;
  messages: SlackImportProgressItem;
  users: SlackImportProgressItem;
};

export type SlackImportProgressItem = {
  __typename: 'SlackImportProgressItem';
  imported: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum SlackImportStatus {
  Completed = 'completed',
  Empty = 'empty',
  Error = 'error',
  Invalid = 'invalid',
  Loading = 'loading',
  Ready = 'ready',
  Running = 'running'
}

export type SubscriptionBillingSession = {
  __typename: 'SubscriptionBillingSession';
  stripePortalURL: Scalars['String']['output'];
};

export type SubscriptionCheckoutSession = {
  __typename: 'SubscriptionCheckoutSession';
  stripeClientSecret: Scalars['String']['output'];
};

export enum SubscriptionInterval {
  Month = 'month',
  Year = 'year'
}

export type SubscriptionPlan = {
  __typename: 'SubscriptionPlan';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interval: SubscriptionInterval;
  name: Scalars['String']['output'];
};

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export type SyncAppObjectInput = {
  externalID: Scalars['String']['input'];
  path: Scalars['String']['input'];
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
  userID: Scalars['ID']['input'];
};

export type SyncAppObjectsInput = {
  appObjects: Array<SyncAppObjectInput>;
};

export type Thread = Node & {
  __typename: 'Thread';
  apps: WorkspaceAppConnection;
  createdAt: Scalars['String']['output'];
  firstMessage: Maybe<Message>;
  id: Scalars['ID']['output'];
  isPersistentChat: Scalars['Boolean']['output'];
  lastMessage: Maybe<Message>;
  messageableBy: MessageableBy;
  messages: MessageConnection;
  recentMessagesUsers: Array<User>;
  recipients: ThreadRecipientConnection;
  recipientsAddable: Addable;
  replyToMessage: Maybe<Message>;
  subject: Scalars['String']['output'];
  users: UserConnection;
  workspaceID: Maybe<Scalars['ID']['output']>;
};


export type ThreadMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aroundLastRead?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ThreadRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type ThreadUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ThreadAction = {
  __typename: 'ThreadAction';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Maybe<Scalars['String']['output']>;
};

export type ThreadActionInput = {
  actionName: Scalars['String']['input'];
  appID: Scalars['ID']['input'];
  threadID: Scalars['ID']['input'];
  workspaceID: Scalars['ID']['input'];
};

export type ThreadActionResult = {
  __typename: 'ThreadActionResult';
  success: Scalars['Boolean']['output'];
};

export type ThreadConnection = Connection & {
  __typename: 'ThreadConnection';
  edges: Array<ThreadEdge>;
  matchedMessages: MessageConnection;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  unreadCounts: UnreadCounts;
};

export type ThreadEdge = Edge & Node & {
  __typename: 'ThreadEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isMentioned: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  isSeen: Scalars['Boolean']['output'];
  isStarred: Scalars['Boolean']['output'];
  lastReadID: Maybe<Scalars['ID']['output']>;
  lastSeenID: Maybe<Scalars['ID']['output']>;
  node: Thread;
  recipientRole: MemberRole;
  remindAt: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `recipientRole` */
  role: Scalars['String']['output'];
  subscription: ThreadSubscription;
  unreadMessageCounts: UnreadCounts;
};

export type ThreadInput = {
  externalID?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<Array<DestinationMailbox>>;
  messageableBy?: InputMaybe<MessageableBy>;
  metadata?: InputMaybe<ThreadMetadataInput>;
  recipients: Array<Scalars['String']['input']>;
  recipientsAddable?: InputMaybe<Addable>;
  replyToMessageID?: InputMaybe<Scalars['ID']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type ThreadMetadata = {
  __typename: 'ThreadMetadata';
  aiSettings: Maybe<AiSettings>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  threadID: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ThreadMetadataAiSettingsInput = {
  chatModel?: InputMaybe<Scalars['String']['input']>;
};

export type ThreadMetadataInput = {
  aiSettings?: InputMaybe<ThreadMetadataAiSettingsInput>;
};

export type ThreadPreview = Joinable & Node & {
  __typename: 'ThreadPreview';
  admin: Maybe<User>;
  id: Scalars['ID']['output'];
  joinableBy: JoinableBy;
  name: Scalars['String']['output'];
  recipients: RecipientConnection;
  subject: Scalars['String']['output'];
};


export type ThreadPreviewRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type ThreadPreviewEdge = Edge & Node & {
  __typename: 'ThreadPreviewEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  node: ThreadPreview;
};

export type ThreadRecipientConnection = {
  __typename: 'ThreadRecipientConnection';
  edges: Array<ThreadRecipientEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ThreadRecipientEdge = Node & {
  __typename: 'ThreadRecipientEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  node: GroupPreview | User | WorkspacePreview;
  recipientRole: MemberRole;
  /** @deprecated Use `recipientRole` */
  role: Scalars['String']['output'];
};

export enum ThreadSubscription {
  Activity = 'activity',
  Archive = 'archive',
  Inbox = 'inbox'
}

export type ThreadSummaryFilter = {
  afterMessageID?: InputMaybe<Scalars['ID']['input']>;
  level: Scalars['Int']['input'];
  threadID: Scalars['ID']['input'];
};

export type ThreadSummaryPoint = Node & {
  __typename: 'ThreadSummaryPoint';
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  messages: MessageConnection;
  point: Scalars['String']['output'];
  threadID: Scalars['ID']['output'];
};


export type ThreadSummaryPointMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ThreadSummaryPointConnection = {
  __typename: 'ThreadSummaryPointConnection';
  edges: Array<ThreadSummaryPointEdge>;
  pageInfo: PageInfo;
};

export type ThreadSummaryPointEdge = {
  __typename: 'ThreadSummaryPointEdge';
  cursor: Scalars['String']['output'];
  node: ThreadSummaryPoint;
};

export type ThreadsFilter = {
  excludeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludePersistentChats?: InputMaybe<Scalars['Boolean']['input']>;
  excludeStarred?: InputMaybe<Scalars['Boolean']['input']>;
  lastMessageSinceID?: InputMaybe<Scalars['ID']['input']>;
  mailbox?: InputMaybe<ThreadsMailbox>;
  match?: InputMaybe<Scalars['String']['input']>;
  matchMessages?: InputMaybe<Scalars['String']['input']>;
  recipientID?: InputMaybe<Scalars['ID']['input']>;
};

export enum ThreadsMailbox {
  Ai = 'ai',
  All = 'all',
  Archived = 'archived',
  Inbox = 'inbox',
  Mentioned = 'mentioned',
  Sent = 'sent',
  Starred = 'starred',
  Unread = 'unread',
  Unseen = 'unseen'
}

export enum ThreadsOrder {
  Created = 'created',
  LastMessage = 'lastMessage',
  Unread = 'unread',
  Unseen = 'unseen'
}

export type Timezone = {
  __typename: 'Timezone';
  auto: Scalars['Boolean']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type TimezoneInfo = {
  __typename: 'TimezoneInfo';
  name: Scalars['String']['output'];
};

export type TimezoneInput = {
  auto: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UnfurlUrlInput = {
  url: Scalars['String']['input'];
  workspaceID?: InputMaybe<Scalars['ID']['input']>;
};

export type UnfurlUrlResult = {
  __typename: 'UnfurlURLResult';
  appUnfurlSetup: Maybe<AppUnfurlSetup>;
  externalObject: ExternalObject;
};

export type UnreadCounts = {
  __typename: 'UnreadCounts';
  mentioned: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unseen: Scalars['Int']['output'];
};

export type UnsyncAppObjectsInput = {
  appObjects: Array<Scalars['String']['input']>;
};

export type UpdateGroupEdgeInput = {
  threadSubscription: ThreadSubscription;
};

export type UpdateGroupInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  avatarURL?: InputMaybe<Scalars['String']['input']>;
  chatMessageableBy?: InputMaybe<MessageableBy>;
  description?: InputMaybe<Scalars['String']['input']>;
  joinableBy?: InputMaybe<JoinableBy>;
  messageableBy?: InputMaybe<MessageableBy>;
  name?: InputMaybe<Scalars['String']['input']>;
  workspaceID?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateJoinApprovalInput = {
  role: MemberRole;
};

export type UpdateLambdaInput = {
  description: Scalars['String']['input'];
  event: Scalars['String']['input'];
  name: Scalars['String']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  sourceDraft?: InputMaybe<Scalars['String']['input']>;
  testThreadID?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateMeInput = {
  avatarURL?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMessageInput = {
  attachments: Array<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type UpdateSettingsInput = {
  autoArchive?: InputMaybe<Scalars['Boolean']['input']>;
  llmSettings?: InputMaybe<UserLlmSettingsInput>;
  location?: InputMaybe<Scalars['String']['input']>;
  notifyMessages?: InputMaybe<NotifyMessagesSetting>;
  notifyMessagesSound?: InputMaybe<NotifyMessagesSoundSetting>;
  timezone?: InputMaybe<TimezoneInput>;
  workSchedule?: InputMaybe<WorkScheduleInput>;
};

export type UpdateThreadInput = {
  messageableBy?: InputMaybe<MessageableBy>;
  recipients?: InputMaybe<Array<Scalars['String']['input']>>;
  recipientsAddable?: InputMaybe<Addable>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateThreadMetadataInput = {
  aiSettings?: InputMaybe<ThreadMetadataAiSettingsInput>;
  threadID: Scalars['ID']['input'];
};

export type UpdateUserEdgeInput = {
  status: UserEdgeStatus;
};

export type UpdateWorkspaceInput = {
  avatarURL?: InputMaybe<Scalars['String']['input']>;
  chatMessageableBy?: InputMaybe<MessageableBy>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  messageableBy?: InputMaybe<MessageableBy>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceInviteLinkInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  expiresInDays?: InputMaybe<Scalars['Int']['input']>;
  maxCount?: InputMaybe<Scalars['Int']['input']>;
  workspaceID: Scalars['ID']['input'];
};

export type UpdateWorkspaceSettingsInput = {
  llmSettings: WorkspaceLlmSettingsInput;
  workspaceID: Scalars['ID']['input'];
};

export type UploadHeader = {
  __typename: 'UploadHeader';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UploadMetadata = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadTicket = {
  __typename: 'UploadTicket';
  formData: Maybe<Array<FormData>>;
  headers: Array<UploadHeader>;
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type UploadTicketInput = {
  contentLength: Scalars['String']['input'];
  contentMD5: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  metadata?: InputMaybe<UploadMetadata>;
  name: Scalars['String']['input'];
};

export type UploadTicketResponse = File | UploadTicket;

export type User = Node & Recipient & {
  __typename: 'User';
  addressDomains: Array<Scalars['String']['output']>;
  avatarURL: Maybe<Scalars['String']['output']>;
  bio: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profile: Maybe<UserProfile>;
  workspaceIDs: Array<Scalars['ID']['output']>;
};

export type UserConnection = Connection & {
  __typename: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserEdge = Edge & Node & {
  __typename: 'UserEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastInteractionAt: Maybe<Scalars['String']['output']>;
  node: User;
  persistentChatEdge: Maybe<ThreadEdge>;
  status: UserEdgeStatus;
  unreadThreadCounts: UnreadCounts;
};

export enum UserEdgeStatus {
  Blocked = 'blocked',
  Connected = 'connected',
  Hidden = 'hidden',
  None = 'none',
  Starred = 'starred'
}

export type UserLlmSettings = {
  __typename: 'UserLLMSettings';
  chatModel: Scalars['String']['output'];
};

export type UserLlmSettingsInput = {
  chatModel: Scalars['String']['input'];
};

export enum UserMilestone {
  NoWorkspaceWall = 'noWorkspaceWall',
  Onboarding = 'onboarding'
}

export enum UserMilestoneTeamSize {
  Early = 'early',
  Enterprise = 'enterprise',
  Expansion = 'expansion',
  Growth = 'growth',
  Seed = 'seed'
}

export type UserPreviewConnection = Connection & {
  __typename: 'UserPreviewConnection';
  edges: Array<UserPreviewEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserPreviewEdge = Edge & Node & {
  __typename: 'UserPreviewEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  node: User;
};

export type UserProfile = {
  __typename: 'UserProfile';
  location: Maybe<Scalars['String']['output']>;
  timezone: Maybe<Scalars['String']['output']>;
  workSchedule: Maybe<WorkSchedule>;
};

export type UserSettings = {
  __typename: 'UserSettings';
  /** @deprecated no longer supported */
  autoArchive: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  llmSettings: UserLlmSettings;
  location: Maybe<Scalars['String']['output']>;
  notifyMessages: NotifyMessagesSetting;
  notifyMessagesSound: NotifyMessagesSoundSetting;
  timezone: Maybe<Timezone>;
  workSchedule: Maybe<WorkSchedule>;
};

export type UsersFilter = {
  addressIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  edgeStatus?: InputMaybe<Array<UserEdgeStatus>>;
  excludeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  match?: InputMaybe<Scalars['String']['input']>;
  mutualGroups?: InputMaybe<Scalars['Boolean']['input']>;
  mutualThreads?: InputMaybe<Scalars['Boolean']['input']>;
  mutualWorkspaceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  mutualWorkspaces?: InputMaybe<Scalars['Boolean']['input']>;
  threadID?: InputMaybe<Scalars['ID']['input']>;
  workspaceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum UsersOrder {
  Interaction = 'interaction',
  Name = 'name'
}

export type WorkSchedule = {
  __typename: 'WorkSchedule';
  enabled: Scalars['Boolean']['output'];
  fri: Maybe<DailySchedule>;
  mon: Maybe<DailySchedule>;
  sat: Maybe<DailySchedule>;
  sun: Maybe<DailySchedule>;
  thu: Maybe<DailySchedule>;
  tue: Maybe<DailySchedule>;
  wed: Maybe<DailySchedule>;
};

export type WorkScheduleInput = {
  enabled: Scalars['Boolean']['input'];
  fri?: InputMaybe<DailyScheduleInput>;
  mon?: InputMaybe<DailyScheduleInput>;
  sat?: InputMaybe<DailyScheduleInput>;
  sun?: InputMaybe<DailyScheduleInput>;
  thu?: InputMaybe<DailyScheduleInput>;
  tue?: InputMaybe<DailyScheduleInput>;
  wed?: InputMaybe<DailyScheduleInput>;
};

export type Workspace = IWorkspace & Node & {
  __typename: 'Workspace';
  admin: Maybe<User>;
  apps: WorkspaceAppConnection;
  avatarURL: Maybe<Scalars['String']['output']>;
  chatMessageableBy: MessageableBy;
  domains: Array<Scalars['String']['output']>;
  groups: GroupOrPreviewConnection;
  id: Scalars['ID']['output'];
  joinableBy: JoinableBy;
  members: WorkspaceMemberConnection;
  messageableBy: MessageableBy;
  name: Scalars['String']['output'];
  pendingApprovals: JoinApprovalConnection;
};


export type WorkspaceAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type WorkspaceGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GroupsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<GroupsOrder>;
};


export type WorkspaceMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<WorkspaceMemberOrder>;
};


export type WorkspacePendingApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkspaceAppConfig = {
  __typename: 'WorkspaceAppConfig';
  token: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type WorkspaceAppConnection = Connection & {
  __typename: 'WorkspaceAppConnection';
  edges: Array<WorkspaceAppEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkspaceAppEdge = Edge & Node & {
  __typename: 'WorkspaceAppEdge';
  appUser: User;
  creator: User;
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  node: App;
  webhooks: Array<WorkspaceAppWebhook>;
  workspaceID: Scalars['ID']['output'];
};

export type WorkspaceAppInput = {
  appID: Scalars['ID']['input'];
  workspaceID: Scalars['ID']['input'];
};

export type WorkspaceAppWebhook = Node & {
  __typename: 'WorkspaceAppWebhook';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type WorkspaceConnection = Connection & {
  __typename: 'WorkspaceConnection';
  edges: Array<WorkspaceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkspaceEdge = Edge & Node & {
  __typename: 'WorkspaceEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  memberRole: MemberRole;
  node: Workspace;
  persistentChatEdge: ThreadEdge;
  /** @deprecated Use `memberRole` */
  role: Scalars['String']['output'];
  unreadGroupThreadCounts: UnreadCounts;
  unreadThreadCounts: UnreadCounts;
};

export type WorkspaceInviteLink = Node & {
  __typename: 'WorkspaceInviteLink';
  count: Scalars['Int']['output'];
  enabled: Scalars['Boolean']['output'];
  expiresAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxCount: Scalars['Int']['output'];
  workspaceID: Scalars['ID']['output'];
};

export type WorkspaceLlmapiKey = {
  __typename: 'WorkspaceLLMAPIKey';
  id: Scalars['ID']['output'];
  keyPreview: Scalars['String']['output'];
  llmProvider: LlmProvider;
  userID: Scalars['ID']['output'];
  workspaceID: Scalars['ID']['output'];
};

export type WorkspaceLlmapiKeyInput = {
  key: Scalars['String']['input'];
  llmProvider: LlmProvider;
  workspaceID: Scalars['ID']['input'];
};

export type WorkspaceLlmSettings = {
  __typename: 'WorkspaceLLMSettings';
  llmAPIKeys: Array<WorkspaceLlmapiKey>;
};

export type WorkspaceLlmSettingsInput = {
  allowedChatModels: Array<Scalars['String']['input']>;
};

export type WorkspaceMemberConnection = Connection & {
  __typename: 'WorkspaceMemberConnection';
  edges: Array<WorkspaceMemberEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkspaceMemberEdge = Edge & Node & {
  __typename: 'WorkspaceMemberEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  memberRole: MemberRole;
  node: User;
  pending: Scalars['Boolean']['output'];
  /** @deprecated Use `memberRole` */
  role: Scalars['String']['output'];
};

export enum WorkspaceMemberOrder {
  Added = 'added',
  Name = 'name'
}

export type WorkspaceOrPreviewConnection = {
  __typename: 'WorkspaceOrPreviewConnection';
  edges: Array<WorkspaceOrPreviewEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkspaceOrPreviewEdge = WorkspaceEdge | WorkspacePreviewEdge;

export type WorkspacePreview = IWorkspace & Joinable & Node & Recipient & {
  __typename: 'WorkspacePreview';
  admin: Maybe<User>;
  avatarURL: Maybe<Scalars['String']['output']>;
  domains: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  joinableBy: JoinableBy;
  members: UserPreviewConnection;
  name: Scalars['String']['output'];
};


export type WorkspacePreviewMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkspacePreviewEdge = Edge & Node & {
  __typename: 'WorkspacePreviewEdge';
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  node: WorkspacePreview;
};

export type WorkspaceSettings = Node & {
  __typename: 'WorkspaceSettings';
  id: Scalars['ID']['output'];
  llmSettings: WorkspaceLlmSettings;
};

export type WorkspaceSubscription = Node & {
  __typename: 'WorkspaceSubscription';
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  currentPeriodEnd: Maybe<Scalars['String']['output']>;
  currentPeriodStart: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestInvoice: Maybe<Invoice>;
  nextInvoice: Maybe<Invoice>;
  plan: Maybe<SubscriptionPlan>;
  quantity: Scalars['Int']['output'];
  status: SubscriptionStatus;
};

export type WorkspaceSubscriptionInput = {
  subscriptionPlanID: Scalars['ID']['input'];
};

export type WorkspacesFilter = {
  match?: InputMaybe<Scalars['String']['input']>;
};

export type _Service = {
  __typename: '_Service';
  sdl: Scalars['String']['output'];
};
