import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { TextInput } from "components/design-system/Forms";

const GroupsDirectorySearchInput = ({
  onChange,
}: {
  onChange: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const search: string = useWatch({ name: "search" });

  const debouncedSearch = useDebouncedCallback(search => {
    onChange(search);
  }, 350);

  useEffect(() => {
    // If the search input is cleared, reset the search state without debouncing
    if (search === "") {
      debouncedSearch.cancel();
      onChange("");
      return;
    }

    debouncedSearch(search);
  }, [debouncedSearch, onChange, search]);

  return (
    <TextInput
      className="leading-5 py-6 text-sm"
      icon="Search"
      name="search"
      placeholder="Search..."
      type="search"
      wrapperClassName="!m-0"
    />
  );
};

export default GroupsDirectorySearchInput;
