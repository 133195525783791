import { parse, differenceInMinutes, startOfDay } from "date-fns";

import { FormData } from "./types";

export const convertTimeStringToMinutes = (
  timeString: string | undefined
): number => {
  if (!timeString) return 0;
  const date = parse(timeString, "HH:mm", new Date());
  return differenceInMinutes(date, startOfDay(date));
};

export const convertMinutesToTimeString = (
  minutes: number | null | undefined,
  defaultValue?: string
): string | undefined => {
  if (minutes == null) return defaultValue;
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
};

export const validateWorkSchedule = (data: Partial<FormData>) => {
  const days = [
    {
      enabled: data.sunEnabled,
      start: data.sunStart,
      end: data.sunEnd,
      name: "Sunday",
    },
    {
      enabled: data.monEnabled,
      start: data.monStart,
      end: data.monEnd,
      name: "Monday",
    },
    {
      enabled: data.tueEnabled,
      start: data.tueStart,
      end: data.tueEnd,
      name: "Tuesday",
    },
    {
      enabled: data.wedEnabled,
      start: data.wedStart,
      end: data.wedEnd,
      name: "Wednesday",
    },
    {
      enabled: data.thuEnabled,
      start: data.thuStart,
      end: data.thuEnd,
      name: "Thursday",
    },
    {
      enabled: data.friEnabled,
      start: data.friStart,
      end: data.friEnd,
      name: "Friday",
    },
    {
      enabled: data.satEnabled,
      start: data.satStart,
      end: data.satEnd,
      name: "Saturday",
    },
  ];

  const daysWithErrors = [];

  for (const day of days) {
    if (day.enabled && day.start && day.end) {
      const startMinutes = convertTimeStringToMinutes(day.start);
      const endMinutes = convertTimeStringToMinutes(day.end);

      if (startMinutes >= endMinutes) {
        daysWithErrors.push(day.name);
      }
    }
  }

  return daysWithErrors;
};
