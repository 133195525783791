import { useEffect, useState } from "react";

import { Button } from "components/design-system/Button";

const SimulateError = () => {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (error) {
      throw new Error("Simulated error");
    }
  }, [error]);

  return (
    <div>
      <Button
        onClick={() => {
          setError(true);
        }}
        type="button"
      >
        Simulate unhandled error
      </Button>
    </div>
  );
};

export default SimulateError;
