import { useState } from "react";
import { Flipped, Flipper } from "react-flip-toolkit";

import { flipAnimate, flipSpring } from "components/Animated/utils";
import { Badge } from "components/design-system/Badge";
import { Icon } from "components/design-system/icons";
import { DailySchedule, WorkSchedule } from "generated/graphql";

const days: (keyof Omit<WorkSchedule, "enabled" | "__typename">)[] = [
  "sun",
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
];

const fullDayNames: Record<string, string> = {
  sun: "Sunday",
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
};

const UserWorkSchedule = ({
  collapsed = true,
  timezone,
  workSchedule,
}: {
  collapsed?: boolean;
  timezone: string;
  workSchedule: WorkSchedule;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  if (!workSchedule.enabled) return null;

  const currentDay = new Date()
    .toLocaleString("en-US", {
      timeZone: timezone,
      weekday: "short",
    })
    .toLowerCase()
    .slice(0, 3) as keyof Omit<WorkSchedule, "enabled">;

  const currentTimeInMinutes =
    Number.parseInt(
      new Date().toLocaleString("en-US", {
        timeZone: timezone,
        hour: "numeric",
        hour12: false,
      })
    ) *
      60 +
    Number.parseInt(
      new Date().toLocaleString("en-US", {
        timeZone: timezone,
        minute: "numeric",
      })
    );

  const currentDaySchedule =
    (workSchedule[currentDay] as DailySchedule) || null;

  const isWithinWorkingHours =
    currentDaySchedule &&
    currentTimeInMinutes >= currentDaySchedule.start &&
    currentTimeInMinutes <= currentDaySchedule.end;

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const meridiem = hours >= 12 ? "PM" : "AM";
    const displayHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    const remainderMinutes = Math.floor(minutes % 60);
    return `${displayHours}:${remainderMinutes.toString().padStart(2, "0")} ${meridiem}`;
  };

  const dayName = new Date().toLocaleString("en-US", {
    timeZone: timezone,
    weekday: "long",
  });

  const formatDaySchedule = (schedule: DailySchedule) => {
    return `${dayName}, ${formatTime(schedule.start)} - ${formatTime(
      schedule.end
    )}`;
  };

  const scheduleDisplay = currentDaySchedule
    ? formatDaySchedule(currentDaySchedule)
    : "Not scheduled";

  const weeklySchedule = days
    .map(day => ({
      day,
      schedule: workSchedule[day],
    }))
    .map(({ day, schedule }) => {
      const scheduleDisplay = schedule
        ? `${formatTime(schedule.start)} - ${formatTime(schedule.end)}`
        : "Not scheduled";
      return {
        markup: (
          <div className="grid grid-cols-[100px_1fr] gap-4">
            <div>{fullDayNames[day]}</div>
            <div>{scheduleDisplay}</div>
          </div>
        ),
        text: `${fullDayNames[day]}, ${scheduleDisplay}`,
      };
    });

  const items = isCollapsed ? [] : weeklySchedule;

  return (
    <section>
      <div className="flex gap-6 items-center mb-4 select-none text-body-bold text-text-primary">
        Schedule
        {isWithinWorkingHours && <Badge variant="success">Working hours</Badge>}
      </div>

      <Flipper
        key={workSchedule ? "ready" : "loading"}
        className="flex flex-col gap-4 mb-4 overflow-x-hidden text-footnote"
        element="ul"
        flipKey={items.map(item => item.text).join()}
      >
        {items.map(schedule => (
          <Flipped
            key={schedule.text}
            flipId={schedule.text}
            onAppear={el => flipAnimate(el)}
            onExit={(_el, _i, remove) => remove()}
            spring={flipSpring}
          >
            <li
              className={schedule.text === scheduleDisplay ? "font-bold" : ""}
            >
              {schedule.markup}
            </li>
          </Flipped>
        ))}
        <li key="collapse-button">
          <button onClick={() => setIsCollapsed(!isCollapsed)} type="button">
            {isCollapsed ? (
              <span className="group/schedule flex items-center gap-4 text-body text-text-secondary hover:text-text-secondary-hover">
                {scheduleDisplay}{" "}
                <Icon
                  className="text-icon-secondary group-hover/schedule:text-icon-secondary-hover"
                  icon="ChevronRight"
                  size="18"
                />
              </span>
            ) : (
              <span className="flex items-center gap-4 text-text-action hover:text-text-action-hover">
                <Icon icon="ChevronUp" size="18" /> Less
              </span>
            )}
          </button>
        </li>
      </Flipper>
    </section>
  );
};

export default UserWorkSchedule;
