import { difference } from "lodash-es";

import { Recipient } from "@utility-types";
import { Form, SubmitButton } from "components/design-system/Forms";
import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import {
  FetchPendingJoinApprovalsDocument,
  FetchThreadEdgeDocument,
  MemberRole,
  ThreadFieldsFragment,
  ThreadPreviewFieldsFragment,
  useAddThreadRecipientsMutation,
  useRequestJoinThreadMutation,
} from "generated/graphql";
import useForceUpdate from "hooks/useForceUpdate";

const SaveButton = ({ canAdd }: { canAdd: boolean }) => (
  <SubmitButton
    buttonStyle="simpleSecondary"
    buttonFont="subheadBold"
    buttonType="text"
    className="pl-16"
  >
    {canAdd ? "Add" : "Request to Add"}
  </SubmitButton>
);

const AddRecipients = ({
  canAdd,
  thread,
}: {
  canAdd: boolean;
  thread?: ThreadFieldsFragment | ThreadPreviewFieldsFragment;
}) => {
  const [addThreadRecipients] = useAddThreadRecipientsMutation({
    errorPolicy: "all",
  });

  const [requestAddRecipient] = useRequestJoinThreadMutation({
    errorPolicy: "all",
  });

  const forceUpdate = useForceUpdate();

  const handleOnSubmit = (data: { recipients: Recipient[] }) => {
    if (!thread || !data.recipients.length) return;

    const recipientsToAdd = difference(
      data.recipients.map(r => r.id),
      thread.recipients.edges.map(r => r.node.id)
    ).map(id => ({ recipient: id, role: MemberRole.Default }));

    if (canAdd) {
      return addThreadRecipients({
        refetchQueries: [
          FetchThreadEdgeDocument,
          FetchPendingJoinApprovalsDocument,
        ],
        variables: {
          id: thread.id,
          recipients: recipientsToAdd,
        },
      }).catch(err => {
        console.warn("Error: [onChangeRecipients] -", err);
        forceUpdate();
      });
    }

    return Promise.all(
      recipientsToAdd.map(r =>
        requestAddRecipient({
          refetchQueries: [FetchPendingJoinApprovalsDocument],
          variables: {
            input: { joinableID: thread.id, recipient: r.recipient },
          },
        })
      )
    );
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      useFormProps={{
        defaultValues: {
          recipients: [],
        },
      }}
      resetDefaultValues
    >
      <div className="flex items-center">
        <RecipientsSelect
          excludeRecipients={thread?.recipients.edges.map(r => r.node)}
          name="recipients"
          placeholder="Groups, users, or emails to add..."
          wrapperClassName="grow !my-0 h-42 text-text-subtle text-subhead"
          containerPadding="4px 12px"
        />
        <SaveButton canAdd={canAdd} />
      </div>
    </Form>
  );
};

export default AddRecipients;
