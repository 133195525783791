import { ComponentProps } from "react";

import analytics from "analytics";
import { FeedbackProvided } from "analytics/events/feedback";
import type { EditorMessage } from "components/MessageEditor/types";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";
import useModalStore from "store/useModalStore";

import { GlueAIFeedbackModal } from "../GlueAIFeedbackModal";
import { FeedbackType } from "../types";

type Props = {
  subject?: string;
  text: string;
  feedbackType?: FeedbackType;
  threadID?: string;
};

const useGlueAIFeedback = () => {
  const { composeThreadToGlue } = useComposeToGlue("support");
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  /**
   * @summary Open feedback form for Glue AI summaries/responses.
   */
  const openFeedbackForm = (
    props: Pick<
      ComponentProps<typeof GlueAIFeedbackModal>,
      "promptMessageId" | "response" | "threadID" | "threadWorkspaceID"
    >
  ) => {
    return openModal(<GlueAIFeedbackModal {...props} />);
  };

  /**
   * @summary Send feedback for Glue AI summaries/responses.
   */

  const sendFeedback = ({
    subject,
    text,
    feedbackType = FeedbackType.THREAD_SUMMARY,
    threadID,
  }: Props) => {
    analytics.track(FeedbackProvided, {
      feedbackType,
      score: -1,
      threadId: threadID,
    });

    const editorMessage: EditorMessage = {
      attachments: [],
      text,
    };

    // responsible for opening ThreadComposeModal
    composeThreadToGlue(subject, editorMessage);
  };

  /**
   * @summary Template for sending feedback on Glue AI generated responses.
   */

  const sendGeneratedResponseFeedback = ({
    messageText,
    threadID,
  }: {
    messageText: string;
    threadID: string;
  }) => {
    const text = `${threadID ? `Thread: \`${threadID}\`` : ""} \n\n \`\`\`\n${
      messageText || ""
    }\n\`\`\`\n\n Please provide feedback on how this response could be improved: \n\n`;

    return sendFeedback({
      subject: "Glue AI response feedback",
      text,
      feedbackType: FeedbackType.GENERATED_RESPONSE,
      threadID,
    });
  };

  return { sendFeedback, sendGeneratedResponseFeedback, openFeedbackForm };
};

export default useGlueAIFeedback;
