import { useHistory } from "react-router-dom";

import { nodeAs } from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import {
  currentPathWithoutDrawer,
  routeToUser,
} from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { useFetchUserProfileQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import UserWorkSchedule from "./UserWorkSchedule";
import WorkspaceItem from "./WorkspaceItem";
import WorkspaceSectionSkeleton from "./WorkspaceSectionSkeleton";
type Props = { pane?: "secondary"; userID: string };

export default function UserProfilePane({ userID, pane }: Props) {
  const { authData, authReady } = useAuthData();
  const history = useHistory();

  const { data: userData } = useFetchUserProfileQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id || !userID,
    variables: { id: `${userID}-${authData?.me.id}` },
  });
  const userEdge = nodeAs(userData?.node, ["UserEdge"]);
  const loading = !userEdge;

  const { avatarURL, bio, id, name } = userEdge?.node || {};
  const { location, timezone, workSchedule } = userEdge?.node.profile || {};

  const workspaceSection =
    userEdge?.node.workspaceIDs?.map(w => (
      <WorkspaceItem key={w} workspaceID={w} />
    )) ?? null;

  const handleMessageButtonClick = () => {
    if (!id) return;
    history.push(routeToUser({ to: "canonical", userID: id }));
  };

  return (
    <div className="flex flex-col h-full w-full">
      <header className="flex flex-col items-center p-24 pt-32 relative w-full border-b-1 border-border-container">
        {pane === "secondary" ? (
          <Button
            buttonStyle="subtle"
            buttonType="text"
            className="absolute left-16 top-16 p-10 -ml-6 mr-8"
            icon="Close"
            iconSize={20}
            onClick={() => history.push(currentPathWithoutDrawer())}
          />
        ) : null}
        <BackStackButton position="profile" />

        <Avatar
          avatarURL={avatarURL}
          background="subtle"
          loading={loading}
          name={name || ""}
          rounded="rounded-lg"
          size="xx-large"
        />
        <div className="flex items-center mt-10 text-title-1 text-text-primary">
          {!loading ? name : <Skeleton width="180px" height="32px" />}
        </div>
        {!loading && bio && (
          <div className="text-body text-text-secondary">{bio}</div>
        )}
      </header>

      <main className="flex flex-col gap-24 grow min-h-0 overflow-auto mt-24 mb-32 px-24">
        {location || timezone ? (
          <section className="flex flex-col gap-8 text-body text-text-secondary">
            {location && (
              <div className="flex items-center gap-6">
                <Icon className="text-icon-secondary" icon="MapPin" size={16} />
                {location}
              </div>
            )}
            {timezone && (
              <div className="flex items-center gap-6">
                <Icon className="text-icon-secondary" icon="Clock" size={16} />
                {`${new Date().toLocaleTimeString("en-US", {
                  timeZone: timezone,
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })} local time`}
              </div>
            )}
          </section>
        ) : null}

        {timezone && workSchedule && (
          <UserWorkSchedule timezone={timezone} workSchedule={workSchedule} />
        )}

        {workspaceSection ? (
          <section>
            <div className="mb-8 select-none text-body-bold text-text-primary">
              Workspaces
            </div>
            <div className="flex flex-col gap-8">
              {loading ? <WorkspaceSectionSkeleton /> : workspaceSection}
            </div>
          </section>
        ) : null}

        <Button
          className="justify-center my-24 w-full"
          disabled={loading}
          icon="ChatRounded"
          onClick={handleMessageButtonClick}
        >
          Message
        </Button>
      </main>
    </div>
  );
}
