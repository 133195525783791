import { ComponentProps } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "components/design-system/Button";
import { EmptyListPrimitive } from "components/design-system/EmptyListPrimitive";
import InviteToGlueModal from "components/InviteToGlue/InviteToGlueModal";
import { currentPathWithSearch } from "components/routing/utils";
import ComposeDMModal from "components/views/dms/ComposeDMModal";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";

type EmptyListViewProps = {
  isDirectoryList: boolean;
  noSearchResults: boolean;
};

const renderText = (
  isDirectoryList: boolean,
  noSearchResults: boolean
): {
  buttonText?: string;
  primaryText: ComponentProps<typeof EmptyListPrimitive>["primaryText"];
  secondaryText: ComponentProps<typeof EmptyListPrimitive>["secondaryText"];
} => {
  if (noSearchResults) {
    return {
      primaryText: "No conversations found",
      secondaryText: "Try adjusting your search or check for typos.",
    };
  }

  if (isDirectoryList) {
    return {
      buttonText: "Add people",
      primaryText: "There's no one here!",
      secondaryText: "Get started by adding your team to Glue.",
    };
  }

  return {
    buttonText: "New DM",
    primaryText: "Send your first DM",
    secondaryText: "Connect one-on-one with your teammates.",
  };
};

const EmptyListView = ({
  isDirectoryList,
  noSearchResults,
}: EmptyListViewProps) => {
  const history = useHistory();

  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const { buttonText, primaryText, secondaryText } = renderText(
    isDirectoryList,
    noSearchResults
  );

  const handleCTAClick = () => {
    if (isDirectoryList)
      return openModal(<InviteToGlueModal inviteToWorkspace />);
    if (breakpointMD)
      return history.push(currentPathWithSearch({ v: "compose-dm" }));
    return openModal(<ComposeDMModal />);
  };

  return (
    <EmptyListPrimitive
      icon={noSearchResults ? "Search" : "ChatRounded"}
      padding="px-24 py-16"
      primaryText={primaryText}
      secondaryText={secondaryText}
    >
      {!noSearchResults && (
        <Button onClick={handleCTAClick}>{buttonText}</Button>
      )}
    </EmptyListPrimitive>
  );
};

export default EmptyListView;
