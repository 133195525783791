import { marked } from "marked";

import {
  GLUE_URI_SCHEME,
  MentionSearchName,
  isMention,
} from "components/MessageEditor/types";

import {
  INLINE_NODES,
  SUBJECT_TEXT_REGEX,
  escapeHtml,
  parseMarkdown,
  unescapeCodeFenceInCodeElements,
} from "./util";

marked.use({
  extensions: [
    {
      level: "block",
      name: "subject",

      renderer(token) {
        return `\n<subject>${token.text}</subject>`;
      },
      tokenizer(src: string) {
        const rule = SUBJECT_TEXT_REGEX;
        const match = rule.exec(src);
        const raw = match?.[0];
        const text = match?.[2];

        if (raw && text) {
          return {
            raw: raw,
            text: text.trim(),
            tokens: [],
            type: "subject",
          };
        }
      },
    },
  ],
  renderer: {
    code(this: marked.Renderer, code, info) {
      const lang = (info || "").match(/\S*/)?.[0];
      const className = lang ? this.options.langPrefix + lang : "";

      return `<pre><code class="${className}">${escapeHtml(
        code
      )}</code></pre>\n`;
    },
  },
});

export default function markdownToHtml(
  markdown: string,
  sanitizer?: (html: string) => string
): string {
  return restoreMentions(
    fixFirstNodeInlineElement(
      unescapeCodeFenceInCodeElements(
        marked.parse(parseMarkdown(markdown), {
          gfm: true,
          sanitizer,
          smartLists: true,
          xhtml: true,
        })
      )
    )
  );
}

function restoreMentions(htmlString: string) {
  const html = new DOMParser().parseFromString(htmlString, "text/html");

  Array.from(html.body.querySelectorAll("a")).forEach(link => {
    const parent = link.parentElement;
    const href = link.href;

    if (!isMention(href) || !parent) return;

    parent.replaceChild(
      createMentionNode(link, href.replace(GLUE_URI_SCHEME, "")),
      link
    );
  });

  return html.body.innerHTML;
}

function createMentionNode(el: HTMLAnchorElement, href: string) {
  const temp = document.createElement("span");
  const text = el.innerText;

  temp.dataset.mentionAtomId = href.replace(GLUE_URI_SCHEME, "");

  temp.dataset.mentionAtomName = MentionSearchName.All;

  temp.textContent = text;

  return temp;
}

function isInlineNode(nodeName = "") {
  return INLINE_NODES.includes(nodeName || "");
}

// https://github.com/remirror/remirror/issues/1725
function fixFirstNodeInlineElement(htmlString: string) {
  const html = new DOMParser().parseFromString(htmlString, "text/html");

  const firstChild = html.body.firstElementChild;

  const grandChild = firstChild?.firstElementChild;

  // insert empty space if first element is inline node
  // this fixes an issue with inserting additional line separators
  if (
    firstChild &&
    grandChild &&
    firstChild.tagName === "P" &&
    isInlineNode(grandChild.tagName)
  ) {
    const temp = document.createElement("div");

    temp.textContent = " ";

    html.body.prepend(temp);
  }

  return html.body.innerHTML;
}
