import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { Button } from "components/design-system/Button";
import { useExternalFormRef } from "components/design-system/Forms/hooks";
import { Icon } from "components/design-system/icons";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer } from "components/ModalKit/Parts";
import { Main } from "components/ModalKit/Parts";
import { Header } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals/StandardModal";
import { routeToGroup } from "components/routing/utils";
import {
  AuthConfigDocument,
  WorkspaceSubscriptionQuery,
  WorkspaceSubscriptionQueryDocument,
  WorkspacesAndGroupsListDocument,
  useCreateWorkspaceMutation,
} from "generated/graphql";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";

import PaywallModal from "./PaywallModal";
import WorkspaceModalForm, {
  FormRef,
  WorkspaceInput,
} from "./WorkspaceModalForm";
import WorkspaceSubscriptionModal from "./WorkspaceSubscriptionModal";

const WorkspaceModalCreate = (props: ModalProps): JSX.Element => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { closeAllModals, openModal } = useModalStore(
    ({ closeAllModals, openModal }) => ({
      closeAllModals,
      openModal,
    })
  );

  const history = useHistory();
  const apolloClient = useApolloClient();

  const [createWorkspace] = useCreateWorkspaceMutation({
    refetchQueries: [AuthConfigDocument, WorkspacesAndGroupsListDocument],
  });

  const ModalToOpen = breakpointMD ? PaywallModal : WorkspaceSubscriptionModal;

  const createNewWorkspace = (_: string, input: WorkspaceInput) =>
    createWorkspace({ variables: { input: input } }).then(({ data }) => {
      if (!data) return;
      openModal(
        <ModalToOpen
          workspace={{ id: data.createWorkspace.id, ...input }}
          onComplete={() => {
            apolloClient
              .query<WorkspaceSubscriptionQuery>({
                query: WorkspaceSubscriptionQueryDocument,
                variables: { workspaceID: data.createWorkspace.id },
                fetchPolicy: "network-only",
              })
              .then(() => {
                history.push(
                  routeToGroup({ groupID: data.createWorkspace.id })
                );
                closeAllModals();
              });
          }}
        />
      );
      return;
    });

  const {
    ref: formRef,
    setRef: setFormRef,
    disabled: submitDisabled,
  } = useExternalFormRef<FormRef>();

  return (
    <StandardModal
      contentHandlesSafeArea={false}
      header={
        <Header
          mobileCtaLabel="Start trial"
          mobileCtaProps={{
            onClick: () => formRef.current?.submitForm(),
            disabled: submitDisabled,
          }}
          variant="bordered"
        >
          <h3 className="m-0">Create a workspace</h3>
        </Header>
      }
      {...props}
    >
      <Main className="px-16 py-20 md:px-32">
        <WorkspaceModalForm
          ref={setFormRef}
          onSave={createNewWorkspace}
          title="Create a workspace"
          {...props}
        />
      </Main>

      <Footer className="hidden md:flex">
        <Button disabled={submitDisabled} onClick={formRef.current?.submitForm}>
          Start trial <Icon className="ml-6" icon="ArrowRight" size={20} />
        </Button>
      </Footer>
    </StandardModal>
  );
};

export default WorkspaceModalCreate;
