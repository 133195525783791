import { type BaseSyntheticEvent, useCallback } from "react";

import newSyntheticEvent from "utils/newSyntheticEvent";

import { FeedbackButton } from "./FeedbackButton";

type Props = {
  handleReaction: (
    reactionType: string,
    event: BaseSyntheticEvent
  ) => Promise<void>;
  handleSendFeedback: () => void;
};

export const FeedbackWidget = ({
  handleReaction,
  handleSendFeedback,
}: Props) => {
  const handleThumbsUp = useCallback(() => {
    handleReaction?.("+1", newSyntheticEvent("reaction"));
  }, [handleReaction]);

  return (
    <div className="my-4 flex rounded-[24px] border border-border-subtle bg-background-body hover:border-interactive-subtle">
      <FeedbackButton onClick={handleThumbsUp} icon="ThumbsUp" />
      <FeedbackButton onClick={handleSendFeedback} icon="ThumbsDown" />
    </div>
  );
};
