import { useFormContext } from "react-hook-form";

import TimePicker from "components/design-system/Forms/TimePicker";
import { Toggle } from "components/design-system/Forms/Toggle";
import { ToggleCircle } from "components/design-system/Forms/ToggleCircle";
import tw from "utils/tw";

import { FormData } from "./types";

const WorkSchedule = ({
  workScheduleErrors,
}: { workScheduleErrors: string[] }) => {
  const {
    formState: { isSubmitting },
    watch,
  } = useFormContext();

  const workScheduleEnabled = watch("workScheduleEnabled");
  const sunEnabled = watch("sunEnabled");
  const monEnabled = watch("monEnabled");
  const tueEnabled = watch("tueEnabled");
  const wedEnabled = watch("wedEnabled");
  const thuEnabled = watch("thuEnabled");
  const friEnabled = watch("friEnabled");
  const satEnabled = watch("satEnabled");

  const DAYS = [
    { label: "Sunday", name: "sun", enabled: sunEnabled },
    { label: "Monday", name: "mon", enabled: monEnabled },
    { label: "Tuesday", name: "tue", enabled: tueEnabled },
    { label: "Wednesday", name: "wed", enabled: wedEnabled },
    { label: "Thursday", name: "thu", enabled: thuEnabled },
    { label: "Friday", name: "fri", enabled: friEnabled },
    { label: "Saturday", name: "sat", enabled: satEnabled },
  ] as const;

  return (
    <div className="flex flex-col gap-16 mt-20 mb-32">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-body-bold text-text-primary group-hover/collapsible-section-label:text-text-primary-hover">
            Work schedule
          </div>
          <div className="text-body text-text-primary">
            Share your work schedule on your profile
          </div>
        </div>

        <Toggle<FormData>
          labelClassName="!mb-0"
          name="workScheduleEnabled"
          wrapperClassName="shrink-0 !my-0"
        />
      </div>

      {workScheduleEnabled && (
        <>
          <div className="grid md:grid-cols-[200px_1fr] gap-16 items-center">
            <div>Working days:</div>
            <div className="grid grid-cols-[repeat(7,minmax(32px,44px))] md:grid-cols-[repeat(7,32px)] gap-8 overflow-hidden w-full">
              {DAYS.map(({ label, name }) => (
                <ToggleCircle<FormData>
                  key={name}
                  aria-label={label}
                  label={label.slice(0, 1)}
                  labelClassName="!mb-0"
                  name={`${name}Enabled`}
                />
              ))}
            </div>
          </div>

          {DAYS.map(({ enabled, label, name }) => (
            <div
              key={name}
              className={tw(
                "grid grid-cols-[minmax(44px,auto)_1fr_auto_1fr] md:grid-cols-[200px_1fr_auto_1fr] gap-16 items-center",
                {
                  hidden: !enabled,
                }
              )}
            >
              <div>
                <span className="hidden md:inline">{label}</span>
                <span className="inline md:hidden">{label.slice(0, 3)}</span>:
              </div>
              <TimePicker
                disabled={isSubmitting}
                error={workScheduleErrors.includes(label) ? "Error" : undefined}
                name={`${name}Start`}
                mdClockIcon
              />
              <div>to</div>
              <TimePicker
                disabled={isSubmitting}
                error={workScheduleErrors.includes(label) ? "Error" : undefined}
                name={`${name}End`}
                mdClockIcon
              />
            </div>
          ))}

          {workScheduleErrors.length > 0 && (
            <div className="grid grid-cols-[minmax(44px,auto)_1fr] md:grid-cols-[200px_1fr] gap-16 items-center text-body text-text-alert">
              <div />
              <div>Start times must be earlier than end times.</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WorkSchedule;
