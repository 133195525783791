import { ComponentProps, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { Workspace } from "@utility-types/graphql";
import { default as GlueLogoDark } from "assets/glue/glue-logo-color-dark.svg?react";
import { default as GlueLogoWhite } from "assets/glue/glue-logo-color-white.svg?react";
import { Form } from "components/design-system/Forms";
import ThemedImage from "components/helper/ThemedImage";
import Payment from "components/Payment/Payment";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import useElementScrolledToBottom from "hooks/useElementScrolledToBottom";
import tw from "utils/tw";

import ChoosePlan from "./ChoosePlan";
import TeamBasedContactCard from "./TeamBasedContactCard";

type Props = {
  onComplete: () => void;
  workspace?: Pick<Workspace, "id" | "name" | "avatarURL">;
  containerClassName?: string;
  isEnterprise?: boolean;
};

const FormContent = ({
  onComplete,
  workspace,
  containerClassName,
  isEnterprise,
  children,
}: WithChildren<Props>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const choosePlanContainerRef = useRef<HTMLDivElement | null>(null);
  const paymentContainerRef = useRef<HTMLDivElement | null>(null);
  const isWideView = useElementBreakpoint(containerRef, 1100);

  const { watch } = useFormContext();
  const planID = watch("plan");

  const isChoosePlanScrolledToBottom = useElementScrolledToBottom(
    choosePlanContainerRef
  );
  const isPaymentContainerScrolledToBottom =
    useElementScrolledToBottom(paymentContainerRef);

  const fadingStyles = tw(
    "after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-64 after:rounded-b-[24px]",
    "after:bg-[linear-gradient(to_top,rgb(var(--color-background-body)),transparent_90%)] after:pointer-events-none",
    "after:transition-opacity after:duration-200 after:ease-in-out"
  );

  return (
    <>
      <div
        ref={containerRef}
        className={tw(
          "flex overflow-hidden w-full h-full z-1 bg-background-app",
          containerClassName
        )}
      >
        <div
          className={tw(
            "flex flex-col items-center grow min-w-[420px] w-full py-32 overflow-hidden",
            isWideView ? "px-100" : "px-20"
          )}
        >
          <ThemedImage
            className="shrink-0 h-32 my-24"
            LightImage={GlueLogoWhite}
            DarkImage={GlueLogoDark}
          />
          <div className="relative overflow-hidden w-full max-w-[600px] bg-background-body border-1 border-border-container rounded-[24px] shadow-level2">
            <div
              ref={choosePlanContainerRef}
              className={tw(
                "flex flex-col w-full h-full px-56 py-32 gap-16 overflow-y-auto overflow-x-hidden",
                fadingStyles,
                isChoosePlanScrolledToBottom && "after:opacity-0"
              )}
            >
              <ChoosePlan workspace={workspace} />
            </div>
          </div>
          <div className="mt-16 w-full max-w-[600px] px-56 py-32 bg-background-body border-1 border-border-container rounded-[24px] shadow-level2">
            <TeamBasedContactCard isEnterprise={isEnterprise} />
          </div>
          {children}
        </div>
        <div className="flex flex-col grow relative shrink-0 overflow-hidden">
          <div
            ref={paymentContainerRef}
            className={tw(
              "flex flex-col shrink-0 w-[485px] h-full grow px-24 py-32 overflow-y-auto bg-background-white",
              "after:rounded-b-none",
              fadingStyles,
              isPaymentContainerScrolledToBottom && "after:opacity-0"
            )}
          >
            <Payment
              workspaceID={workspace?.id}
              planID={planID ?? ""}
              onComplete={onComplete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

type FormProps = ComponentProps<typeof Form>;
const Paywall = ({
  formProps,
  ...props
}: WithChildren<Props & { formProps?: FormProps }>) => (
  <Form {...formProps}>
    <FormContent {...props} />
  </Form>
);

export default Paywall;
