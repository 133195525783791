import { useApolloClient } from "@apollo/client";
import { pick } from "lodash-es";
import { forwardRef, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { ModalProps } from "components/ModalKit/Modal";
import {
  routePath,
  superTabsDefaults,
  useRouteParams,
  useRoutePartition,
} from "components/routing/utils";
import {
  FetchGroupOrPreviewEdgeDocument,
  GroupFieldsFragment,
  MailboxCountsDocument,
  NotInGroupDocument,
  WorkspacesAndGroupsListDocument,
  useDeleteGroupMutation,
  useLeaveGroupMutation,
  useUpdateGroupMutation,
} from "generated/graphql-operations";
import { UpdateGroupInput } from "generated/graphql-types";
import { useCacheEvict } from "hooks/state/useCacheEvict";
import useModalStore from "store/useModalStore";
import { filterActiveQueries } from "utils/filterActiveQueries";

import EditGroupForm, { FormRef } from "./EditGroupForm";

const memberUpdatable = ["name", "description"] as const;

type Props = {
  group: GroupFieldsFragment;
  isAdmin: boolean;
  focusOnDescriptionField?: boolean;
} & ModalProps;

const GroupSettings = forwardRef<FormRef, Props>(
  ({ group, ...props }: Props, ref): JSX.Element | null => {
    const { closeModal } = useModalStore(({ closeModal }) => ({
      closeModal,
    }));
    const apolloClient = useApolloClient();
    const { evictNode } = useCacheEvict();
    const routeParams = useRouteParams();
    const { superTab } = useRoutePartition();
    const history = useHistory();

    const [updateGroup] = useUpdateGroupMutation({});

    const [deleteGroup] = useDeleteGroupMutation({
      errorPolicy: "all",
    });

    const [leaveGroup] = useLeaveGroupMutation({
      errorPolicy: "all",
    });

    const onSave = (input: UpdateGroupInput) =>
      updateGroup({
        refetchQueries: filterActiveQueries(apolloClient, [
          WorkspacesAndGroupsListDocument,
          FetchGroupOrPreviewEdgeDocument,
        ]),
        variables: {
          id: group.id,
          input: props.isAdmin ? input : pick(input, memberUpdatable),
        },
      })
        .then(({ data }) => {
          if (!data) return;
          closeModal(props.modalId);
        })
        .catch(err => {
          console.warn("Error: [updateGroup] - ", err);
        });

    const onDelete = useCallback(async () => {
      if (!group) return Promise.resolve();

      return deleteGroup({
        awaitRefetchQueries: true,
        refetchQueries: [
          MailboxCountsDocument,
          WorkspacesAndGroupsListDocument,
        ],
        variables: { id: group.id },
      }).then(({ data }) => {
        if (!data?.deleteGroup) return;
        evictNode(group);
        history.replace(superTabsDefaults.groups);
        closeModal(props.modalId);
      });
    }, [group, deleteGroup, evictNode, history, closeModal, props.modalId]);

    const onLeave = useCallback(() => {
      if (!group) return Promise.resolve();

      return leaveGroup({
        awaitRefetchQueries: true,
        refetchQueries: [
          FetchGroupOrPreviewEdgeDocument,
          MailboxCountsDocument,
          NotInGroupDocument,
          WorkspacesAndGroupsListDocument,
        ],
        variables: { id: group.id },
      }).then(({ data }) => {
        if (!data?.leaveGroup) return;
        evictNode(group);
        history.replace(superTabsDefaults.groups);
        closeModal(props.modalId);
      });
    }, [closeModal, evictNode, group, history, leaveGroup, props.modalId]);

    const updateGroupArchivedState = useCallback(
      (archived: boolean) =>
        updateGroup({
          awaitRefetchQueries: true,
          refetchQueries: [
            FetchGroupOrPreviewEdgeDocument,
            MailboxCountsDocument,
            NotInGroupDocument,
            WorkspacesAndGroupsListDocument,
          ],
          variables: { id: group.id, input: { archived } },
          update: c => {
            if (archived) {
              evictNode(group, c);
            }

            // Delete cached instant search result queries
            c.evict({ id: "ROOT_QUERY", fieldName: "localGroups" });
            c.gc();
          },
        }).then(({ data }) => {
          if (!data?.updateGroup) return;
          if (archived) {
            history.replace(
              routePath({ ...routeParams, superTab, recipientID: undefined })
            );
          }
          closeModal(props.modalId);
        }),
      [
        closeModal,
        evictNode,
        group,
        history,
        props.modalId,
        routeParams,
        superTab,
        updateGroup,
      ]
    );

    const onArchive = useCallback(() => {
      if (!group) return Promise.resolve();

      return updateGroupArchivedState(true);
    }, [group, updateGroupArchivedState]);

    const onUnarchive = useCallback(() => {
      if (!group) return Promise.resolve();

      return updateGroupArchivedState(false);
    }, [group, updateGroupArchivedState]);

    return (
      <EditGroupForm
        ref={ref}
        group={group}
        onDelete={onDelete}
        onLeave={onLeave}
        onSave={onSave}
        onArchive={onArchive}
        onUnarchive={onUnarchive}
        {...props}
      />
    );
  }
);

export default GroupSettings;
