import { last } from "lodash-es";
import { useCallback } from "react";
import { useHistory } from "react-router";

import { Mailbox, ThreadEdgeSimple } from "@utility-types";
import { SectionItem } from "components/design-system/ui/sections-sidebar";
import {
  routeToGroup,
  routeToThread,
  useRouteParams,
} from "components/routing/utils";
import { useThreadListData } from "components/threads-list/hooks";
import { InboxThreadListItem } from "components/views/inbox/InboxMain";
import { useInboxSidebarSectionsState } from "components/views/inbox/providers/InboxSidebarSectionsProvider";
import { ThreadsOrder } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useChatRecipient from "hooks/useChatRecipient";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import { unreadThreadEdges } from "utils/sumUnreadCount";

import { useLoadMoreOnFirstPage } from "./hooks";
import MailboxSection from "./MailboxSection";

const sectionKey = "starred";

const Starred = () => {
  const { authData } = useAuthData();
  const { swipedOpenItemId, setState } = useInboxSidebarSectionsState(
    ({ swipedOpenItemId }) => ({
      swipedOpenItemId,
    })
  );
  const setSwipedOpenItemId = (id?: string) => {
    setState({ swipedOpenItemId: id });
  };

  const { collapsedSidebarSections } = useLocalSettingsStore(
    ({ collapsedSidebarSections }) => ({ collapsedSidebarSections })
  );
  const collapsed = collapsedSidebarSections.includes(sectionKey);
  const groupRecipient = useChatRecipient(["GroupPreview", "WorkspacePreview"]);

  const history = useHistory();
  const { recipientID, threadID: selectedID } = useRouteParams();

  const {
    hasNextPage,
    loadingNextPage,
    loadNextPage,
    result: { threadEdges },
  } = useThreadListData({
    mailbox: Mailbox.Starred,
    order: ThreadsOrder.Unread,
    pageSize: 19,
    recipientID: undefined,
  });

  const edges = threadEdges?.slice().reverse();

  const { firstPageSize } = useLoadMoreOnFirstPage({
    loadMore: hasNextPage && (last(edges)?.unreadMessageCounts.total ?? 0) > 0,
    loadNextPage: useCallback(() => loadNextPage(9), [loadNextPage]),
    pageSize: 20,
  });

  const matchOnID = useCallback(
    (edge: ThreadEdgeSimple) => {
      const group = groupRecipient(edge.node);
      return group ? group.id === recipientID : edge.node.id === selectedID;
    },
    [groupRecipient, recipientID, selectedID]
  );

  if (!edges?.length || !authData?.me) return null;

  const sectionItems = (edges: ThreadEdgeSimple[]) => {
    // when collapsed update edges to keep only the selected item
    if (collapsed) edges = edges.filter(e => matchOnID(e));
    return edges.map(edge => {
      const isDMOrGroupChat = edge.node.isPersistentChat;
      const group = groupRecipient(edge.node);
      const isSelected = matchOnID(edge);
      return (
        <SectionItem
          key={edge.node.id}
          className="!mx-0 px-0 group/thread-list-item"
          flipId={`starred-${edge.node.id}`}
          onClick={(
            e:
              | React.KeyboardEvent<HTMLDivElement>
              | React.MouseEvent<HTMLDivElement>
          ) => {
            const to = e.ctrlKey || e.metaKey ? "secondary" : "primary";
            history.push(
              group
                ? routeToGroup({ groupID: group?.id ?? "" })
                : routeToThread({
                    superTab: "inbox",
                    threadID: edge.node.id,
                    to,
                  })
            );
          }}
          setSwipedOpenItemId={setSwipedOpenItemId}
          swipedOpenItemId={swipedOpenItemId}
          canFollow={!isDMOrGroupChat}
          canArchive={!isDMOrGroupChat}
          canRemind={!isDMOrGroupChat}
          itemData={edge}
          type="thread"
        >
          <InboxThreadListItem
            canArchive={!isDMOrGroupChat}
            canRemind={!isDMOrGroupChat}
            canFollow={!isDMOrGroupChat}
            isSelected={isSelected}
            sectionIsOpen={!collapsed}
            threadID={edge.node.id}
          />
        </SectionItem>
      );
    });
  };

  const initialEdges = edges.slice(0, firstPageSize);
  const moreEdges = edges.slice(firstPageSize);

  return (
    <MailboxSection
      flipKey={threadEdges?.map(e => e.node.id).join()}
      hasPreviousPage={hasNextPage}
      loadingNextPage={loadingNextPage}
      moreItems={sectionItems(moreEdges)}
      onClickMore={loadNextPage}
      sectionItems={sectionItems(initialEdges)}
      sectionKey={sectionKey}
      sectionTitle="Starred"
      selectedItem={
        !initialEdges.find(e => e.node.id === selectedID)
          ? sectionItems(moreEdges.filter(e => e.node.id === selectedID))[0]
          : undefined
      }
      unreadCount={unreadThreadEdges(edges, "total")}
      unreadMentions={unreadThreadEdges(edges, "mentioned")}
    />
  );
};

export default Starred;
