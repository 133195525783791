import { useCallback, useRef, useState } from "react";

import { nodeAs } from "@utility-types";
import { ThreadsApps } from "components/apps";
import { Button } from "components/design-system/Button";
import { useExternalFormRef } from "components/design-system/Forms/hooks";
import { ModalProps } from "components/ModalKit/Modal";
import { Header, Main, Footer } from "components/ModalKit/Parts";
import { ProfileItemHeader, StandardModal } from "components/Modals";
import { Tabs } from "components/Tabs";
import MembersSearch from "components/workspace-group/MembersSearch";
import { MemberRole, useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import useAppStateStore from "store/useAppStateStore";

import GroupMembers from "../GroupMembers/GroupMembers";

import { FormRef } from "./EditGroupForm";
import GroupNotificationsSettings from "./GroupNotificationsSettings";
import GroupSettings from "./GroupSettings";
import useGroupApps from "./hooks/useGroupApps";
import useShowAppsOption from "./hooks/useShowAppsOption";

export enum GroupProfileTab { // in order of UI appearance
  Members = "members",
  Settings = "settings",
  Notifications = "notifications",
  Apps = "apps",
}

type Props = {
  defaultTab?: GroupProfileTab;
  groupID: string;
  isAdmin?: boolean;
  focusOnDescriptionField?: boolean;
} & ModalProps;

const GroupProfileModal = ({
  defaultTab = GroupProfileTab.Members,
  groupID,
  isAdmin: isAdminProp,
  ...props
}: Props) => {
  const { authData, authReady } = useAuthData();
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const { data, error, fetchMore, refetch, loading } =
    useFetchGroupOrPreviewEdgeQuery({
      fetchPolicy: authReady ? "cache-and-network" : "cache-only",
      nextFetchPolicy: "cache-first",
      skip: !authData?.me.id,
      variables: {
        id: `${groupID}-${authData?.me.id}`,
      },
    });

  const groupEdge = nodeAs(data?.node, ["GroupEdge", "GroupPreviewEdge"]);
  const { memberEdge } = useMemberEdge(groupEdge);
  const isAdmin =
    isAdminProp !== undefined
      ? isAdminProp
      : memberEdge?.__typename === "GroupEdge"
        ? memberEdge.memberRole === MemberRole.Admin
        : false;

  const showApps = useShowAppsOption({
    groupEdge: memberEdge,
    isAdmin,
  });

  const tabs = [
    {
      tab: GroupProfileTab.Members,
    },
    ...(showApps
      ? [
          {
            tab: GroupProfileTab.Apps,
          },
        ]
      : []),
    {
      tab: GroupProfileTab.Settings,
    },
    {
      tab: GroupProfileTab.Notifications,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(
    tabs.find(t => t.tab === defaultTab)?.tab ?? GroupProfileTab.Members
  );

  const {
    ref: formRef,
    setRef: setFormRef,
    disabled: submitDisabled,
  } = useExternalFormRef<FormRef>();

  const group = groupEdge?.node;
  const appsAdded = memberEdge?.node.apps.edges;
  const persistentChatID = memberEdge?.persistentChatEdge.node.id;

  const { addApp, appsAvailable, editApp, removeApp } = useGroupApps(
    group,
    persistentChatID
  );

  const onMemberSearch = useCallback(
    (search?: string) => {
      if (search === undefined) return;
      !!search.length && setIsSearchLoading(true);
      refetch({
        membersFilter: { match: search },
      }).finally(() => {
        setIsSearchLoading(false);
      });
    },
    [refetch]
  );

  return (
    <StandardModal
      header={
        <Header
          className="!justify-start md:h-48 md:min-h-0 md:pt-16"
          mobileCtaLabel={
            selectedTab === GroupProfileTab.Settings ? "Save" : undefined
          }
          mobileCtaProps={{
            disabled: submitDisabled,
            onClick: () => {
              formRef.current?.submitForm();
            },
          }}
          variant="borderless"
        >
          {group && (
            <ProfileItemHeader target={{ recipient: group, size: "large" }} />
          )}
        </Header>
      }
      mdHeightAuto={false}
      {...props}
    >
      <Main ref={scrollContainerRef} className="relative pb-20">
        <div className="bg-background-body sticky top-0 z-1">
          <Tabs<GroupProfileTab>
            className="px-16 md:px-32 w-full border-b-1 border-border-container"
            onClickTab={setSelectedTab}
            selectedTab={selectedTab}
            tabs={tabs}
          />
        </div>

        {data && group && (
          <div className="px-16 md:px-32">
            {selectedTab === GroupProfileTab.Members &&
              group?.__typename === "Group" && (
                <>
                  <MembersSearch onMemberSearch={onMemberSearch} />
                  <GroupMembers
                    group={group}
                    isAdmin={isAdmin}
                    hasError={!!error}
                    fetchMore={fetchMore}
                    isLoading={loading}
                    scrollContainerRef={scrollContainerRef}
                    isSearchLoading={isSearchLoading}
                  />
                </>
              )}
            {selectedTab === GroupProfileTab.Apps && (
              <ThreadsApps
                addApp={addApp}
                appsAdded={appsAdded}
                appsAvailable={appsAvailable}
                canChangeApps={isAdmin}
                configureApp={editApp}
                name={group.name}
                removeApp={removeApp}
              />
            )}
            {selectedTab === GroupProfileTab.Settings &&
              group?.__typename === "Group" && (
                <GroupSettings
                  ref={setFormRef}
                  group={group}
                  isAdmin={isAdmin}
                  {...props}
                />
              )}
            {selectedTab === GroupProfileTab.Notifications &&
              memberEdge?.__typename === "GroupEdge" && (
                <GroupNotificationsSettings groupEdge={memberEdge} />
              )}
          </div>
        )}
      </Main>
      {selectedTab === GroupProfileTab.Settings && (
        <Footer
          flexboxClassName={
            breakpointMD
              ? "flex-row justify-end"
              : "flex-col-reverse w-full items-center"
          }
        >
          <Button
            disabled={submitDisabled}
            onClick={() => formRef.current?.submitForm()}
            className={!breakpointMD ? "w-full justify-center" : undefined}
          >
            Save
          </Button>
        </Footer>
      )}
    </StandardModal>
  );
};

export default GroupProfileModal;
