const GLUE_DOCS_URL = "https://docs.glue.ai";

export const GLUE_DOCS = {
  BASE_URL: GLUE_DOCS_URL,
  RELEASE_NOTES_URL: `${GLUE_DOCS_URL}/release-notes/release-notes`,
  PUSH_NOTIFICATIONS_URL: `${GLUE_DOCS_URL}/account-setup/push-notifications`,
  WEBHOOKS_URL: `${GLUE_DOCS_URL}/integrations/webhooks`,
  THREAD_PERMISSIONS_URL: `${GLUE_DOCS_URL}/conversations/threads/thread-permissions`,
  GROUP_SETTINGS_URL: `${GLUE_DOCS_URL}/groups/group-settings/admin-settings-for-groups#group-replies`,
};
