import { forwardRef } from "react";

import tw from "utils/tw";

type ModalPart = {
  children?: React.ReactNode;
  className?: string;
};

const footerVariants: Record<string, string> = {
  bordered: "border-t-border-container border-t-1",
  borderless: "border-t-none",
  mdBordered: "border-t-border-container md:border-t-1",
  mdBorderless: "border-t-border-container border-t-1 md:border-none",
};

export const Footer = ({
  children,
  className,
  flexboxClassName = "flex-col md:flex-row justify-end w-full",
  variant = "mdBordered",
}: ModalPart & {
  flexboxClassName?: string;
  variant?: "bordered" | "borderless" | "mdBordered" | "mdBorderless";
}) => (
  <div
    className={tw(
      "shrink-0",
      "min-h-[48px] px-32 py-16 select-none touch-none",
      "leading-6",
      footerVariants[variant],
      className
    )}
    data-testid="modal-footer"
  >
    <div className={tw("flex shrink-0", flexboxClassName)}>{children}</div>
  </div>
);

const headerVariants: Record<string, string> = {
  bordered: "border-border-container border-b-1 md:border-b-1",
  borderless: "border-none",
  mdBorderless: "border-border-container border-b-1 md:border-none",
};

export const Header = ({
  children,
  className,
  mobileCtaLabel,
  mobileCtaProps,
  variant = "mdBorderless",
  textAlign = "left",
}: ModalPart & {
  textAlign?: "left" | "center";
  mobileCtaLabel?: string;
  mobileCtaProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  variant?: "bordered" | "borderless" | "mdBorderless";
}) => (
  <div
    className={tw(
      "box-content relative touch-none select-none",
      "text-headline-bold text-text-primary",
      "flex shrink-0 justify-center items-center",
      "min-h-[56px] px-48", // mobile
      "md:min-h-[60px] md:px-32 md:justify-start", // desktop
      headerVariants[variant],
      {
        "text-left": textAlign === "left",
        "text-center": textAlign === "center",
      },
      className
    )}
    data-testid="modal-header"
  >
    {children}
    {mobileCtaLabel && (
      <button
        {...mobileCtaProps}
        className={tw(
          "h-[56px] md:h-[60px]", // matches Header height in ./Parts.tsx; allows flexbox to vertically center
          "md:hidden",
          "absolute top-0 right-0 px-16",
          "text-subhead-bold",
          "text-text-action hover:text-text-action-hover disabled:text-text-disabled",
          "flex items-center justify-center overflow-hidden whitespace-nowrap",
          mobileCtaProps?.className
        )}
      >
        {mobileCtaLabel}
      </button>
    )}
  </div>
);

export const Main = forwardRef<HTMLDivElement, ModalPart>(
  ({ children, className }, ref) => (
    <div
      ref={ref}
      className={tw("overflow-y-auto overscroll-y-contain grow", className)}
      data-testid="modal-main"
    >
      {children}
    </div>
  )
);
