import { Workspace } from "@utility-types/graphql";
import Paywall from "components/Payment/Paywall";
import useOnboardingStore from "store/useOnboardingStore";

import Footer from "./Footer";

const PlanAndPaymentDesktop = ({
  onComplete,
  workspace,
}: {
  onComplete: () => void;
  workspace?: Pick<Workspace, "id" | "name" | "avatarURL">;
}) => {
  const { setState, currentStep, isEnterprise } = useOnboardingStore(
    ({ setState, currentStep, isEnterprise }) => ({
      setState,
      currentStep,
      isEnterprise,
    })
  );

  return (
    <div className="overflow-x-auto overflow-y-hidden bg-background-app-base py-16 h-full">
      <div className="flex justify-center items-center w-full h-full px-100">
        <Paywall
          onComplete={onComplete}
          workspace={workspace}
          formProps={{
            className:
              "w-full h-full min-w-[900px] rounded-[32px] max-w-[1240px] max-h-[800px] bg-background-subtle overflow-hidden",
          }}
          isEnterprise={isEnterprise}
        >
          <div className="flex flex-col grow justify-end w-full max-w-[600px] mt-16">
            <Footer
              onClickBack={() =>
                setState({
                  view: "CreateWorkspace",
                  currentStep: currentStep - 1,
                })
              }
              fullHeight={false}
              hideSkip
            >
              {null}
            </Footer>
          </div>
        </Paywall>
      </div>
    </div>
  );
};

export default PlanAndPaymentDesktop;
