import { useCallback, useEffect, useRef, useState } from "react";

import { Message } from "@utility-types/graphql";
import QuotedMessage from "components/QuotedMessage/QuotedMessage";

import { DisposableElement } from "../components/DisposableElement";
import { Mode } from "../types";

function useQuotedMessage(mode: Mode, onChange: () => void) {
  const [quotedMessageState, setQuotedMessageState] = useState<Message>();
  const ref = useRef(quotedMessageState);

  const setQuotedMessage = useCallback(
    ({
      focus,
      message,
      skipOnChange = false,
    }: {
      focus?: () => void;
      message?: Message;
      skipOnChange?: boolean;
    }) => {
      setQuotedMessageState(message);

      focus?.();

      !skipOnChange && onChange();
    },
    [onChange]
  );

  const setQuotedMessageRef = useRef(setQuotedMessage);

  const quotedMessageComponent =
    quotedMessageState &&
    (mode === "compose" ? (
      <DisposableElement
        className="pl-10"
        onRemove={() => setQuotedMessageRef.current({ message: undefined })}
      >
        <QuotedMessage message={quotedMessageState} />
      </DisposableElement>
    ) : (
      <div className="mx-10 mt-10">
        <QuotedMessage message={quotedMessageState} />
      </div>
    ));

  useEffect(() => {
    setQuotedMessageRef.current = setQuotedMessage;
  }, [setQuotedMessage]);

  useEffect(() => {
    ref.current = quotedMessageState;
  }, [quotedMessageState]);

  return {
    quotedMessage: ref,
    quotedMessageComponent,
    setQuotedMessageRef,
  };
}

export default useQuotedMessage;
