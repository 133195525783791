import { useState } from "react";

import tw from "utils/tw";

import { Icon } from "../icons";

import CollapsibleDiv from "./CollapsibleDiv";

const CollapsibleSection = ({
  children,
  collapsed = false,
  label,
}: WithChildren<{
  collapsed?: boolean;
  label: string;
}>) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  return (
    <div className="flex flex-col">
      <button
        className="group/collapsible-section-label flex items-center justify-start"
        onClick={() => setIsCollapsed(!isCollapsed)}
        type="button"
      >
        <Icon
          className={tw(
            "mr-6 text-icon-primary group-hover/collapsible-section-label:text-icon-primary-hover",
            "duration-350 ease-spring",
            {
              "-rotate-90": isCollapsed,
            }
          )}
          icon="ChevronDown"
          size={20}
        />
        <span className="text-body-bold text-text-primary group-hover/collapsible-section-label:text-text-primary-hover">
          {label}
        </span>
      </button>

      <CollapsibleDiv collapsed={isCollapsed}>{children}</CollapsibleDiv>
    </div>
  );
};

export default CollapsibleSection;
