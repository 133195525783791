import { isEqual } from "lodash-es";

import { Addable, SendMessageInput } from "generated/graphql";

import { DraftForm } from "./types";

export const formToInput = (form: DraftForm): SendMessageInput => ({
  message: {
    text: form.message.text,
    attachments: form.message.attachments.map(({ id }) => id),
  },
  thread: {
    subject: form.subject,
    metadata: form.metadata,
    recipients: form.recipients.map(({ id }) => id),
    recipientsAddable: form.recipientsAddable,
  },
});

type PendingAction = "send" | false;

export type State = {
  draftForm: DraftForm;
  dirty: boolean;
  error: string | undefined;
  pending: PendingAction;
};

export const InitialState: State = {
  draftForm: {
    subject: "",
    recipients: [],
    message: {
      attachments: [],
      text: "",
    },
    replyToMessage: null,
    recipientsAddable: Addable.Anyone,
    appUnfurlSetups: [],
  },
  dirty: false,
  error: undefined,
  pending: false,
};

export type Action =
  | { type: "change"; draftForm: Partial<DraftForm> }
  | { type: "send" }
  | { type: "error" };

export const SendMessageReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "change": {
      const draftForm = {
        ...state.draftForm,
        ...action.draftForm,
      };

      if (isEqual(state.draftForm, draftForm)) return state;

      return {
        ...state,
        draftForm,
        dirty: true,
      };
    }
    case "send": {
      return { ...state, pending: "send" };
    }
    case "error": {
      return { ...state, pending: false };
    }
  }
};
