import { useMemo, useRef, useState } from "react";

import { StreamGlueMessage } from "@utility-types";
import { Badge } from "components/design-system/Badge";
import MessageAvatar from "components/design-system/ui/MessageAvatar";
import { Author } from "components/thread/ThreadView/stream-components/Message/components/Author";
import {
  isOnlyEmojis as isOnlyEmojisFunction,
  renderText,
  renderTextWithOptions,
} from "components/thread/ThreadView/stream-components/Message/utils";
import { MessageAttachments } from "components/thread/ThreadView/stream-components/MessageAttachments";
import { MessageFieldsFragment } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import generateRandomId from "utils/generateRandomId";
import { glueMessageToStreamMessage } from "utils/stream/message";
import { truncateMarkdown } from "utils/thread/truncateMarkdown";
import tw from "utils/tw";

const MessageContent = ({
  attachments,
  id,
  text,
}: {
  attachments?: StreamGlueMessage["attachments"];
  id: string;
  text?: string;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const truncateLength = breakpointMD ? 110 : 40;

  const [collapsed, setCollapsed] = useState(
    text && text.length > truncateLength
  );

  const collapseButtonIdRef = useRef(generateRandomId("collapse-btn"));

  const collapsedMessageText = useMemo(() => {
    const truncatedText = truncateMarkdown(
      text || "",
      truncateLength,
      `<button id=${collapseButtonIdRef.current}>See more</button>`
    );
    const truncatedMessage = renderTextWithOptions(`${truncatedText}\n`, {
      allowButton: true,
    });

    return truncatedMessage;
  }, [text, truncateLength]);

  const messageText = useMemo(() => renderText(text ?? ""), [text]);

  const messageAttachments = attachments?.length ? (
    <MessageAttachments
      attachments={attachments || []}
      messageId={id}
      disablePointerEvents
    />
  ) : null;

  const isOnlyEmojis = isOnlyEmojisFunction(text);

  return (
    <div className="relative w-full min-w-0">
      <div
        className={tw("str-chat-message-content", {
          "text-3xl": isOnlyEmojis,
        })}
        onClick={e => {
          const { target } = e;
          if (
            target instanceof HTMLButtonElement &&
            target.id === collapseButtonIdRef.current
          ) {
            setCollapsed(false);
          }
        }}
      >
        {collapsed ? collapsedMessageText : messageText}
      </div>

      {!collapsed && messageAttachments}
    </div>
  );
};

const Message = ({
  message,
}: {
  message: StreamGlueMessage;
}) => {
  const { authData } = useAuthData();
  const isMyMessage = message.user?.id === authData?.me.id;

  return (
    <div className="str-chat-message relative py-6 flex items-start w-full min-w-0">
      <div className="pr-12">
        <MessageAvatar
          image={message.user?.image}
          isMyMessage={isMyMessage}
          name={message.user?.name}
          userID={message.user?.id}
        />
      </div>

      <div className="flex flex-col min-w-0 w-full">
        <Author
          className="!pr-0"
          clickable={true}
          createdAt={message.created_at}
          isMyMessage={isMyMessage}
          messageID={message.id}
          name={message.user?.name}
          onUserClick={() => null}
          userID={message.user?.id}
        />

        <MessageContent
          attachments={message.attachments}
          id={message.id}
          text={message.text ?? ""}
        />
      </div>
    </div>
  );
};

const isMessageFragment = (
  message: StreamGlueMessage | MessageFieldsFragment
): message is MessageFieldsFragment => "__typename" in message;

export const MessageRow = ({
  message,
  type,
}: {
  message: StreamGlueMessage | MessageFieldsFragment;
  type: "prompt" | "response";
}) => {
  const messageAsStreamMessage = isMessageFragment(message)
    ? glueMessageToStreamMessage({
        ...message,
      })
    : message;

  return (
    <div className="flex items-start justify-between gap-12">
      <Message message={messageAsStreamMessage} />
      <Badge className="mt-4 shrink-0 capitalize" variant="subtle">
        {type}
      </Badge>
    </div>
  );
};
